//Store for ResultPostLog

import { DropdownItemData } from "components/Dropdown/DropdownBase";
import { Message } from "components/Message";
import { makeAutoObservable } from "mobx";
import {
  getMotivatingFactors,
  MotivatingFactorsApisPayload,
} from "container/MotivatingFactors/store/motivatingFactors.api";
import {
  getResultPostLog,
  ResultPostLogApisPayload,
} from "container/ResultPostLog/store/resultPostLog.api";
import { debounce } from "lodash";
import { MotivatingFactorsTableData } from "container/MotivatingFactors/store/motivatingFactors.types";
import { getLookupData } from "container/LookUpXL/store/lookUpXL.api";

class ResultPostLogStore {
  constructor() {
    makeAutoObservable(this);
  }

  search;
  page;

  selectedData;
  isOpen;

  setSearch = (search: string) => {
    this.search = search;
    this.page = 1;

    this.callResultPostLogApiWithDebounce({
      search: search,
      page: 1,
    });
  };

  setPage = (page) => {
    this.page = page;

    this.callResultPostLogApi({
      search: this.search,
      page: page,
    });
  };

  setSelectedData = (data) => {
    this.selectedData = data;
  };

  setOpenModal = (bool: boolean = !this.isOpen) => {
    this.isOpen = bool;
  };

  callResultPostLogApi = async (payload?: ResultPostLogApisPayload) => {
    await getResultPostLog.callApi(payload);
  };

  callResultPostLogApiWithDebounce = debounce(
    (payload?: ResultPostLogApisPayload) => {
      getResultPostLog.callApi(payload);
    },
    1500
  );

  copyJsonResponse = () => {
    const jsonObject = JSON.stringify(this.selectedData, null, 4);
    (navigator as any).clipboard.writeText(jsonObject);
    Message.success("Successfully copy json to clipboard");
  };

  get getResultPostLogData() {
    return getResultPostLog.data || [];
  }

  get getMetaData() {
    return getResultPostLog.meta;
  }

  get getSelectedData() {
    return this.selectedData;
  }

  get getTotalData() {
    return getResultPostLog.meta?.total || "";
  }
}

export const resultPostLogStore = new ResultPostLogStore();
