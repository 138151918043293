// Apis For LookUpXL
import { ApiStore } from "stores/ApiStore";
import { RestMethods } from "stores/baseApi";

export interface LookUpXLApisPayload {
  search?: string;
  filters?: {
    reference: [string];
  };
  page?: number;
}

const ENDPOINT_TEMPLATE = "/api/descriptors/lookupData";

class LookUpXLApis extends ApiStore<LookUpXLApisPayload, null, null, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static uploadLookupData() {
    const endpoint = `${ENDPOINT_TEMPLATE}/uploadLookupData`;
    return new LookUpXLApis(endpoint, { requestType: RestMethods.POST });
  }
  static getLookupData() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getLookupData`;
    return new LookUpXLApis(endpoint, { requestType: RestMethods.GET });
  }
}

export const uploadLookupData = LookUpXLApis.uploadLookupData();
export const getLookupData = LookUpXLApis.getLookupData();
