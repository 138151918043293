/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-25
 */

// screen file for MapperLanding
import React, { useState } from "react";
import style from "./index.module.scss";
import { MapperLandingProps } from "container/MapperTemplate/MapperLanding/store/mapperLanding.types";
import { PageTitle } from "components/PageTitle";
import { TableWithVirtualization } from "components/TableWithVirtualization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "components/Button";
import { Title } from "components/Title";
import { SearchInput } from "components/SearchInput";
import { MAPPER_TEMPLATE_DOMAIN } from "constants/routes";
import { useNavigate } from "react-router-dom";
import { PageBase } from "components/PageBase";
import { columnsMapperTemplate } from "./tableColumn";
import { mapperLandingStore } from "./store/mapperLanding.store";
import { useEffectAsync } from "utlis/hooks";
import { observer } from "mobx-react";
import { Modal } from "components/Modal";
import { Select } from "components/Select";
import { SelectBoxWithCheckbox } from "components/Select/SelectBoxWithCheckbox";
import { getJourneys } from "container/_globalContainerStore/globalContainerStore.api";
import { isNil } from "lodash";
import { Input } from "components/Input";
import { createMapperTemplateApi } from "container/MapperTemplate/MapperSetup/store/mapperSetup.api";
import { Message } from "components/Message";
import { mapperLandingFormStore } from "container/MapperTemplate/MapperLanding/store/mapperLanding.formstore";
import { Pagination } from "components/Pagination";
import { authStore } from "stores/AuthStore";
import { Table } from "components/Table";

export const MapperLanding: React.FC<MapperLandingProps> = observer(({}) => {
  let navigate = useNavigate();
  useEffectAsync(async () => {
    await mapperLandingStore.callGetMapperTemplateApi();
  }, [authStore.getClientIdToken]);

  useEffectAsync(async () => {
    if (!isNil(mapperLandingStore.client.value?.value)) {
      await getJourneys.callApi({
        client_ids: [mapperLandingStore.client.value.value],
      });
    }
  }, [
    !isNil(mapperLandingStore.client.value?.value),
    mapperLandingStore.client.value?.value,
  ]);

  return (
    <PageBase className={style.mapperContainer}>
      <PageTitle
        pageTitle={`Mapper Template`}
        subTitle={"Create and manage your mapper template."}
      />
      <div className={style.topDropdown}>
        <Title count={mapperLandingStore.getMapperMeta?.total}>
          MAPPER TEMPLATE LIST
        </Title>
        <div className={`d-flex`}>
          <SearchInput
            placeholder={"Search Template"}
            onChange={(e) => {
              mapperLandingStore.setSearch(e.target.value);
            }}
          />
          <Button
            className={`ms-2 ${style.button}`}
            onClick={() =>
              mapperLandingStore.setCreateOpen(!mapperLandingStore.createOpen)
            }
          >
            <FontAwesomeIcon icon={faPlus} /> CSV Mapping
          </Button>
        </div>
      </div>
      <div className={style.tableHeight}>
        <Table
          columns={columnsMapperTemplate}
          data={mapperLandingStore.getTableData}
        />
      </div>
      <Pagination
        currentPage={mapperLandingStore.getMapperMeta?.current_page}
        lastPage={mapperLandingStore.getMapperMeta?.last_page}
        onPageClick={(e) => mapperLandingStore.setPage(e)}
        onNextClick={() =>
          mapperLandingStore.setPage(
            mapperLandingStore.getMapperMeta?.current_page + 1
          )
        }
        onPreviousClick={() =>
          mapperLandingStore.setPage(
            mapperLandingStore.getMapperMeta?.current_page - 1
          )
        }
        onLastClick={() =>
          mapperLandingStore.setPage(
            mapperLandingStore.getMapperMeta?.last_page
          )
        }
        onFirstClick={() => mapperLandingStore.setPage(1)}
      />
      <Modal
        toggle={() =>
          mapperLandingStore.setUploadOpen(!mapperLandingStore.uploadOpen)
        }
        isOpen={mapperLandingStore.uploadOpen}
        showActionBtn={true}
        headerTitle={"Upload Data"}
        actionBtnLabel={"Upload"}
        cancelBtnLabel={"Cancel"}
        modalSize={style.modalSize}
        actionBtnOnClick={mapperLandingStore.uploadButton}
        cancelBtnOnClick={mapperLandingStore.cancelButton}
        actionBtnProps={{
          disabled:
            !mapperLandingFormStore.isValid || !mapperLandingStore.file?.size,
        }}
      >
        <div className={style.uploadModal}>
          <Select
            containerClassName={style.selectDropdown}
            options={mapperLandingStore.clientListOptions}
            {...mapperLandingStore.client.bind()}
            needSelectAsterisk={false}
          />
          <Select
            containerClassName={style.selectDropdown}
            options={
              mapperLandingStore.client.value && mapperLandingStore.journeyList
            }
            {...mapperLandingStore.journey.bind()}
            needSelectAsterisk={false}
          />
          <SelectBoxWithCheckbox
            containerClassName={style.selectDropdown}
            {...mapperLandingStore.normGroup.bind()}
            options={mapperLandingStore.normList}
            needSelectAsterisk={false}
          />
          <div className={style.uploadFile}>
            <Button
              className={style.uploadButton}
              onClick={() => mapperLandingStore.dataRef.click()}
              loading={mapperLandingStore.isDataLoading}
              disabled={mapperLandingStore.isDataLoading}
            >
              Upload File
            </Button>
            <Input
              label={"Upload Data"}
              placeholder={"No file chosen"}
              value={
                mapperLandingStore.file?.size
                  ? mapperLandingStore.file.name
                  : ""
              }
              readOnly={true}
              className={style.fileName}
              rules={"required"}
            />
            <input
              className="d-none"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id={"input"}
              ref={(ref) => (mapperLandingStore.dataRef = ref)}
              value=""
              onChange={(e) => mapperLandingStore.handleUploadData(e)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        toggle={() => {
          mapperLandingStore.setCreateOpen(!mapperLandingStore.createOpen);
        }}
        isOpen={mapperLandingStore.createOpen}
        showActionBtn={true}
        headerTitle={"Create CSV Template"}
        actionBtnLabel={"Create"}
        cancelBtnLabel={"Cancel"}
        modalSize={style.createModal}
        actionBtnOnClick={async () => {
          try {
            await createMapperTemplateApi.callApi({
              name: mapperLandingStore.mappingName,
            });
            navigate(
              `/${MAPPER_TEMPLATE_DOMAIN}/create/${createMapperTemplateApi.data.id}`
            );
            Message.success("Successfully created");
          } catch (error) {
            Message.error("Could not creat");
          }
        }}
        actionBtnProps={{ disabled: !mapperLandingStore.mappingName }}
      >
        <Input
          placeholder={"Enter name here ..."}
          label={"Template Name"}
          className={style.inputMapping}
          onChange={async (e: any) => {
            mapperLandingStore.setMappingName(e.target.value);
          }}
        />
      </Modal>
    </PageBase>
  );
});
