import * as React from "react";
import style from "./index.module.scss";

interface PageBaseProps {
  className?: string;
}

export const PageBase: React.FC<PageBaseProps> = ({ className, children }) => {
  return (
    <div className={`${style.pageBaseContainer} ${className}`}>{children}</div>
  );
};
