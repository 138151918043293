//Store for LookUpXL

import { ChangeEvent } from "react";
import {
  getLookupData,
  LookUpXLApisPayload,
  uploadLookupData,
} from "./lookUpXL.api";
import { downloadExcelFile, isExcelFile } from "utlis/files";
import { makeAutoObservable } from "mobx";
import { lookUpXLTableData } from "./lookUpXL.types";
import { Message } from "components/Message";
import { getCompetencyLibrary } from "container/CompetencyLibrary/store/competencyLibrary.api";
import { debounce } from "lodash";
import { SearchLandingApi } from "container/Landing/store/landing.types";
import { workBookDetailApi } from "container/Landing/store/landing.api";

class LookUpXLStore {
  constructor() {
    makeAutoObservable(this);
  }

  lookupRef;
  isLookupXLUploadLoading = false;
  search;
  page;

  setIsLookupXLUploadLoading(bool: boolean) {
    this.isLookupXLUploadLoading = bool;
  }

  setSearch = (search: string) => {
    this.search = search;
    this.page = 1;

    this.callGetLookUpXLApiWithDebounce({
      search: search,
      page: 1,
    });
  };

  setPage = (page) => {
    this.page = page;

    this.callGetLookUpXLApi({
      search: this.search,
      page: page,
    });
  };

  handleUploadLookupXL = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      this.setIsLookupXLUploadLoading(true);
      const excelFile = e.target.files ? e.target.files[0] : undefined;

      if (!isExcelFile(e.target.value)) {
        Message.error("Unsupported File");
      }

      const payload = {
        lookup_data: excelFile,
      };
      await uploadLookupData.callApiWithFormData(payload);
      Message.success("Successfully uploaded Lookup XL");
      await getLookupData.callApi();
    } catch (error) {
      console.log("error: ", error);
      Message.error("Failed to upload Lookup XL");
    } finally {
      this.setIsLookupXLUploadLoading(false);
    }
  };
  downloadLookupXLData = async () => {
    try {
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/descriptors/lookupData/downloadLookupFile`;

      await downloadExcelFile(endpoint, "LookUpFile", "", "POST");
      Message.success("Downloaded Lookup Xl File");
    } catch (error) {
      Message.error("Could not download");
    }
  };

  callGetLookUpXLApi = async (payload?: LookUpXLApisPayload) => {
    await getLookupData.callApi(payload);
  };

  callGetLookUpXLApiWithDebounce = debounce((payload?: LookUpXLApisPayload) => {
    getLookupData.callApiIfPayloadDifferent(payload);
  }, 1500);

  get getTableData() {
    return (getLookupData.data as lookUpXLTableData[]) || [];
  }

  get getTotalData() {
    return getLookupData.meta?.total || "";
  }

  get getMetaData() {
    return getLookupData.meta;
  }
}

export const lookUpXLStore = new LookUpXLStore();
