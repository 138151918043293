import React from "react";
import { DropdownActionButton } from "components/Dropdown/DropdownActionButton";
import { Message } from "components/Message";
import { mapperLandingStore } from "container/MapperTemplate/MapperLanding/store/mapperLanding.store";
import {
  getClient,
  getLevel,
} from "container/_globalContainerStore/globalContainerStore.api";
import { GoToLink } from "components/GoToLink";
import { MAPPER_TEMPLATE_DOMAIN } from "constants/routes";
import { mapperSetupStore } from "container/MapperTemplate/MapperSetup/store/mapperSetup.store";
import {
  getCsvFields,
  getFields,
} from "container/MapperTemplate/MapperSetup/store/mapperSetup.api";
import { format, parseISO } from "date-fns";
import { isNil } from "lodash";

const dropDownItems = (data) => [
  {
    name: (
      <GoToLink to={`/${MAPPER_TEMPLATE_DOMAIN}/create/${data.id}`}>
        Map Data
      </GoToLink>
    ),
    onClick: () => {},
  },
  {
    name: "Upload Data",
    onClick: async () => {
      mapperLandingStore.mapperTemplateId.set("value", data.id);
      await getClient.callApi();
      await getLevel.callApi();
      mapperLandingStore.setUploadOpen(!mapperLandingStore.uploadOpen);
    },
  },
  {
    name: "Delete",
    onClick: async () => {
      try {
        await mapperLandingStore.callDeleteMapperTemplateApi(data.id);
        await mapperLandingStore.callGetMapperTemplateApi()
        Message.success("Successfully delete mapper template");
      } catch (e) {
        Message.error("Fail to delete mapper template");
      }
    },
  },
];
export const columnsMapperTemplate = [
  {
    Header: "Template Name",
    accessor: "name",
    width: 400,
    minWidth: 400,
  },
  {
    Header: "Date Created",
    accessor: "created_at",
    width: 250,
    minWidth: 200,
    Cell: ({ row }) => (
      <div>
        {isNil(row.original.created_at)
          ? "-"
          : format(parseISO(row.original.created_at), "PPpp")}
      </div>
    ),
  },
  {
    Header: "Date Uploaded?",
    accessor: "file_name",
    width: 200,
    minWidth: 200,
    Cell: ({ row }) => <div>{row.original.file_name || "-"}</div>,
  },
  {
    Header: "Last Update",
    accessor: "updated_at",
    width: 200,
    minWidth: 200,
    Cell: ({ row }) => (
      <div>
        {isNil(row.original.updated_at)
          ? "-"
          : format(parseISO(row.original.updated_at), "PPpp")}
      </div>
    ),
  },
  {
    Header: "Action",
    id: "action",
    disableSortBy: true,
    width: 90,
    minWidth: 70,
    Cell: ({ row }) => (
      <div>
        <DropdownActionButton dropdownItem={dropDownItems(row.original)} />
      </div>
    ),
  },
];
