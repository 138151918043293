import * as React from 'react';
import  style from './index.module.scss';
import { components } from 'react-select';
import {ISelectProps} from "./index.types";
import {CheckBox} from "../CheckBox";
import {Select} from "./index";

interface SelectBoxWithCheckboxProps extends ISelectProps {}

export const SelectBoxWithCheckbox: React.FC<SelectBoxWithCheckboxProps> = ({  ...otherProps }) => {
  const formatGroupLabel = data => (
    <div className={`${style.groupContainer}`}>
      <span className={`${style.groupHeader}`}>{data.label}</span>
      <span className={`${style.groupBadge}`}>{data.options.length}</span>
    </div>
  );

  const Option = props => {
    return (
      <components.Option {...props} className={`${style.menuItemContainer}`}>
        <div className={`${style.menuItemLabel}`}>
          <CheckBox className={`${style.checkboxMenu}`} checked={props.isSelected} onChange={() => null} />{' '}
          <span className={`ps-2`}>{props.label}</span>
        </div>
        <span>{props.value}</span>
      </components.Option>
    );
  };

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      formatGroupLabel={formatGroupLabel}
      components={{
        Option
      }}
      {...otherProps}
    />
  );
};