import * as React from "react";
import style from "./index.module.scss";
import { components } from "react-select";
import { Select } from "components/Select";
import { ISelectProps } from "components/Select/index.types";
import { TypeColorText } from "components/TypeColorText";
import {isNil} from "lodash";
import {observer} from "mobx-react";

interface SelectBoxWithCodeProps extends ISelectProps {
  code?: string;
  type?: string;
  showValue?: boolean;
}

export const SelectBoxWithCode: React.FC<SelectBoxWithCodeProps> = observer(({
  showValue,
  ...otherProps
}) => {
  const Option = (props, index) => {
    return (
      <components.Option key={index} {...props} className={`${style.menuItemContainer}`}>
        <div className={`${style.menuItemLabel}`}>
          <span className={`ps-2 d-flex`}>
            {props.label}
              {!isNil(props.data.code) && <TypeColorText className={style.codeType} color={props.data.type} text={`(${props.data.code})`}/>}
          </span>
        </div>
        {showValue && <span>{props.value}</span>}
      </components.Option>
    );
  };

  return (
    <Select
      hideSelectedOptions={false}
      components={{
        Option,
      }}
      {...otherProps}
    />
  );
});
