import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { authStore } from "stores/AuthStore";
import { saveAs } from "file-saver";
import { isBoolean } from "lodash";

export const getFilename = (response) => {
  let filename = "";
  const disposition = response.request.getResponseHeader("Content-Disposition");

  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }
  return filename;
};

export const downloadFileFromArrayBuffer = (
  response: AxiosResponse,
  filename,
  mimeType:
    | string
    | boolean = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
) => {
  const mimeAuto = isBoolean(mimeType);

  const blob = new Blob([response.data], {
    type: mimeAuto ? response.headers[`content-type`] : mimeType,
  });
  saveAs(blob, filename);
};

export const saveToCSV = (csv: any, fileName: string = "file") => {
  const blob = new Blob([csv], {
    type: "text/csv",
  });
  saveAs(blob, fileName);
};

export const getCSVResponse = async (endpoint, params = null, method) => {
  const token = authStore.getToken;
  const response = await axios({
    url: endpoint,
    method: method,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    responseType: 'arraybuffer',
  });

  return response;
};

export const downloadExcelFile = async (endpoint, filename, params, method) => {
  const token = authStore.getToken;
  const client_id = authStore.getClient;
  const response = await axios({
    url: endpoint,
    method: method,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      ["X-client"]: client_id,
    },
    responseType: "arraybuffer",
  });
  downloadFileFromArrayBuffer(response, filename);
};

export const downloadCSVFile = async (enpoint, params = null, method) => {
  const response = await getCSVResponse(enpoint, params, method);
  saveToCSV(response.data, getFilename(response));
};

export const isExcelFile = (fileName) => {
  if (!fileName) {
    throw "cant resolve file type of unknown file.";
  }
  let idxDot = fileName.lastIndexOf(".") + 1;
  let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
  return extFile === "xlsx" || extFile === "csv" || extFile === "xls";
};
