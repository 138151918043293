import * as React from "react";
import style from "./index.module.scss";

interface MappingLogoProps {
  className?: string;
  size?: number;
}

export const MappingLogo: React.FC<MappingLogoProps> = ({className, size}) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width={size || 32}
        height={size || 32}
        viewBox="0 0 32.717 19.5"
      >
        <g
          id="Group_13900"
          data-name="Group 13900"
          transform="translate(0.635)"
        >
          <g
            id="Group_6129"
            data-name="Group 6129"
            transform="translate(-0.635 0)"
          >
            <g id="poweredby-accendo">
              <g id="Group_2" data-name="Group 2" transform="translate(14.526)">
                <g id="Group_1" data-name="Group 1" transform="translate(0)">
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M312.576-796.955a17.186,17.186,0,0,1-4.767,12.5l1.838,2.872c3.4-4.215,5.165-8.894,5.336-16.055h-2.431Z"
                    transform="translate(-307.809 797.636)"
                    fill="#3bbced"
                  />
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M298.1-796.934v.053a31.579,31.579,0,0,1-1.527,8.612,17.958,17.958,0,0,1-1.132-5.552,18.52,18.52,0,0,0,.278-3.113l-.026-.7h2.431C298.121-797.4,298.113-797.166,298.1-796.934Z"
                    transform="translate(-290.952 797.636)"
                    fill="#0c95c9"
                  />
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M274.784-797.635a27.109,27.109,0,0,0,8.174,19.5,8.542,8.542,0,0,1,5.274-1.442,19.8,19.8,0,0,1-11.04-17.378,1.575,1.575,0,0,0,.572-.232,1.509,1.509,0,0,0,.444-.448Z"
                    transform="translate(-270.04 797.637)"
                    fill="#0373ac"
                  />
                </g>
              </g>
              <g id="Group_2-2" data-name="Group 2" transform="translate(0)">
                <g id="Group_1-2" data-name="Group 1" transform="translate(0)">
                  <path
                    id="Path_1-2"
                    data-name="Path 1"
                    d="M310.217-796.955a17.186,17.186,0,0,0,4.767,12.5l-1.838,2.872c-3.4-4.215-5.165-8.894-5.336-16.055h2.431Z"
                    transform="translate(-296.792 797.636)"
                    fill="#3bbced"
                  />
                  <path
                    id="Path_2-2"
                    data-name="Path 2"
                    d="M295.468-796.934v.053a31.579,31.579,0,0,0,1.527,8.612,17.958,17.958,0,0,0,1.132-5.552,18.52,18.52,0,0,1-.278-3.113l.026-.7h-2.431C295.449-797.4,295.456-797.166,295.468-796.934Z"
                    transform="translate(-284.426 797.636)"
                    fill="#0c95c9"
                  />
                  <path
                    id="Path_3-2"
                    data-name="Path 3"
                    d="M288.232-797.635a27.109,27.109,0,0,1-8.174,19.5,8.542,8.542,0,0,0-5.273-1.442,19.8,19.8,0,0,0,11.04-17.378,1.574,1.574,0,0,1-.572-.232,1.509,1.509,0,0,1-.444-.448Z"
                    transform="translate(-274.784 797.637)"
                    fill="#0373ac"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
