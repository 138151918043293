//Store for WorkbookCreation

import { makeAutoObservable } from "mobx";
import {
  createWorkBookApi, getWorkBookApi,
  updateWorkBookApi,
} from "container/Workbook/WorkbookCreation/store/workbookCreation.api";
import {WorkbookCreationApisPayload} from "container/Workbook/WorkbookCreation/store/workbookCreation.types";

class WorkbookCreationStore {
  constructor() {
    makeAutoObservable(this);
  }

  loading = false;

  setLoading = (bool: boolean) => {
   this.loading = bool
  }

  //api calls section
  callCreateWorkbookApi = async (payload: WorkbookCreationApisPayload) => {
    await createWorkBookApi.callApi(payload);
  };

  callUpdateWorkbookApi = async (payload: WorkbookCreationApisPayload) => {
    await updateWorkBookApi.callApi(payload);
  };


}

export const workbookCreationStore = new WorkbookCreationStore();
