import * as React from "react";
import style from "./index.module.scss";
import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  btnIcon?: ReactNode;
  className?: string;
  color?: "primary" | "transparent" | "transparentPrimary" |"white";
  loading?: boolean;
  buttonLabelClass?: string;
}

export const Button: React.FC<IButtonProps> = (props) => {
  const {
    btnIcon,
    className,
    children,
    loading,
    color = "primary",
    buttonLabelClass,
    ...otherProps
  } = props;

  return (
    <button
      className={`${style.button} ${style[color]} ${
        loading && style.loading
      } ${className}`}
      {...otherProps}
    >
      {loading ? (
        <span className={style.btnSpinner}>
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          ) : btnIcon ? (
            btnIcon
          ) : null}
        </span>
      ) : (
        <span className={`${style.btnLabel} ${buttonLabelClass}`}>
          {children}
        </span>
      )}
    </button>
  );
};
