import * as React from "react";
import { toast } from "react-toastify";
import {snakeCase} from "lodash";


const urlCreation = () => {
    const pathname = window.location.pathname;
    const segments = pathname.split("/").filter(Boolean);
    let lastSegment: string | number = segments.pop();

    if (!isNaN(Number(lastSegment)) && segments.length > 0) {
        lastSegment = segments.pop();
    }
    return lastSegment
}

const success = (message: string) => {
  toast.success(message, {
      toastId: `${urlCreation()}-${snakeCase(message)}-success`,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 10000,
  });
};

const info = (message: string) => {
    toast.info(message, {
        toastId: `${urlCreation()}-${snakeCase(message)}-info`,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 10000,
  });
};

const warning = (message: string) => {
    toast.warning(message, {
        toastId: `${urlCreation()}-${snakeCase(message)}-warning`,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 10000,
  });
};

const error = (message: string) => {
    toast.error(message, {
        toastId: `${urlCreation()}-${snakeCase(message)}-error`,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 10000,
  });
};


export const Message = {
  success,
  info,
  warning,
  error,
};
