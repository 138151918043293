import React from "react";
import style from "./index.module.scss";

export const columnsWorkRelatedBehaviours = [
    {
        Header: "S.No",
        accessor: "id",
        Cell: ({ row }) => <div className={style.w50}>{row.original.id}</div>,
    },
    {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => (
            <div className={style.w250}>{row.original.name || "-"}</div>
        ),
    },
    {
        Header: "Low Text",
        accessor: "low_text",
        disableSortBy: true,
        Cell: ({ row }) => (
            <div
                className={
                    row.original.low_text === null ? style.w250 : style.score_text
                }
            >
                {row.original.low_text || "No Low Text Available"}
            </div>
        ),
    },
    {
        Header: "High Text",
        accessor: "high_text",
        disableSortBy: true,
        Cell: ({ row }) => (
            <div
                className={
                    row.original.high_text === null ? style.w250 : style.score_text
                }
            >
                {row.original.high_text || "No High Text Available"}
            </div>
        ),
    },
    {
        Header: "Group",
        accessor: "group",
        disableSortBy: true,
        Cell: ({ row }) => (
            <div className={style.w100}>{row.original.group || "-"}</div>
        ),
    },
    {
        Header: "Order",
        accessor: "order",
        Cell: ({ row }) => (
            <div className={style.w100}>{row.original.order || "-"}</div>
        ),
    },
];
