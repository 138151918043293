import * as React from "react";
import style from "./singleTableData.module.scss";

interface SingleTableDataProps {
  zScore: string;
  sten: string;
}

export const SingleTableData: React.FC<SingleTableDataProps> = ({
  sten,
  zScore,
}) => {
  return (
    <div className={`d-flex justify-content-between align-items-center py-1`}>
      <div className={`w-50 d-flex justify-content-center align-items-center`}>{zScore}</div>
      <div className={`w-50 d-flex justify-content-center align-items-center`}>{sten}</div>
    </div>
  );
};
