/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-34
 */

// screen file for LookUpXL
import React from "react";
import style from "./index.module.scss";
import { LookUpXLProps } from "container/LookUpXL/store/lookUpXL.types";
import { PageTitle } from "components/PageTitle";
import { Button } from "components/Button";
import { Title } from "components/Title";
import { PageBase } from "components/PageBase";
import { lookUpXLStore } from "./store/lookUpXL.store";
import { useEffectAsync } from "utlis/hooks";
import { observer } from "mobx-react";
import { columnsLookup } from "./tableColumn";
import { authStore } from "stores/AuthStore";
import { Pagination } from "components/Pagination";
import { SearchInput } from "components/SearchInput";
import { Table } from "components/Table";
import { getLookupData } from "container/LookUpXL/store/lookUpXL.api";

export const LookUpXL: React.FC<LookUpXLProps> = observer(({}) => {
  useEffectAsync(async () => {
    await lookUpXLStore.callGetLookUpXLApi();
  }, [authStore.getClientIdToken]);

  return (
    <PageBase className={style.lookUpContainer}>
      <PageTitle pageTitle={`Upload Lookup XL`} />
      <div className={style.topDropdown}>
        <div className={style.titleSearch}>
          <Title count={lookUpXLStore.getTotalData}>Lookup XL</Title>
          <SearchInput
            placeholder={"Search"}
            onChange={(e) => lookUpXLStore.setSearch(e.target.value)}
          />
        </div>
        <div className={style.filesContainer}>
          <div className={style.uploadFile}>
            <div className={style.label}>Upload New Document:</div>
            <Button
              className={`ms-2`}
              onClick={() => lookUpXLStore.lookupRef.click()}
              loading={lookUpXLStore.isLookupXLUploadLoading}
              disabled={lookUpXLStore.isLookupXLUploadLoading}
            >
              Upload File
            </Button>
            <input
              className="d-none"
              type="file"
              accept=".xlsx, .xls"
              id={"input"}
              ref={(ref) => (lookUpXLStore.lookupRef = ref)}
              value=""
              onChange={(e) => lookUpXLStore.handleUploadLookupXL(e)}
            />
          </div>
          <div className={style.downloadSection}>
            <div className={style.label}>
              Download Previous Uploaded Document:
            </div>
            <Button
              className={`ms-2`}
              onClick={() => lookUpXLStore.downloadLookupXLData()}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
      <div className={style.tableHeight}>
        <Table
          columns={columnsLookup}
          data={lookUpXLStore.getTableData}
          isLoading={getLookupData.isLoading}
        />
      </div>
      <Pagination
        currentPage={lookUpXLStore.getMetaData?.current_page}
        lastPage={lookUpXLStore.getMetaData?.last_page}
        onPageClick={(e) => lookUpXLStore.setPage(e)}
        onNextClick={() =>
          lookUpXLStore.setPage(lookUpXLStore.getMetaData?.current_page + 1)
        }
        onPreviousClick={() =>
          lookUpXLStore.setPage(lookUpXLStore.getMetaData?.current_page - 1)
        }
        onLastClick={() =>
          lookUpXLStore.setPage(lookUpXLStore.getMetaData?.last_page)
        }
        onFirstClick={() => lookUpXLStore.setPage(1)}
      />
    </PageBase>
  );
});
