import { FormStore } from "stores/FormStore";
import { Message } from "components/Message";
import { categoryManagementStore } from "container/CategoryManagement/store/categoryManagement.store";
import {
  createCategoriesApi,
  editCategoriesApi,
} from "container/CategoryManagement/store/categoryManagement.api";
import { workbookConfigurationStore } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.store";
import { get } from "lodash";

interface CategoryManagementFormStore {
  name: string;
  category_code: string;
  category_source: string;
  description: string;
  high_text: string;
  low_text: string;
  category_id: number;
}

export const categoryManagementFormStore =
  new FormStore<CategoryManagementFormStore>(
    {
      name: {
        label: "Category name",
        placeholder: "Name your Category",
        bindings: "extended",
      },
      category_code: {
        label: "Category code",
        placeholder: "Code your Category",
        bindings: "extended",
      },
      description: {
        label: "Description",
        placeholder: "Describe your category",
        bindings: "extended",
      },
      category_source: {
        label: "Category source",
        placeholder: "Select your category source",
        bindings: "extended",
      },
      high_text: {
        label: "High text",
        placeholder: "Type high text here",
        bindings: "extended",
      },

      low_text: {
        label: "Low text",
        placeholder: "Type low text here",
        bindings: "extended",
      },
      legend: {
        label: "Legend",
        placeholder: "Type legend text here",
        bindings: "extended",
      },
      to_clone: {},
      acl_category_id: {},
      category_id: {},
    },
    {
      options: {
        validateOnChange: true,
      },
      hooks: {
        onSuccess: async (form: FormStore) => {
          const value = form.values();
          categoryManagementStore.isEdit
            ? value.category_source.value === "others"
              ? await editCategoriesApi.callApi({
                  name: value.name,
                  category_source: value.category_source.value,
                  category_code: value.category_code,
                  description: value.description,
                  category_id: value.category_id,
                  is_save_as_new: categoryManagementStore.saveAsNew,
                  legend: value.legend,
                  to_clone: categoryManagementStore.enableClone,
                  workbook_id:
                    (categoryManagementStore.fromWorkbookConfiguration &&
                      workbookConfigurationStore.workbookId) ||
                    undefined,
                })
              : await editCategoriesApi.callApi({
                  name: value.name,
                  category_source:
                    categoryManagementStore.saveAsNew ||
                    value.category_source.value === "acl"
                      ? "others"
                      : value.category_source.value,
                  category_code: value.category_code,
                  description: value.description,
                  category_id: value.category_id,
                  high_text: value.high_text || undefined,
                  low_text: value.low_text || undefined,
                  is_save_as_new: categoryManagementStore.saveAsNew,
                  to_clone: categoryManagementStore.enableClone,
                  workbook_id:
                    (categoryManagementStore.fromWorkbookConfiguration &&
                      workbookConfigurationStore.workbookId) ||
                    undefined,
                })
            : value.category_source.value === "others" ||
              categoryManagementStore.saveAsNew
            ? await createCategoriesApi.callApi({
                name: value.name,
                category_code: value.category_code,
                description: value.description,
                legend: value.legend,
                category_source: categoryManagementStore.saveAsNew
                  ? "others"
                  : value.category_source.value,
              })
            : value.category_source.value === "acl"
            ? await createCategoriesApi.callApi({
                name: value.name,
                category_code: value.category_code,
                description: value.description,
                category_source: value.category_source.value,
                to_clone: categoryManagementStore.enableClone,
                acl_category_id: value.acl_category_id,
                parent_id:
                  workbookConfigurationStore.fromWorkbookConfigurationData
                    .parent_id,
                workbook_id:
                  (categoryManagementStore.fromWorkbookConfiguration &&
                    workbookConfigurationStore.workbookId) ||
                  undefined,
              })
            : await createCategoriesApi.callApi({
                name: value.name,
                category_code: value.category_code,
                description: value.description,
                high_text: value.high_text,
                low_text: value.low_text,
                category_source: value.category_source.value,
              });
          categoryManagementStore.isEdit
            ? Message.success(`Category has been edited successfully`)
            : Message.success(`Category has been created successfully`);
          if (workbookConfigurationStore.fromWorkbookConfiguration) {
            if (
              !categoryManagementStore.isEdit &&
              value.category_source.value !== "acl"
            ) {
              workbookConfigurationStore.addCategoryApi(
                { value: get(createCategoriesApi.data, "id") },
                workbookConfigurationStore.fromWorkbookConfigurationData
              );
            }
            workbookConfigurationStore.callGetFormulaTreeApi(
              workbookConfigurationStore.workbookId
            );
          }
          categoryManagementStore.setLoading(false);
          categoryManagementStore.setEditOpen(false);
          workbookConfigurationStore.setCreateEditModalIsOpen(false);
          categoryManagementStore.setWorkbookData(false);
          return Promise.resolve();
        },
        onError: (form) => {
          Message.error("Incorrect form information");
          return Promise.reject(`Incorrect form information`);
        },
      },
    }
  );
