import * as React from "react";
import ReactSelect from "react-select";
import style from "./index.module.scss";
import { useRef } from "react";
import { ISelectProps } from "./index.types";
import { checkRequiredByRules, useShowAsterisk } from "utlis/form";
import { Asterisk } from "../Asterisk";
import { Label } from "../Label";
import { ErrorText } from "../ErrorText";
import { SkeletonBox } from "components/SkeletonBox";
import Creatable, { useCreatable } from "react-select/creatable";

export const Select: React.FC<ISelectProps> = ({
  label,
  showError = true,
  error,
  borderLess,
  rules,
  containerClassName,
  className,
  labelClass,
  errorClassName,
  isLoadingComponent,
  needSelectAsterisk = true,
  isCreatable = false,
  ...otherProps
}) => {
  const selectRef = useRef<HTMLInputElement | any>();
  const isRequired = checkRequiredByRules(rules);

  const showAsterisk = useShowAsterisk(
    selectRef.current && selectRef.current.state.value
  );

  return (
    <div className={`${style.select} ${containerClassName}`}>
      {label && (
        <Label
          className={`${style.labelText} ${labelClass}`}
          showAsterisk={isRequired && showAsterisk}
        >
          {label}
        </Label>
      )}
      {isRequired &&
        !isLoadingComponent &&
        showAsterisk &&
        needSelectAsterisk && <Asterisk className={style.asterisk} />}
      {isLoadingComponent ? (
        <SkeletonBox
          className={style.loading}
          width={"100%"}
          height={"100%"}
          borderRadius={"8px"}
        />
      ) : isCreatable ? (
        <Creatable
          className={`${style.selectInner} ${
            borderLess && `${style.borderLess}`
          } ${error && `${style.borderError}`} ${className} `}
          classNamePrefix={"select"}
          {...otherProps}
          captureMenuScroll={false}
          ref={selectRef as any}
        />
      ) : (
        <ReactSelect
          className={`${style.selectInner} ${
            borderLess && `${style.borderLess}`
          } ${error && `${style.borderError}`} ${className} `}
          classNamePrefix={"select"}
          {...otherProps}
          captureMenuScroll={false}
          ref={selectRef as any}
        />
      )}

      <ErrorText className={errorClassName} show={showError}>
        {error}
      </ErrorText>
    </div>
  );
};
