// Apis For WorkbookCreation
import { ApiStore } from "stores/ApiStore";
import { RestMethods } from "stores/baseApi";
import {WorkbookCreationApisPayload} from "container/Workbook/WorkbookCreation/store/workbookCreation.types";


const ENDPOINT_TEMPLATE = "api/workbook";

class WorkbookCreationApis extends ApiStore<
  WorkbookCreationApisPayload,
  null,
  null,
  null
> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static createWorkBookApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/create`;
    return new WorkbookCreationApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }

  static updateWorkBookApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:workbook_id/update`;
    return new WorkbookCreationApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }

  static getWorkBookApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:workbook_id/getWorkbook`;
    return new WorkbookCreationApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }
}

export const createWorkBookApi = WorkbookCreationApis.createWorkBookApi();
export const updateWorkBookApi = WorkbookCreationApis.updateWorkBookApi();
export const getWorkBookApi = WorkbookCreationApis.getWorkBookApi();
