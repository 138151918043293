/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-23
 */

// screen file for ViewDataset
import React, { useState } from "react";
import style from "./index.module.scss";
import { ViewDatasetProps } from "container/Workbook/ViewDataset/store/viewDataset.types";
import { MAPPING_DOMAIN, WORKBOOK_DOMAIN } from "constants/routes";
import { HeaderFooterContainer } from "components/HeaderFooterContainer";
import { useNavigate, useParams } from "react-router-dom";
import { HeaderWorkbook } from "container/Workbook/HeaderWorkbook";
import { Title } from "components/Title";
import { SearchInput } from "components/SearchInput";
import { Select } from "components/Select";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { viewDatasetStore } from "container/Workbook/ViewDataset/store/viewDataset.store";
import { observer } from "mobx-react";
import { CheckBox } from "components/CheckBox";
import { Label } from "components/Label";
import { useEffectAsync } from "utlis/hooks";
import {find, isEmpty, map} from "lodash";
import {
  columnViewDataFixed,
} from "container/Workbook/ViewDataset/tableColumn";
import { reviewMappingStore } from "container/Workbook/ReviewMapping/store/reviewMapping.store";
import { Message } from "components/Message";
import { globalContainerStore } from "container/_globalContainerStore/globalContainerStore.store";
import { Table } from "components/Table";
import {
  finalizeWorkbook,
  getCalculatedData,
} from "container/Workbook/ViewDataset/store/viewDataset.api";
import { toJS } from "mobx";
import { SelectBoxWithCheckbox } from "components/Select/SelectBoxWithCheckbox";
import {TextOverflowWithTooltip} from "components/TextOverflowWithTooltip";

export const ViewDataset: React.FC<ViewDatasetProps> = observer(({}) => {
  const [columnViewData, setColumnViewData] = useState(columnViewDataFixed);
  const [searchString, setSearchString] = useState("");
  const [additionalFields, setAdditionalFields] = useState(false);
  const [finalize, setFinalize] = useState(false);

  const getWorkbookColumns = [
    {
      Header: "Index",
      accessor: "id",
      width: 50,
      minWidth: 50,
    },
    {
      Header: "Tool",
      accessor: "tool",
      Cell: ({ row }) => (
          <TextOverflowWithTooltip>
            {row.original.tool}
          </TextOverflowWithTooltip>
      ),
    },
    {
      Header: "Field",
      accessor: "field",
      Cell: ({ row }) => (
          <TextOverflowWithTooltip>
            {row.original.field}
          </TextOverflowWithTooltip>
      ),
    },
    {
      Header: "Type",
      accessor: "type",
      width: 50,
      minWidth: 50,
    },
    {
      Header: "Show in Excel",
      accessor: "show_in_excel",
      disableSortBy: true,
      width: 90,
      minWidth: 70,
      Cell: ({ row }) => {
        const [check, setCheck] = useState<boolean>(
            find(viewDatasetStore.filter_ids, (item) => item === row.original.id)
                ? !row.original.show_in_excel
                : row.original.show_in_excel
        );
        return (
            <CheckBox
                onChange={() => {
                  setCheck(!check);
                  if (find(viewDatasetStore.filter_ids, (item) => item === row.original.id)) {
                    viewDatasetStore.removeFilterIds(row.original.id);
                  } else {
                    viewDatasetStore.setFilterIds(row.original.id);
                  }
                }}
                checked={check}
            />
        );
      },
    },
  ];

  let navigate = useNavigate();
  const { workbookId, level_id, journey_id } = useParams();
  useEffectAsync(async () => {
    viewDatasetStore.clearFields();
    await globalContainerStore.callGetWorkbookApi({
      workbook_id: workbookId,
    });
    await reviewMappingStore.callGetJourneysApi(
      globalContainerStore.getWorkbookClientsIds
    );
    await viewDatasetStore.setSelectedJourneyName(journey_id);
  }, []);

  useEffectAsync(async () => {
    if (viewDatasetStore.selectJourney) {
      await viewDatasetStore.callGetCalculatedData({
        journey_id: viewDatasetStore.selectJourney.value,
        norm_group_id: level_id,
        workbook_id: workbookId,
      });

      setColumnViewData([
        ...columnViewDataFixed,
        ...map(viewDatasetStore.getAdditionalFields, (item) => ({
          Header: item,
          accessor: item,
          width: 250,
          minWidth: 250,
        })),
      ]);
    }
  }, [viewDatasetStore.selectJourney]);

  useEffectAsync(async () => {
    if (!isEmpty(viewDatasetStore.filter_ids)) {
      await viewDatasetStore.callSetWorkbookFields({
        field_ids: viewDatasetStore.filter_ids,
        workbook_id: workbookId,
      });
    }
    await viewDatasetStore.callGetWorkbookFields({
      search: searchString,
      include_others: additionalFields ? 1 : 0,
      page: 1,
      workbook_id: workbookId,
    });
  }, [searchString, additionalFields]);

  return (
    <HeaderFooterContainer
      header={<HeaderWorkbook />}
      actionBtnOnClick={() => setFinalize(true)}
      actionBtnLabel={"Finalize"}
      actionBtnProps={{ loading: finalizeWorkbook.isLoading }}
      cancelBtnOnClick={() =>
        navigate(`/${WORKBOOK_DOMAIN}/review/${workbookId}`)
      }
    >
      <div className={style.viewDataSetContainer}>
        <div className={`d-flex justify-content-between`}>
          <Title count={viewDatasetStore.getCalculatedData.length}>
            {viewDatasetStore.getSelectedJourneyName}
          </Title>
          <div className={`d-flex`}>
            <Select
              errorClassName={`ms-2`}
              className={`ms-2 ${style.selectLevel}`}
              placeholder={"Select Journey"}
              options={reviewMappingStore.getJourneyOptionData}
              value={viewDatasetStore.selectJourney}
              onChange={(e) => {
                viewDatasetStore.setFinalizeJourney(e);
                viewDatasetStore.setJourneyId(e);
              }}
            />
            <Button
              className={`ms-2 ${style.button}`}
              onClick={() => viewDatasetStore.setFilter(true)}
            >
              Select Fields
            </Button>
          </div>
        </div>
        <div className={style.tableDataSet}>
          <Table
            columns={columnViewData}
            data={viewDatasetStore.getCalculatedData}
            isLoading={getCalculatedData.isLoading}
          />
        </div>
      </div>
      <Modal
        toggle={() => viewDatasetStore.setFilter(!viewDatasetStore.filter)}
        isOpen={viewDatasetStore.filter}
        headerTitle={"Select Fields"}
        actionBtnLabel={"Confirm"}
        cancelBtnLabel={"Close"}
        modalSize={style.modalSize}
        actionBtnOnClick={async () => {
          await viewDatasetStore.callSetWorkbookFields({
            field_ids: viewDatasetStore.filter_ids,
            workbook_id: workbookId,
          });
          await viewDatasetStore.callGetCalculatedData({
            journey_id: journey_id,
            norm_group_id: level_id,
            workbook_id: workbookId,
          });
          setColumnViewData([
            ...columnViewDataFixed,
            ...map(viewDatasetStore.getAdditionalFields, (item) => ({
              Header: item,
              accessor: item,
              width: 250,
              minWidth: 250,
            })),
          ]);
        }}
      >
        <div>
          <SearchInput
            placeholder={"Search Fields"}
            label={"Use the search to find your..."}
            onChange={(e) => setSearchString(e.target.value)}
          />
          <CheckBox
            onChange={() => setAdditionalFields(!additionalFields)}
            checked={additionalFields}
            className={`ms-2 mb-3`}
            label={"Search include additional fields"}
          />
          <Label>
            List of fields (tools are automatically detected from mapping tree)
          </Label>
          <div className={style.tableSize}>
            <Table
              columns={getWorkbookColumns}
              data={viewDatasetStore.getWorkbookFields}
            />
          </div>
        </div>
      </Modal>
      <Modal
        toggle={() => setFinalize(!finalize)}
        isOpen={finalize}
        headerTitle={"Finalize Journey"}
        actionBtnLabel={"Confirm"}
        cancelBtnLabel={"Close"}
        cancelBtnOnClick={() => {
          viewDatasetStore.setFinalizeJourney(viewDatasetStore.selectJourney);
        }}
        actionBtnOnClick={async () => {
          try {
            await viewDatasetStore.callFinalizeWorkbook({
              workbook_id: workbookId,
              journey_ids: map(
                viewDatasetStore.finalizeJourney,
                (item) => item.value
              ),
            });
            Message.success("Successfully finalize workbook");
            navigate(`/${MAPPING_DOMAIN}`);
          } catch (e) {}
        }}
      >
        <div className={style.confirmationModal}>
          Choose the journey that you want to finalize with this workbook
          <CheckBox
            onChange={() =>
              viewDatasetStore.setFinalizeJourney(
                reviewMappingStore.getJourneyOptionData
              )
            }
            checked={
              reviewMappingStore.getJourneyOptionData?.length ===
              viewDatasetStore.finalizeJourney?.length
            }
            className={`mt-3`}
            label={"Select All Journeys"}
          />
          <SelectBoxWithCheckbox
            isMulti={true}
            isClearable={true}
            errorClassName={`ms-2`}
            className={`mt-2`}
            placeholder={"Select Journey"}
            options={reviewMappingStore.getJourneyOptionData}
            value={toJS(viewDatasetStore.finalizeJourney)}
            onChange={(e) => viewDatasetStore.setFinalizeJourney(e)}
          />
        </div>
      </Modal>
    </HeaderFooterContainer>
  );
});
