// Apis For MapperLanding
import { ApiStore } from "stores/ApiStore";
import { RestMethods } from "stores/baseApi";

export interface MapperLandingApisPayload{
  mapper_id?: string | number;
  name?: string;
  page?: string | number;
}

const ENDPOINT_TEMPLATE = "/api/mapperTemplate";

class MapperLandingApis extends ApiStore<
  MapperLandingApisPayload,
  null,
  null,
  null
> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static getMapperTemplate() {
    const endpoint = `${ENDPOINT_TEMPLATE}/get`;
    return new MapperLandingApis(endpoint, { requestType: RestMethods.GET });
  }
  static uploadData() {
    const endpoint = `${ENDPOINT_TEMPLATE}/uploadData`;
    return new MapperLandingApis(endpoint, { requestType: RestMethods.POST });
  }
  static deleteMapperTemplate() {
    const endpoint = `${ENDPOINT_TEMPLATE}/delete/:mapper_id`;
    return new MapperLandingApis(endpoint, { requestType: RestMethods.POST });
  }

}

export const getMapperTemplate = MapperLandingApis.getMapperTemplate();
export const uploadData = MapperLandingApis.uploadData();
export const deleteMapperTemplate = MapperLandingApis.deleteMapperTemplate();
