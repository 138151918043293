import * as React from "react";
import style from "./index.module.scss";

import { Ref } from "react";

import { checkRequiredByRules, useShowAsterisk } from "utlis/form";
import { ErrorText } from "../ErrorText";
import { Asterisk } from "../Asterisk";
import { Label } from "../Label";
import { SkeletonBox } from "components/SkeletonBox";
import ReactSelect from "react-select";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  showError?: boolean;
  error?: string;
  rightAppendAddon?: Element | React.ReactNode;
  rightAppendAddonClassName?: string;
  className?: string;
  inputContainerClassName?: string;
  getInput?: (input: HTMLInputElement) => void;
  rules?: string;
  showAsterisk?: boolean;
  ref?: Ref<HTMLInputElement>;
  textarea?: boolean;
  rows?: number;
  isLoading?: boolean;
  removeError?: boolean;
}

export const Input: React.FC<InputProps> = React.forwardRef(
  (props: InputProps | any, ref: any) => {
    const { showAsterisk = true, textarea = false } = props;
    const isRequired = checkRequiredByRules(props.rules);

    const asteriskState = useShowAsterisk(ref && ref.value);

    props.getInput && props.getInput(ref);

    const {
      label,
      showError = "true",
      error,
      rightAppendAddon,
      rightAppendAddonClassName,
      className,
      inputContainerClassName,
      isLoading,
      removeError,
      ...otherProps
    } = props;
    return (
      <div className={`${style.inputMain} ${className} `}>
        {label && (
          <Label showAsterisk={isRequired && showAsterisk}>{label}</Label>
        )}
        {isLoading ? (
          <SkeletonBox
            className={style.loading}
            width={"100%"}
            height={"100%"}
            borderRadius={"8px"}
          />
        ) : (
          <div className={style.inputContainer}>
            {textarea ? (
              <textarea
                cols={3}
                className={`${inputContainerClassName} ${style.input} ${
                  error && style.inputError
                }`}
                ref={ref}
                rows={props.rows}
                {...otherProps}
              />
            ) : (
              <input
                className={`${inputContainerClassName} ${style.input} ${
                  style.height
                } ${error && style.inputError}`}
                ref={ref}
                {...otherProps}
              />
            )}
            <div
              className={`${style.rightAppendAddon} ${rightAppendAddonClassName}`}
            >
              {rightAppendAddon && rightAppendAddon}
            </div>
            {!label && showAsterisk && isRequired && asteriskState && (
              <Asterisk className={style.asterisk} />
            )}
          </div>
        )}
        {!removeError && <ErrorText show={showError}>{error}</ErrorText>}
      </div>
    );
  }
);
