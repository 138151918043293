import * as React from "react";
import * as style from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { MAPPER_TEMPLATE_DOMAIN } from "constants/routes";

interface GoToLinkProps {
  to: string;
}

export const GoToLink: React.FC<GoToLinkProps> = ({ to, children }) => {
  let navigate = useNavigate();
  return <div onClick={() => navigate(to)}>{children}</div>;
};
