import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";

const root = document.createElement("div");
root.className = 'mappingContainer'
document.body.appendChild(root);
document.body.classList.add('light-theme');

ReactDOM.render(<App />, root);

