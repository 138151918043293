import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "container/Landing/index.module.scss";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { DropdownActionButton } from "components/Dropdown/DropdownActionButton";
import React  from "react";
import { DropdownItemData } from "components/Dropdown/DropdownBase";
import { WORKBOOK_DOMAIN } from "constants/routes";
import { landingStore } from "container/Landing/store/landing.store";
import { ConfirmationBox } from "components/ConfirmationBox";
import { Message } from "components/Message";
import { format, parseISO } from "date-fns";
import { isNil } from "lodash";
import { globalContainerStore } from "container/_globalContainerStore/globalContainerStore.store";
import { Select } from "components/Select";
import { authStore } from "stores/AuthStore";

const mappingDropDownItems = (data) => [
  {
    name: "Edit Workbook",
    to: `/${WORKBOOK_DOMAIN}/create/${data?.id}`,
  },
  {
    name: "Download Scores",
    onClick: () => {
      landingStore.setWorkbookId(data?.id, data?.name);
      landingStore.setDownload(true);
    },
  },
  {
    name: "Delete",
    onClick: async () => {
      await ConfirmationBox.alert(
        <div>
          Are you sure want to delete
          <span className={`${style.boldText} mx-1`}>{data?.name}</span>?
        </div>,
        {
          confirmBtnOnClick: async () => {
            try {
              landingStore.setLoading(true);
              await landingStore.callWorkBookDeleteApi(data?.id);
              await landingStore.callWorkBookApi();
              Message.success("Successfully removed workbook.");
              landingStore.setLoading(false);
            } catch (e) {
              await landingStore.callWorkBookApi();
              landingStore.setLoading(false);
              Message.error("Failed to removed workbook.");
              console.error("Error removing workbook.");
            }
          },
        }
      );
    },
  },
  {
    name: "View Log",
    onClick: () => {
      landingStore.setWorkbookId(data?.id, data?.name);
      landingStore.setLog(true);
    },
  },
  {
    name: "Track Finalize",
    onClick: () => {
      landingStore.setWorkbookId(data?.id, data?.name);
      landingStore.setTrack(true);
    },
  },
  {
    name: "Clone",
    onClick: async () => {
      await ConfirmationBox.alert(
        <div className={style.confirmHeight}>
          <div className="mb-2">Are you sure you want to clone <span className={`${style.boldText}`}>{data?.name}</span> to the selected client?</div>
          <Select
            placeholder={"Client"}
            options={globalContainerStore.getClientOptionData}
            showError={false}
            defaultValue={landingStore.clientSelectedOption(parseInt(authStore.getClient))}
            onChange={(e: any) => {
              landingStore.setSelectedCloneClient(e.value);
            }}
            maxMenuHeight={200}
          />
        </div>,
        {
          modalHeaderText: "Clone Workbook?",
          confirmBtnText: "Clone",
          confirmBtnOnClick: async () => {
            try {
              landingStore.setLoading(true);
              await landingStore.callWorkBookCloneApi(
                data?.id,
                landingStore.selectedCloneClient
              );
              await landingStore.callWorkBookApi();
              Message.success("Successfully clone workbook.");
              landingStore.setLoading(false);
            } catch (e) {
              Message.error("Failed to clone workbook.");
              console.error("Error cloning workbook.");
            }
          },
        }
      );
    },
  },
];

export const mockColumnsMappingRecent = (recent: boolean) => [
  {
    id: "icon",
    Header: () => null,
    Cell: ({ row }) => (
      <FontAwesomeIcon className={style.fileIcon} icon={faFile} />
    ),
    disableSortBy: true,
    width: 10,
    maxWidth: 10,
    disableResizing: true,
  },
  {
    Header: `${recent ? "Last 7 Days" : "Older"}`,
    accessor: "name",
    width: 500,
    minWidth: 500,
  },
  {
    Header: "Status",
    accessor: "status",
    width: 100,
    minWidth: 100,
  },
  {
    Header: "Owner",
    accessor: "owner",
    width: 250,
    minWidth: 250,
    Cell: ({ row }) => <div>{row.original.owner?.name || "-"}</div>,
  },
  {
    Header: "Last Updated",
    accessor: "updated_at",
    width: 200,
    minWidth: 200,
    Cell: ({ row }) => (
      <div>
        {isNil(row.original.updated_at)
          ? "-"
          : format(parseISO(row.original.updated_at), "PPpp")}
      </div>
    ),
  },
  {
    Header: "Action",
    id: "action",
    disableSortBy: true,
    width: 90,
    minWidth: 70,
    Cell: ({ row }) => (
      <div>
        <DropdownActionButton
          enableScroll={true}
          dropdownItem={mappingDropDownItems(row.original)}
        />
      </div>
    ),
  },
];

export const viewLogColumns = [
  {
    Header: "User",
    accessor: "user",
    width: 200,
    minWidth: 200,
  },
  {
    Header: "Page",
    accessor: "page",
    width: 250,
    minWidth: 200,
  },
  {
    Header: "Event",
    accessor: "event",
    width: 350,
    minWidth: 300,
  },
  {
    Header: "Updated on",
    accessor: "date",
    width: 200,
    minWidth: 200,
    Cell: ({ row }) => (
      <div>
        {isNil(row.original.updated_at)
          ? "-"
          : format(parseISO(row.original.updated_at), "PPpp")}
      </div>
    ),
  },
];

export const trackFinalizeColumns = [
  {
    Header: "Journey",
    accessor: "journey.name",
    width: 350,
    minWidth: 300,
  },
  {
    Header: "Status",
    accessor: "status",
    width: 300,
    minWidth: 250,
  },
  {
    Header: "Updated on",
    accessor: "date",
    width: 200,
    minWidth: 200,
    Cell: ({ row }) => (
      <div>
        {isNil(row.original.updated_at)
          ? "-"
          : format(parseISO(row.original.updated_at), "PPpp")}
      </div>
    ),
  },
];
