import * as React from "react";
import style from "./index.module.scss";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffectAsync } from "utlis/hooks";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { globalContainerStore } from "container/_globalContainerStore/globalContainerStore.store";
import { TextOverflowWithTooltip } from "components/TextOverflowWithTooltip";
import {authStore} from "stores/AuthStore";

interface HeaderWorkbookProps {}

export const HeaderWorkbook: React.FC<HeaderWorkbookProps> = observer(({}) => {
  const { workbookId } = useParams();

  useEffectAsync(async () => {
    await globalContainerStore.callGetWorkbookApi({
      workbook_id: workbookId,
    });
  }, [authStore.getClientIdToken]);

  return (
    <div className={style.headerWorkbookContainer}>
      <div className={`d-flex`}>
        <FontAwesomeIcon className={style.fileIcon} icon={faFile} />
        <div className={style.name}>{globalContainerStore.getWorkbookName}</div>
      </div>
      <div className={`d-flex`}>
        <div className={`d-flex me-3`}>
          Client:{" "}
          <TextOverflowWithTooltip className={style.name} width={200}>
            {globalContainerStore.getWorkbookClients}
          </TextOverflowWithTooltip>
        </div>
        <div className={`d-flex`}>
          Level:{" "}
          <TextOverflowWithTooltip className={style.name} width={200}>
            {globalContainerStore.getWorkbookLevels}
          </TextOverflowWithTooltip>
        </div>
      </div>
    </div>
  );
});
