//Store for MotivatingFactors

import { makeAutoObservable } from "mobx";
import { ChangeEvent } from "react";
import { downloadExcelFile, isExcelFile } from "utlis/files";
import { Message } from "components/Message";
import { debounce } from "lodash";
import {
  getMotivatingFactors,
  MotivatingFactorsApisPayload,
  uploadMotivatingFactors,
} from "container/MotivatingFactors/store/motivatingFactors.api";
import { MotivatingFactorsTableData } from "container/MotivatingFactors/store/motivatingFactors.types";

class MotivatingFactorsStore {
  constructor() {
    makeAutoObservable(this);
  }

  motivatingFactorRef;
  isMotivatingFactorsUploadLoading = false;
  search;
  page;

  setIsMotivatingFactorsUploadLoading(bool: boolean) {
    this.isMotivatingFactorsUploadLoading = bool;
  }

  setSearch = (search: string) => {
    this.search = search;
    this.page = 1;

    this.callGetMotivatingFactorsWithDebounce({
      search: search,
      page: 1,
    });
  };

  setPage = (page) => {
    this.page = page;

    this.callGetMotivatingFactorsApi({
      search: this.search,
      page: page,
    });
  };

  handleUploadMotivatingFactors = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      this.setIsMotivatingFactorsUploadLoading(true);
      const excelFile = e.target.files ? e.target.files[0] : undefined;

      if (!isExcelFile(e.target.value)) {
        Message.error("Unsupported File");
      }

      const payload = {
        motivating_factors: excelFile,
      };
      await uploadMotivatingFactors.callApiWithFormData(payload);
      Message.success("Successfully uploaded Motivating Factor");
      await getMotivatingFactors.callApi();
    } catch (error: any) {
      if (error.response.data.errors) {
        Message.error(error.response.data.errors?.motivating_factors[0]);
      } else {
        Message.error("Failed to upload Motivating Factor");
      }
    } finally {
      this.setIsMotivatingFactorsUploadLoading(false);
    }
  };
  downloadMotivatingFactorsData = async () => {
    try {
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/descriptors/motivatingFactors/downloadMotivatingFactors`;

      await downloadExcelFile(endpoint, "Motivating Factors", "", "POST");
      Message.success("Downloaded Motivating Factors File");
    } catch (error) {
      Message.error("Could not download");
    }
  };

  callGetMotivatingFactorsApi = async (
    payload?: MotivatingFactorsApisPayload
  ) => {
    await getMotivatingFactors.callApi(payload);
  };

  callGetMotivatingFactorsWithDebounce = debounce(
    (payload?: MotivatingFactorsApisPayload) => {
      getMotivatingFactors.callApi(payload);
    },
    1500
  );

  get getTableData() {
    return (getMotivatingFactors.data as MotivatingFactorsTableData[]) || [];
  }

  get getTotalData() {
    return getMotivatingFactors.meta?.total || "";
  }

  get getMetaData() {
    return getMotivatingFactors.meta;
  }
}

export const motivatingFactorsStore = new MotivatingFactorsStore();
