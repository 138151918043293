//Store for CompetencyLibrary

import { ChangeEvent } from "react";
import { downloadExcelFile, isExcelFile } from "utlis/files";
import {
  CompetencyLibraryApisPayload,
  getCompetencyLibrary,
  uploadCompetencyLibrary,
} from "./competencyLibrary.api";
import { makeAutoObservable } from "mobx";
import { Message } from "components/Message";
import { competencyLibraryTableData } from "./competencyLibrary.types";
import { debounce } from "lodash";

class CompetencyLibraryStore {
  constructor() {
    makeAutoObservable(this);
  }
  competencyLibraryRef;
  isCompetencyLibraryUploadLoading = false;
  search;
  page;

  setIsCompetencyLibraryUploadLoading(bool: boolean) {
    this.isCompetencyLibraryUploadLoading = bool;
  }
  setSearch = (search: string) => {
    this.search = search;
    this.page = 1;

    this.callGetCompetencyLibraryApiWithDebounce({
      search: search,
      page: 1,
    });
  };

  setPage = (page) => {
    this.page = page;

    this.callGetCompetencyApi({
      search: this.search,
      page: page,
    });
  };

  handleUploadCompetencyLibrary = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      this.setIsCompetencyLibraryUploadLoading(true);
      const excelFile = e.target.files ? e.target.files[0] : undefined;

      if (!isExcelFile(e.target.value)) {
        Message.warning("Unsupported File");
      }
      const payload = {
        competency_library: excelFile,
      };
      await uploadCompetencyLibrary.callApiWithFormData(payload);
      Message.success("Successfully uploaded");
      await getCompetencyLibrary.callApi();
    } catch (error) {
      console.log("error: ", error);
      Message.error("Unsuccessfully uploaded");
    } finally {
      this.setIsCompetencyLibraryUploadLoading(false);
    }
  };
  downloadCompetencyLibrary = async () => {
    try {
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/descriptors/competencyLibrary/downloadCompetencyLibrary`;

      await downloadExcelFile(endpoint, "CompetencyLibraryFile", "", "POST");
      Message.success("Competency library has been downloaded successfully");
    } catch (error) {
      Message.error("Could not download");
    }
  };
  callGetCompetencyApi = async (payload?: CompetencyLibraryApisPayload) => {
    await getCompetencyLibrary.callApi(payload);
  };

  callGetCompetencyLibraryApiWithDebounce = debounce(
    (payload?: CompetencyLibraryApisPayload) => {
      getCompetencyLibrary.callApi(payload);
    },
    1500
  );

  get getTableData() {
    return (getCompetencyLibrary.data as competencyLibraryTableData[]) || [];
  }

  get getTotalData() {
    return getCompetencyLibrary.meta?.total || "";
  }
  get getMetaData() {
    return getCompetencyLibrary.meta;
  }
}

export const competencyLibraryStore = new CompetencyLibraryStore();
