//Store for CategoryManagement


import {myProfile} from "container/Account/store/account.api";
import {makeAutoObservable} from "mobx";

class AccountsStore {
    constructor() {
        makeAutoObservable(this);
    }

    get getMyProfileData() {
        myProfile.callApiIfEmpty();
        return myProfile.data
    }
}

export const accountsStore = new AccountsStore();


