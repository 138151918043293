//Store for CategoryManagement

import { CategoriesTableData, Category } from "./categoryManagement.types";
import { makeAutoObservable } from "mobx";
import {
  CategoryApisPayload,
  cloneCategoriesApi,
  createCategoriesApi,
  deleteCategoriesApi,
  editCategoriesApi,
  getCategoriesApi,
  getCategoryWorkbooks,
} from "./categoryManagement.api";
import { Message } from "components/Message";
import { categoryManagementFormStore } from "container/CategoryManagement/store/categoryManagement.formstore";
import { debounce, filter, find, map } from "lodash";
import { handleFailureResponse } from "utlis/handleFailureResponse";

class CategoryManagementStore {
  constructor() {
    makeAutoObservable(this);
  }
  loading = false;
  catName;
  catId;
  catWorkbook;
  search;
  type;
  newCode;
  page = 1;
  defaultCategoryList;
  editOpen = false;
  cloneOpen = false;
  enableClone = false;
  deleteOpen = false;
  isEdit = false;
  saveAsNew = false;
  workbookDataShow = false;
  fromWorkbookConfiguration = false;

  categoryEditData;
  categoryCloneData;

  categoryOthers = [
    { value: "cultural_fit", label: "Cultural Fit" },
    { value: "others", label: "Others" },
  ];
  categorySource = [
    { value: "acl", label: "ACL" },
    { value: "cultural_fit", label: "Cultural Fit" },
    { value: "others", label: "Others" },
  ];
  typesOptions = [
    { value: "default", label: "default" },
    { value: "custom", label: "custom" },
  ];

  saveButton = async () => {
    try {
      if (
        this.isEdit &&
        getCategoryWorkbooks.data?.workbooks.length > 0 &&
        !this.workbookDataShow
      ) {
        this.setWorkbookData(true);
      } else {
        this.setEditOpen(false);
        this.setLoading(true);
        await categoryManagementFormStore.submit();
        categoryManagementFormStore.reInit();
      }
    } catch (e) {
      categoryManagementFormStore.reInit();
      this.setLoading(false);
      this.saveAsNewPayload(false);
      this.setWorkbookData(false);
      if (createCategoriesApi.failureResponse) {
        handleFailureResponse(
          createCategoriesApi.failureResponse,
          "The category is not created"
        );
      }
      if (editCategoriesApi.failureResponse) {
        handleFailureResponse(
          editCategoriesApi.failureResponse,
          "The category is not edited"
        );
      }
    }
    this.setEnableClone(false);
  };

  saveAsNewButton = async () => {
    try {
      this.setLoading(true);
      this.saveAsNewPayload(true);
      await categoryManagementFormStore.submit();
      categoryManagementFormStore.reInit();
      this.saveAsNewPayload(false);
    } catch (e) {
      categoryManagementFormStore.reInit();
      this.setIsEdit(true);
      this.setLoading(false);
      this.saveAsNewPayload(false);
      if (createCategoriesApi.failureResponse) {
        handleFailureResponse(
          createCategoriesApi.failureResponse,
          "The category is not created"
        );
      }
      if (editCategoriesApi.failureResponse) {
        handleFailureResponse(
          editCategoriesApi.failureResponse,
          "The category is not edited"
        );
      }
    }
    this.setEnableClone(false);
  };

  cancelButton = async () => {
    categoryManagementFormStore.reInit();
    this.setEnableClone(false);
  };

  confirmButton = async () => {
    try {
      this.setLoading(true);
      await deleteCategoriesApi.callApi({
        categories_id: this.catId,
      });
      categoryManagementStore.setSearch("");
      Message.success(
        `${categoryManagementStore.catName} category deleted successfully`
      );
    } catch (e) {
      this.setLoading(false);
      Message.error(
        `${categoryManagementStore.catName} category did not delete`
      );
    }
  };

  cloneButton = async () => {
    try {
      this.setLoading(true);
      this.categoryCloneData?.category_source === "cultural_fit"
        ? await cloneCategoriesApi.callApi({
            categories_id: this.categoryCloneData?.id,
            name: this.categoryCloneData?.name,
            description: this.categoryCloneData?.description,
            category_source: this.categoryCloneData?.category_source,
            category_code: this.newCode,
            high_text: this.categoryCloneData.high_text,
            low_text: this.categoryCloneData.low_text,
          })
        : this.categoryCloneData?.category_source === "acl"
        ? await cloneCategoriesApi.callApi({
            categories_id: this.categoryCloneData?.id,
            name: this.categoryCloneData?.name,
            description: this.categoryCloneData?.description,
            category_source: this.categoryCloneData?.category_source,
            category_code: this.newCode,
            acl_category_id: this.categoryCloneData.id,
            to_clone: true,
          })
        : await cloneCategoriesApi.callApi({
            categories_id: this.categoryCloneData?.id,
            name: this.categoryCloneData?.name,
            description: this.categoryCloneData?.description,
            category_source: this.categoryCloneData?.category_source,
            category_code: this.newCode,
          });
      Message.success(
        `${categoryManagementStore.categoryEditData?.name} category cloned successfully`
      );
    } catch (e) {
      this.setLoading(false);
      Message.error(
        `${categoryManagementStore.categoryEditData?.name} category did not clone`
      );
      Message.error(
        `${cloneCategoriesApi.failureResponse?.errors?.category_code[0]}`
      );
    }
  };

  callGetCategoriesApi = async (payload?: CategoryApisPayload) => {
    await getCategoriesApi.callApi(payload);
    this.defaultCategoryList = filter(
      getCategoriesApi.data,
      (category: Category) => {
        return category.type === "default";
      }
    );
  };
  callGetCategoriesApiWithDebounce = debounce(
    (payload?: CategoryApisPayload) => {
      getCategoriesApi.callApiIfPayloadDifferent(payload);
    },
    1500
  );

  setLoading = (bool: boolean) => {
    this.loading = bool;
  };

  setFromWorkbookConfiguration = (bool: boolean) => {
    this.fromWorkbookConfiguration = bool;
  };

  setCategoryEditData = (data) => {
    this.categoryEditData = data;
  };

  setWorkbookData = (data) => {
    this.workbookDataShow = data;
  };
  setCategoryCloneData = (data) => {
    this.categoryCloneData = data;
  };

  setIsEdit = (bool: boolean) => {
    this.isEdit = bool;
  };

  saveAsNewPayload = (bool: boolean) => {
    this.saveAsNew = bool;
  };

  get getTableData() {
    return (getCategoriesApi.data as CategoriesTableData[]) || [];
  }
  get getDefaultCategoryList() {
    return map(this.defaultCategoryList, (category: Category) => ({
      value: category.id,
      label: category.name,
    }));
  }

  setSearch = (search: string) => {
    this.search = search;
  };

  setType = (val) => {
    this.type = val;
  };

  setDefaultCategory = (val) => {
    categoryManagementFormStore.$("acl_category_id").set("value", val.value);
  };
  setEnableClone(bool) {
    this.enableClone = bool;
  }

  setEditOpen = (val) => {
    this.editOpen = val;
  };
  setCloneOpen = (val) => {
    this.cloneOpen = val;
  };
  setDeleteOpen = (val) => {
    this.deleteOpen = val;
  };
  get getSearch() {
    return this.search;
  }
  setCat = (val) => {
    this.catName = val.name;
    this.catId = val.id;
    this.catWorkbook = val.workbooks_count;
  };
  setPage = (page) => {
    this.page = page;
  };
  setNewCode = (code) => {
    this.newCode = code;
  };

  get getTotalData() {
    return getCategoriesApi.meta?.total || "";
  }
  get getMetaData() {
    return getCategoriesApi.meta;
  }
  get getPage() {
    return this.page;
  }
}

export const categoryManagementStore = new CategoryManagementStore();
