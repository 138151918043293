/**
 * NOTE: This table component will take 100% height and width of its parent container.
 *       It will not depends on the size of its content. So please manage the size of
 *       its parent container appropriately
 */
import * as React from "react";
import {
  Column,
  TableInstance,
  useSortBy,
  useTable,
  useRowSelect,
  useFlexLayout,
  useExpanded,
} from "react-table";
import style from "./index.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { CheckBox } from "../CheckBox";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { SkeletonBox } from "../SkeletonBox";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";

const HEADER_HEIGHT = 40;

export interface ITableDataProps {
  hideSubContent?: boolean; // option to optionally disable sub content for specific data
  [key: string]: any;
}

interface TableProps {
  data?: ITableDataProps[];
  columns?: Column[];
  isLoading?: boolean;
  useCheckBox?: boolean;
  expandableRow?: boolean;
  showFileIcon?: boolean;
  rowHeight?: number; // height in pixel
  rowGapHeight?: number;
  subContentHeight?: number;
  returnCheckBoxData?: (checkedData) => void;
  subContentRenderer?: (rowData) => React.ReactElement;
}

export const TableWithVirtualization: React.FC<TableProps> = ({
  data,
  columns,
  isLoading,
  useCheckBox,
  expandableRow = false,
  showFileIcon = false,
  rowHeight = 34,
  rowGapHeight = 5,
  subContentHeight = 100,
  returnCheckBoxData,
  subContentRenderer,
}) => {
  const tableInstance: TableInstance = useTable(
    { data, columns },
    useSortBy,
    useFlexLayout,
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Column for expander
        ...(expandableRow
          ? [
              {
                id: "expander",
                Header: () => null,
                Cell: ({ row }) =>
                  row.original.hideSubContent ? (
                    ""
                  ) : (
                    <span
                      className={style.expander}
                      {...(row.original.hideSubContent
                        ? {}
                        : row.getToggleRowExpandedProps())}
                    >
                      {row.isExpanded ? (
                        <FontAwesomeIcon icon={faChevronDown} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronRight} />
                      )}
                    </span>
                  ),
                disableSortBy: true,
                width: 10,
                maxWidth: 10,
                disableResizing: true,
              },
            ]
          : []),
        // Let's make a column for selection
        ...(useCheckBox
          ? [
              {
                id: "selection",
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div className={style.checkboxSpacingHeader}>
                    <CheckBox {...getToggleAllRowsSelectedProps()} />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                  <div className={style.checkboxSpacingCell}>
                    <CheckBox {...row.getToggleRowSelectedProps()} />
                  </div>
                ),
                disableSortBy: true,
                width: 45,
                maxWidth: 45,
                disableResizing: true,
              },
            ]
          : []),
        ...columns,
      ]);
    }
  );
  const {
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds, expanded },
    totalColumnsWidth,
  } = tableInstance;

  const listRef = React.useRef<VariableSizeList>();

  React.useEffect(() => {
    if (useCheckBox) {
      returnCheckBoxData(selectedFlatRows.map((d) => d.original));
    }
  }, [selectedRowIds]);

  const renderSorting = (isSorted, isSortedDesc, header) => {
    return (
      <span className={style.sortingSpacing}>
        <div className={style.heightSpacing}>
          <FontAwesomeIcon
            className={`${style.chevron} ${
              isSorted && !isSortedDesc && style.chevronActive
            }`}
            icon={faChevronUp}
          />
        </div>
        <div className={style.heightSpacing}>
          <FontAwesomeIcon
            className={`${style.chevron} ${
              isSorted && isSortedDesc && style.chevronActive
            }`}
            icon={faChevronDown}
          />
        </div>
      </span>
    );
  };

  const outerElType = React.forwardRef((props: any, ref: any) => {
    const { children, style: _style, ...otherProps } = props;
    return (
      <div
        ref={ref}
        style={{ ..._style, overflowX: "hidden", overflowY: "auto" }}
        {...otherProps}
      >
        {children}
      </div>
    );
  });

  React.useEffect(() => {
    if (listRef.current) {
      const keys = Object.keys(expanded);
      const target = keys.length ? Number(keys[keys.length - 1]) : 0;
      listRef.current.resetAfterIndex(target);
    }
  }, [expanded]);

  const renderSubContent = (row) => {
    if (subContentRenderer) {
      return subContentRenderer(row);
    }

    return <div className={style.noContent}>No Content Available</div>;
  };

  /**
   * We will only render the row when prepareRow and rows is available
   */
  const renderRow =
    (rowWidth) =>
    ({ index, style: rowStyle }) => {
      const row = rows[index];
      prepareRow(row);

      const { style: tableRowStyle, ...otherRowProps } = row.getRowProps();

      return (
        <div style={{ ...rowStyle, width: rowWidth }}>
          <div
            className={[row.isExpanded ? style.expanded : null, style.tr].join(
              " "
            )}
            {...otherRowProps}
            style={{ ...tableRowStyle, height: rowHeight, width: "100%" }}
          >
            {row.cells.map((cell) => (
              <div className={style.td} {...cell.getCellProps()}>
                {cell.render("Cell")}
              </div>
            ))}
          </div>
          {row.isExpanded ? (
            <div
              className={style.subContentContainer}
              style={{ height: subContentHeight, width: rowWidth }}
            >
              {renderSubContent(row)}
            </div>
          ) : undefined}
        </div>
      );
    };
  // [prepareRow, rows, selectedRowIds, expanded]
  // );

  const getRowHeight = (idx) => {
    /**
     * Don't forget that Object.keys() output array of string! we need to cast the number to string
     * before comparing (No I definitely didn't spent ridiculous amount of time figuring out why rowHeight
     * was not updating. no nope :V )
     */
    if (Object.keys(expanded).includes(`${idx}`)) {
      //  return expanded size
      return rowHeight + rowGapHeight + subContentHeight;
    }

    return rowHeight + rowGapHeight;
  };

  return (
    <div className={style.tableContainer}>
      <AutoSizer>
        {({ height: containerHeight, width: containerWidth }) => {
          const height = containerHeight - HEADER_HEIGHT;
          /**
           * We want the row to follow the parent container width if it is bigger than the total column width.
           * if not, then follow total column width so that horizontal scrollbar appear
           */
          const tbodyWidth =
            containerWidth > totalColumnsWidth
              ? containerWidth
              : totalColumnsWidth;

          return (
            <div
              className={style.table}
              style={{ height: containerHeight, width: containerWidth }}
            >
              <div
                className={style.thead}
                style={{ height: HEADER_HEIGHT, width: containerWidth }}
              >
                {headerGroups.map((headerGroup) => (
                  <div
                    {...headerGroup.getHeaderGroupProps()}
                    className={style.tr}
                  >
                    {headerGroup.headers.map((column: any) => (
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={style.th}
                      >
                        {column.render("Header")}
                        <span className={style.masteryNull} />
                        {!column.disableSortBy &&
                          renderSorting(
                            column.isSorted,
                            column.isSortedDesc,
                            column.Header
                          )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className={style.tbody} style={{ width: tbodyWidth }}>
                <div style={{ height: "100%" }}>
                  {isLoading ? (
                    <div style={{ height, width: "100%" }}>
                      {[1, 2, 3].map((row) => (
                        <div
                          style={{
                            height: `${rowHeight}px`,
                            marginBottom: `${rowGapHeight}px`,
                          }}
                          key={row}
                        >
                          <SkeletonBox
                            width={"100%"}
                            height={"100%"}
                            borderRadius={"8px"}
                          />
                        </div>
                      ))}
                    </div>
                  ) : data.length === 0 ? (
                    <div className={style.noData} style={{ width: tbodyWidth }}>
                      No Data For This Table
                    </div>
                  ) : (
                    <VariableSizeList
                      overscanCount={25}
                      itemSize={getRowHeight}
                      height={height}
                      width={tbodyWidth}
                      itemCount={data.length}
                      outerElementType={outerElType}
                      ref={listRef}
                    >
                      {renderRow(tbodyWidth)}
                    </VariableSizeList>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </AutoSizer>
    </div>
  );
};
