import * as React from "react";
import { observer } from "mobx-react";
import { useEffectAsync } from "utlis/hooks";
import { Modal } from "components/Modal";
import style from "container/Landing/index.module.scss";
import { Select } from "components/Select";
import { categoryManagementStore } from "container/CategoryManagement/store/categoryManagement.store";
import { Input } from "components/Input";
import { CheckBox } from "components/CheckBox";
import { categoryManagementFormStore } from "container/CategoryManagement/store/categoryManagement.formstore";
import { find, map } from "lodash";
import { getCategoryWorkbooks } from "container/CategoryManagement/store/categoryManagement.api";
import { Table } from "components/Table";

interface CategoryManagementModalsProps {
  isOpen?: boolean;
  callGetCategoriesApi?: boolean;
  toggle?: () => void;
  fromWorkbookConfiguration?: boolean;
}

export const CreateEditCategoryModal: React.FC<CategoryManagementModalsProps> =
  observer(
    ({
      isOpen,
      toggle,
      callGetCategoriesApi = true,
      fromWorkbookConfiguration = false,
    }) => {
      const categoryName = categoryManagementFormStore.$("name");
      const categoryCode = categoryManagementFormStore.$("category_code");
      const categoryDescription = categoryManagementFormStore.$("description");
      const highText = categoryManagementFormStore.$("high_text");
      const legendText = categoryManagementFormStore.$("legend");
      const lowText = categoryManagementFormStore.$("low_text");
      const categorySourceList =
        categoryManagementFormStore.$("category_source");
      const categoryId = categoryManagementFormStore.$("category_id");
      const defaultCategoryID =
        categoryManagementFormStore.$("acl_category_id");

      useEffectAsync(async () => {
        if (isOpen && callGetCategoriesApi) {
          categoryManagementStore.setFromWorkbookConfiguration(
            fromWorkbookConfiguration
          );
          await categoryManagementStore.callGetCategoriesApi();
        }
        if (categorySourceList.value?.value === "acl") {
          categoryManagementStore.setEnableClone(true);
        }
      }, [isOpen]);

        useEffectAsync(async () => {
            if (categorySourceList.value?.value === "acl") {
                categoryManagementStore.setEnableClone(true);
            } else {
                categoryManagementStore.setEnableClone(false);
            }
        }, [categorySourceList.value]);

      useEffectAsync(async () => {
        if (isOpen && categoryManagementStore.categoryEditData) {
          await getCategoryWorkbooks.callApi({
            categories_id: categoryManagementStore.categoryEditData.id,
          });
          categoryId.set("value", categoryManagementStore.categoryEditData.id);
          categoryCode.set(
            categoryManagementStore.categoryEditData.category_code
          );
          categoryName.set(categoryManagementStore.categoryEditData.name);
          categoryDescription.set(
            categoryManagementStore.categoryEditData.description
          );
          highText.set(categoryManagementStore.categoryEditData.high_text);
          defaultCategoryID.set(
            "value",
            categoryManagementStore.categoryEditData.acl_category_id
          );
          lowText.set(categoryManagementStore.categoryEditData.low_text);
          legendText.set(categoryManagementStore.categoryEditData.legend || "");
          categorySourceList.set({
            value: categoryManagementStore.categoryEditData.category_source,
            label: categoryManagementStore.categorySource.find(
              (item) =>
                item.value ===
                categoryManagementStore.categoryEditData.category_source
            ).label,
          });
        }
      }, [categoryManagementStore.categoryEditData, isOpen]);

      const customEdit = () => (
        <div className={style.editModal}>
          <Select
            options={
              categoryManagementStore.isEdit
                ? categoryManagementStore.categoryOthers
                : (categoryManagementStore.categorySource as any)
            }
            isDisabled={categoryManagementStore.categoryEditData}
            className={style.categorySource}
            {...categorySourceList.bind()}
          />
          {categorySourceList.value?.value === "acl" && (
            <Select
              options={categoryManagementStore.getDefaultCategoryList}
              label={"Default categories list"}
              placeholder={"Select default category"}
              className={style.categorySource}
              onChange={(e: any) => {
                categoryManagementStore.setDefaultCategory(e);
              }}
              isDisabled={categoryManagementStore.categoryEditData}
              value={find(categoryManagementStore.getDefaultCategoryList, {
                value: defaultCategoryID.value || categoryId.value,
              })}
            />
          )}
          <Input {...categoryName.bind()} />
          <Input {...categoryCode.bind()} />
          <Input {...categoryDescription.bind()} />
          {categorySourceList.value?.value === "others" && (
            <>
              <Input {...legendText.bind()} />
            </>
          )}
          {categorySourceList.value?.value === "cultural_fit" && (
            <>
              <Input {...highText.bind()} />
              <Input {...lowText.bind()} />
            </>
          )}
          {categorySourceList.value?.value === "acl" && categoryManagementStore.categoryEditData?.type === "default" && (
            <>
              <CheckBox
                type={"checkbox"}
                label={"Clone All Existing Category Data?"}
                onChange={() => {
                  categoryManagementStore.setEnableClone(
                    !categoryManagementStore.enableClone
                  );
                }}
                checked={categoryManagementStore.enableClone}
              />
            </>
          )}
        </div>
      );

      const workBookDisplay = () => (
        <div className={style.editModal}>
          <div>
            This changes will affect other workbook(s) as well, do you wish to
            proceed and save?
          </div>
          <hr />
          <div className={style.boldText}>List of workbook affected</div>
          <Table
            columns={[
              {
                Header: "Workbook Name",
                accessor: "name",
                width: 500,
                minWidth: 400,
              },
            ]}
            data={getCategoryWorkbooks.data?.workbooks}
          />
        </div>
      );

      return (
        <Modal
          toggle={toggle}
          isOpen={isOpen}
          showActionBtn={true}
          actionBtnProps={{
            loading: categoryManagementStore.loading,
            disabled:
              categorySourceList.value?.value === "cultural_fit"
                ? !categoryName.value ||
                  !categoryCode.value ||
                  !categorySourceList.value
                : categorySourceList.value?.value === "acl"
                ? !categoryName.value ||
                  !categoryCode.value ||
                  !categorySourceList.value
                : !categoryName.value ||
                  !categoryCode.value ||
                  !categorySourceList.value,
          }}
          headerTitle={`${categoryManagementStore.isEdit ? `Edit` : `Create`} ${
            categoryManagementStore.categoryEditData?.type === "default"
              ? "Default"
              : "Custom"
          } Category`}
          actionBtnLabel={`${
            categoryManagementStore.isEdit
              ? categoryManagementStore.categoryEditData?.type === "default"
                ? `Save as New`
                : categoryManagementStore.workbookDataShow
                ? `Change Anyway`
                : `Save`
              : `Create`
          }`}
          extraActionBtnOnClick={async () => {
            categoryManagementStore.saveAsNewPayload(true);
            await categoryManagementStore.saveButton();
          }}
          cancelBtnLabel={
            categoryManagementStore.workbookDataShow ? "Back" : "Cancel"
          }
          extraActionBtnLabel={"Save as New"}
          showExtraActionBtn={categoryManagementStore.workbookDataShow}
          modalSize={style.modalSize}
          actionDisableToggle={
            getCategoryWorkbooks.data?.workbooks.length > 0 &&
            !categoryManagementStore.workbookDataShow
          }
          cancelDisableToggle={categoryManagementStore.workbookDataShow}
          actionBtnOnClick={
            categoryManagementStore.categoryEditData?.type === "default"
              ? categoryManagementStore.saveAsNewButton
              : categoryManagementStore.saveButton
          }
          cancelBtnOnClick={() =>
            categoryManagementStore.workbookDataShow
              ? categoryManagementStore.setWorkbookData(false)
              : categoryManagementStore.cancelButton()
          }
        >
          {categoryManagementStore.workbookDataShow
            ? workBookDisplay()
            : customEdit()}
        </Modal>
      );
    }
  );
