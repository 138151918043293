// Apis For ReviewMapping
import { ApiStore } from 'stores/ApiStore';
import { RestMethods } from 'stores/baseApi';

export interface ReviewMappingApisPayload {
  
}

const ENDPOINT_TEMPLATE = '/api/journey'
const ENDPOINT_TEMPLATE_FINALIZE = '/api/finalize'

class ReviewMappingApis extends ApiStore<ReviewMappingApisPayload, null, null, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static getJourneys() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getJourneys`;
    return new ReviewMappingApis(endpoint, { requestType: RestMethods.GET });
  }

  static finalizeWorkbook() {
    const endpoint = `${ENDPOINT_TEMPLATE_FINALIZE}/finalizeWorkbook`;
    return new ReviewMappingApis(endpoint, { requestType: RestMethods.POST });
  }
}

export const getJourneys = ReviewMappingApis.getJourneys();
export const finalizeWorkbook = ReviewMappingApis.finalizeWorkbook();

