import * as React from 'react';
import style from './index.module.scss';
import {Asterisk} from "../Asterisk";

interface LabelProps {
  showAsterisk?: boolean;
  className?: string;
}

export const Label: React.FC<LabelProps> = props => {
  const { showAsterisk, children, className } = props;
  return (
    <div className={`${style.inputLabel} ${showAsterisk && style.inputLabelPadding} ${className}`}>
      {`${children}`}
      {showAsterisk && <Asterisk className={style.asteriskLabel} />}
    </div>
  );
};
