/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-20
 */

// screen file for WorkbookCreation
import React, { useState } from "react";
import style from "./index.module.scss";
import { WorkbookCreationProps } from "container/Workbook/WorkbookCreation/store/workbookCreation.types";
import { Select } from "components/Select";
import { Input } from "components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { HeaderFooterContainer } from "components/HeaderFooterContainer";
import { MAPPING_DOMAIN, WORKBOOK_DOMAIN } from "constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { workbookCreationFormStore } from "container/Workbook/WorkbookCreation/store/workbookCreation.formstore";
import { observer } from "mobx-react";
import { globalContainerStore } from "container/_globalContainerStore/globalContainerStore.store";
import { useEffectAsync } from "utlis/hooks";
import { workbookCreationStore } from "container/Workbook/WorkbookCreation/store/workbookCreation.store";
import { Message } from "components/Message";
import { get, map } from "lodash";
import { createWorkBookApi } from "container/Workbook/WorkbookCreation/store/workbookCreation.api";

export const WorkbookCreation: React.FC<WorkbookCreationProps> = observer(
  ({}) => {
    let navigate = useNavigate();
    const { workbookId } = useParams();

    const name = workbookCreationFormStore.$("name");
    const clientIds = workbookCreationFormStore.$("client_ids");
    const levelIds = workbookCreationFormStore.$("level_ids");
    const workbookID = workbookCreationFormStore.$("workbook_id");

    useEffectAsync(async () => {
      workbookCreationFormStore.clear();
      workbookCreationStore.setLoading(true);
      await Promise.all([
        globalContainerStore.callGetClientApi(),
        globalContainerStore.callGetLevelApi(),
      ]);
      if (workbookId && workbookId !== "null") {
        await globalContainerStore.callGetWorkbookApi({
          workbook_id: workbookId,
        });
        const selectedId = map(
          get(globalContainerStore.getWorkbookApi, "clients"),
          (item: any) => ({ label: item.name, value: item.id })
        );
        const selectedLevel = map(
          get(globalContainerStore.getWorkbookApi, "levels"),
          (item: any) => ({ label: item.name, value: item.id })
        );
        name.set("value", get(globalContainerStore.getWorkbookApi, "name"));
        clientIds.set("value", selectedId);
        levelIds.set("value", selectedLevel);
        workbookID.set("value", workbookId);
      }
      workbookCreationStore.setLoading(false);
    }, []);

    const NextButton = async () => {
      try {
        workbookCreationStore.setLoading(true);
        await workbookCreationFormStore.submit();
        let id;
        if (workbookId === "null") {
          const response: any = createWorkBookApi.data;
          id = response.id;
        } else {
          id = workbookId;
        }
        navigate(`/${WORKBOOK_DOMAIN}/configure/${id}`);
      } catch (e) {
        Message.error(`Failed to create workbook`);
        workbookCreationStore.setLoading(false);
      }
    };

    const SkipButton = () => {
      navigate(`/${WORKBOOK_DOMAIN}/configure/${workbookId}`);
    };

    return (
      <HeaderFooterContainer
        className={style.workBookCreationContainer}
        cancelBtnOnClick={() => navigate(`/${MAPPING_DOMAIN}`)}
        actionBtnOnClick={NextButton}
        actionBtnLabel={"Next"}
        actionBtnProps={{ loading: workbookCreationStore.loading }}
        showExtraButton={workbookId !== "null"}
        extraBtnOnClick={SkipButton}
      >
        <div className={style.workBookWidth}>
          <div className={style.workBookTitle}>
            <FontAwesomeIcon className={style.fontColor} icon={faPlus} /> Create
            New Workbook
          </div>
          <Input
            isLoading={workbookCreationStore.loading}
            className={style.workBookInputSpacing}
            {...name.bind()}
          />
          <Select
            isLoading={workbookCreationStore.loading}
            isMulti={true}
            className={style.workBookInputSpacing}
            {...clientIds.bind()}
            options={globalContainerStore.getClientOptionData}
          />
          <Select
            isLoading={workbookCreationStore.loading}
            isMulti={true}
            {...levelIds.bind()}
            options={globalContainerStore.getLevelOptionData}
          />
        </div>
      </HeaderFooterContainer>
    );
  }
);
