import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export enum RestMethods {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
    PATCH = 'patch'
}

interface IApiCallsStoreParams {
    params: AxiosRequestConfig;
    /**
     * fn() before successful api call
     */
    interceptorRequestOnFulFilled?: (config: AxiosRequestConfig) => AxiosRequestConfig;
    /**
     * fn() before failed api call
     */
    interceptorRequestOnRejected?: (err: any) => any;
    /**
     * fn() after successful api call
     */
    interceptorResponseOnFulFilled?: (res: AxiosResponse) => AxiosResponse;
    /**
     * fn() after failed api call
     */
    interceptorResponseOnRejected?: (err: any) => any;
}

/**
 * API Store in Function Programming Way
 */
export const ApiStoreCreate = ({
                                   params,
                                   interceptorRequestOnFulFilled,
                                   interceptorRequestOnRejected,
                                   interceptorResponseOnFulFilled,
                                   interceptorResponseOnRejected
                               }: IApiCallsStoreParams): AxiosInstance => {
    const apiBase = axios.create({
        ...params
    });

    // **** Request Interceptors **** //
    if (interceptorRequestOnFulFilled) {
        if (interceptorRequestOnRejected) {
            apiBase.interceptors.request.use(
                interceptorRequestOnFulFilled,
                interceptorRequestOnRejected
            );
        } else {
            apiBase.interceptors.request.use(interceptorRequestOnFulFilled);
        }
    }

    // **** Response Interceptors **** //
    if (interceptorResponseOnFulFilled) {
        if (interceptorResponseOnRejected) {
            apiBase.interceptors.response.use(
                interceptorResponseOnFulFilled,
                interceptorResponseOnRejected
            );
        } else {
            apiBase.interceptors.response.use(interceptorResponseOnFulFilled);
        }
    }

    return apiBase;
};
