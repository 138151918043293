import * as React from "react";
import style from "./index.module.scss";
import { InputProps } from "../Input";
import { Label } from "../Label";

export interface CheckBoxProps extends InputProps {
  indeterminate?: boolean;
  label?: string;
  labelClassName?: string;
  type?: "checkbox" | "radio";
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  indeterminate,
  label,
  labelClassName = "",
  className = "",
  type = "checkbox",
  ...inputProps
}) => {

  return (
    <label className={`${style.checkBoxContainer} ${className}`}>
      <input type={type} {...inputProps} />
      <span
        className={`${style.checkMark} ${
          indeterminate ? `${style.indeterminate}` : ""
        }`}
      />
      {label && <Label className={`ps-2`}>{label}</Label>}
    </label>
  );
};
