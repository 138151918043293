import React from "react";
import style from "container/CompetencyLibrary/index.module.scss";
import { TextOverflowWithTooltip } from "components/TextOverflowWithTooltip";

export const columnsCompetencyLibrary = [
  {
    Header: "S.No",
    accessor: "id",
    Cell: ({ row }) => <div className={style.w50}>{row.original.id}</div>,
  },
  {
    Header: "Category",
    accessor: "category",
    Cell: ({ row }) => (
      <div className={style.w100}>
        <TextOverflowWithTooltip>
          {row.original.category || "-"}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Report Type",
    accessor: "report_type",
    disableSortBy: true,
    Cell: ({ row }) => (
      <div className={style.w100}>
        <TextOverflowWithTooltip>
          {row.original.report_type || "-"}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Level",
    accessor: "level",
    Cell: ({ row }) => (
      <div className={style.w100}>
        <TextOverflowWithTooltip>
          {row.original.level || "-"}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Type",
    accessor: "text_type",
    disableSortBy: true,
    Cell: ({ row }) => (
      <div className={style.w100}>
        <TextOverflowWithTooltip>
          {row.original.text_type || "-"}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Appear",
    accessor: "appear",
    Cell: ({ row }) => (
      <div className={style.w100}>
        <TextOverflowWithTooltip>
          {row.original.appear || "-"}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Reference",
    accessor: "reference",
    Cell: ({ row }) => (
      <div className={style.w250}>
        <TextOverflowWithTooltip>
          {row.original.reference || "-"}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Text",
    accessor: "text",
    Cell: ({ row }) => (
      <div className={style.text}>
        {row.original.text || "No Text Available"}
      </div>
    ),
  },
];
