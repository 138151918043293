// screen file for WorkRelatedBehaviours
import React from "react";
import style from "container/WorkRelatedBehaviours/index.module.scss";
import { WorkRelatedBehavioursProps } from "container/WorkRelatedBehaviours/store/workRelatedBehaviours.types";
import {observer} from "mobx-react";
import {useEffectAsync} from "utlis/hooks";
import {authStore} from "stores/AuthStore";
import {PageBase} from "components/PageBase";
import {PageTitle} from "components/PageTitle";
import {Title} from "components/Title";
import {SearchInput} from "components/SearchInput";
import {Button} from "components/Button";
import {Table} from "components/Table";
import {Pagination} from "components/Pagination";
import {columnsWorkRelatedBehaviours} from "container/WorkRelatedBehaviours/tableColumns";
import { workRelatedBehavioursStore } from "./store/workRelatedBehaviours.store";
import {getWorkRelatedBehaviors} from "container/WorkRelatedBehaviours/store/workRelatedBehaviours.api";


export const WorkRelatedBehaviours: React.FC<WorkRelatedBehavioursProps> = observer(
    ({}) => {
        useEffectAsync(async () => {
            await workRelatedBehavioursStore.callGetWorkRelatedBehavioursApi();
        }, [authStore.getClientIdToken]);

        return (
            <PageBase className={style.workRelatedBehavioursContainer}>
                <PageTitle pageTitle={`Upload Work Related Behaviours`} />
                <div className={style.topDropdown}>
                    <div className={style.titleSearch}>
                        <Title count={workRelatedBehavioursStore.getTotalData}>
                            Work Related Behaviours
                        </Title>
                        <SearchInput
                            placeholder={"Search"}
                            onChange={(e) => workRelatedBehavioursStore.setSearch(e.target.value)}
                        />
                    </div>
                    <div className={style.filesContainer}>
                        <div className={style.uploadFile}>
                            <div className={style.label}>Upload New Document:</div>
                            <Button
                                className={`ms-2`}
                                onClick={() =>
                                    workRelatedBehavioursStore.motivatingFactorRef.click()
                                }
                                loading={
                                    workRelatedBehavioursStore.isWorkRelatedBehavioursUploadLoading
                                }
                                disabled={
                                    workRelatedBehavioursStore.isWorkRelatedBehavioursUploadLoading
                                }
                            >
                                Upload File
                            </Button>
                            <input
                                className="d-none"
                                type="file"
                                accept=".xlsx, .xls"
                                id={"input"}
                                ref={(ref) =>
                                    (workRelatedBehavioursStore.motivatingFactorRef = ref)
                                }
                                value=""
                                onChange={(e) =>
                                    workRelatedBehavioursStore.handleUploadWorkRelatedBehaviours(e)
                                }
                            />
                        </div>
                        <div className={style.downloadSection}>
                            <div className={style.label}>
                                Download Previous Uploaded Document:
                            </div>
                            <Button
                                className={`ms-2`}
                                onClick={() =>
                                    workRelatedBehavioursStore.downloadWorkRelatedBehavioursData()
                                }
                            >
                                Download
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={style.tableHeight}>
                    <Table
                        columns={columnsWorkRelatedBehaviours}
                        data={workRelatedBehavioursStore.getTableData}
                        isLoading={getWorkRelatedBehaviors.isLoading}
                    />
                </div>
                <Pagination
                    currentPage={workRelatedBehavioursStore.getMetaData?.current_page}
                    lastPage={workRelatedBehavioursStore.getMetaData?.last_page}
                    onPageClick={(e) => workRelatedBehavioursStore.setPage(e)}
                    onNextClick={() =>
                        workRelatedBehavioursStore.setPage(
                            workRelatedBehavioursStore.getMetaData?.current_page + 1
                        )
                    }
                    onPreviousClick={() =>
                        workRelatedBehavioursStore.setPage(
                            workRelatedBehavioursStore.getMetaData?.current_page - 1
                        )
                    }
                    onLastClick={() =>
                        workRelatedBehavioursStore.setPage(
                            workRelatedBehavioursStore.getMetaData?.last_page
                        )
                    }
                    onFirstClick={() => workRelatedBehavioursStore.setPage(1)}
                />
            </PageBase>
        );
    }
);


