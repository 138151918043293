import * as React from "react";
import { DropdownActionButton } from "../Dropdown/DropdownActionButton";
import { DropdownItemData } from "../Dropdown/DropdownBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import * as _ from "lodash";
import { CheckBox } from "components/CheckBox";

export const dropDownItems: DropdownItemData[] = [
  {
    name: "Application Menu",
    mainHeader: true,
  },
  {
    name: "Upload Lookup XL",
    onClick: () => {
      alert("Hello! I am an alert box!!");
    },
  },
  {
    name: "Upload Competency Library",
    onClick: () => {
      alert("Hello! I am an alert box!!");
    },
  },
  {
    name: "Category Management",
    onClick: () => {
      alert("Hello! I am an alert box!!");
    },
  },
  {
    name: "Mapper Template",
    onClick: () => {
      alert("Hello! I am an alert box!!");
    },
  },
  {
    name: "Client Management",
    onClick: () => {
      alert("Hello! I am an alert box!!");
    },
  },
  {
    name: "Result Post Log",
    onClick: () => {
      alert("Hello! I am an alert box!!");
    },
  },
];
export const mockColumns = [
  {
    Header: "Employee Profile",
    id: "name",
  },
  {
    Header: "Roles",
    accessor: "role",
  },
  {
    Header: "Leadership Fit",
    accessor: "leadership_score",
  },
  {
    Header: "Skills Fit",
    accessor: "skills_score",
  },
  {
    Header: "Past Conversation",
    accessor: "convo_date",
  },
  {
    Header: "Rating Completion",
    accessor: "completion",
  },
  {
    Header: "Action",
    id: "action",
  },
];

export const mockColumnsFields = [
  {
    Header: "Index",
    accessor: "index",
    width: 100,
    minWidth: 100,
  },
  {
    Header: "Tool",
    accessor: "tool",
    width: 250,
    minWidth: 200,
  },
  {
    Header: "Field",
    accessor: "field",
    width: 150,
    minWidth: 150,
  },
  {
    Header: "Type",
    accessor: "type",
    width: 100,
    minWidth: 100,
  },
  {
    Header: "Show in Excel",
    id: "action",
    disableSortBy: true,
    width: 90,
    minWidth: 70
  },
];

export const mockColumnsCategory = [
  {
    Header: "Category Name",
    accessor: "name",
    width: 500,
    minWidth: 400,
  },
  {
    Header: "Category Code",
    accessor: "code",
    width: 250,
    minWidth: 200,
  },
  {
    Header: "Category Type",
    accessor: "type",
    width: 250,
    minWidth: 200,
  },
  {
    Header: "Category Usage",
    accessor: "usage",
    width: 200,
    minWidth: 200,
  },
  {
    Header: "Action",
    id: "action",
    disableSortBy: true,
    width: 90,
    minWidth: 70,
    Cell: (row) => (
      <div>
        <DropdownActionButton dropdownItem={dropDownItems} />
      </div>
    ),
  },
];

export const mockColumnsLog = [
  {
    Header: "User",
    accessor: "user",
    width: 200,
    minWidth: 200,
  },
  {
    Header: "Page",
    accessor: "page",
    width: 250,
    minWidth: 200,
  },
  {
    Header: "Event",
    accessor: "event",
    width: 350,
    minWidth: 300,
  },
  {
    Header: "Updated on",
    accessor: "date",
    width: 200,
    minWidth: 200,
  },
];

export const mockColumnsTrack = [
  {
    Header: "Journey",
    accessor: "journey",
    width: 350,
    minWidth: 300,
  },
  {
    Header: "Status",
    accessor: "status",
    width: 300,
    minWidth: 250,
  },
  {
    Header: "Updated on",
    accessor: "date",
    width: 200,
    minWidth: 200,
  },
];

export const dummyData = [
  {
    category: "Leadership (default)",
    formulaName: "Overall Scores",
    formula:
      "((Accendo_Focusbroadpicture_MM+Accendo_Leadership_MM+Accendo_ST_BA/9)",
    level: 1,
  },
  {
    category: "Leadership (default)",
    formulaName: "Overall Scores",
    formula:
      "1  =\n" +
      "(shapes_management_work_related_behavior__middle_managers__imaginative_z+shapes_management_work_related_behavior__middle_managers__open_to_change_z+shapes_management_work_related_behavior__middle_managers__conceptual_z)/3*1.3",
    level: 2,
  },
  {
    category: "Leadership (default)",
    formulaName: "Overall Scores",
    formula:
      "((Accendo_Focusbroadpicture_MM+Accendo_Leadership_MM+Accendo_ST_BA/9)",
    level: 3,
  },
  {
    category: "Leadership (default)",
    formulaName: "Overall Scores",
    formula:
      "((Accendo_Focusbroadpicture_MM+Accendo_Leadership_MM+Accendo_ST_BA/9)",
    level: 1,
  },
  {
    category: "Leadership (default)",
    formulaName: "Overall Scores",
    formula:
      "1  =\n" +
      "(shapes_management_work_related_behavior__middle_managers__imaginative_z+shapes_management_work_related_behavior__middle_managers__open_to_change_z+shapes_management_work_related_behavior__middle_managers__conceptual_z)/3*1.3",
    level: 2,
  },
  {
    category: "Leadership (default)",
    formulaName: "Overall Scores",
    formula:
      "((Accendo_Focusbroadpicture_MM+Accendo_Leadership_MM+Accendo_ST_BA/9)",
    level: 3,
  },
];

export const mockData = [
  {
    img_url: "",
    name: "Bryan",
    role: "Sourcer",
    leadership_score: 58,
    skills_score: 67,
    convo_date: "11/6/2019",
    completion: "Rated (21/6/2018)",
  },
  {
    img_url: "",
    name: "Faralisa",
    role: "Junior Recruiter",
    leadership_score: 42,
    skills_score: 47,
    convo_date: "-",
    completion: "Pending",
  },
  {
    img_url: "",
    name: "Melissa",
    role: "Recruitment Manager",
    leadership_score: 75,
    skills_score: 59,
    convo_date: "21/4/2019",
    completion: "Rated (9/2/2018)",
  },
  {
    img_url: "",
    name: "Andrew",
    role: "Senior Recruiter",
    leadership_score: 48,
    skills_score: 75,
    convo_date: "1/2/2019",
    completion: "Rated (4/3/2019)",
  },
];

export const mockDataFields = [
  {
    index: "12345",
    tool: "BYB",
    field: "numberofprojects",
    type: "String",
  },
  {
    index: "12346",
    tool: "Shapes Management Work",
    field: "automous_z",
    type: "Float",
  },
  {
    index: "12347",
    tool: "Mapper Template",
    field: "abcdef",
    type: "Float",
  },
  {
    index: "12348",
    tool: "BYB",
    field: "numberofprojects",
    type: "string",
  },
];

export const mockDataCategory = [
  {
    name: "Interest & Motives",
    code: "values",
    type: "default",
    usage: 55,
  },
  {
    name: "Drive Result Fast",
    code: "DRF",
    type: "default",
    usage: 67,
  },
  {
    name: "Create Societal Value",
    code: "CSV",
    type: "custom",
    usage: 44,
  },
  {
    name: "New Customer Engagement",
    code: "NCE",
    type: "custom",
    usage: 23,
  },
  {
    name: "Drive Result Fast",
    code: "DRF",
    type: "default",
    usage: 67,
  },
  {
    name: "Create Societal Value",
    code: "CSV",
    type: "custom",
    usage: 44,
  },
  {
    name: "New Customer Engagement",
    code: "NCE",
    type: "custom",
    usage: 23,
  },
  {
    name: "Drive Result Fast",
    code: "DRF",
    type: "default",
    usage: 67,
  },
  {
    name: "Create Societal Value",
    code: "CSV",
    type: "custom",
    usage: 44,
  },
  {
    name: "New Customer Engagement",
    code: "NCE",
    type: "custom",
    usage: 23,
  },
];

export const mockDataMapper = [
  {
    name: "Accendo Template",
    create: "18 May 2019",
    data: "excel_sheet123.csv",
    update: "1 month ago",
  },
  {
    name: "Grab Template",
    create: "24 Jun 2021",
    data: "excel_sheet123.csv",
    update: "2 month ago",
  },
  {
    name: "Bank Islam Template",
    create: "3 Aug 2020",
    data: "excel_sheet123.csv",
    update: "5 month ago",
  },
  {
    name: "RHb Template",
    create: "18 May 2020",
    data: "excel_sheet123.csv",
    update: "2 month ago",
  },
  {
    name: "AXA Template",
    create: "20 Jul 2019",
    data: "excel_sheet123.csv",
    update: "3 month ago",
  },
  {
    name: "HRDF Template",
    create: "18 Jan 2019",
    data: "excel_sheet123.csv",
    update: "7 month ago",
  },
];

export const mockDataResult = [
  {
    no: 1,
    name: "Project A",
    date: "18 May 2019",
  },
  {
    no: 2,
    name: "Project B",
    date: "23 May 2020",
  },
  {
    no: 3,
    name: "Project C",
    date: "22 Oct 2021",
  },
  {
    no: 4,
    name: "Project D",
    date: "13 Jun 2019",
  },
  {
    no: 5,
    name: "Project E",
    date: "25 AUg 2019",
  },
  {
    no: 6,
    name: "Project F",
    date: "18 Apr 2019",
  },
];
export const mockDataLog = [
  {
    user: "Bryan",
    page: "Sourcer",
    date: "11/6/2019",
    event: "Rated (21/6/2018)",
  },
  {
    user: "Faralisa",
    page: "Junior Recruiter",
    date: "-",
    event: "Pending",
  },
  {
    user: "Melissa",
    page: "Recruitment Manager",
    date: "21/4/2019",
    event: "Rated (9/2/2018)",
  },
  {
    user: "Andrew",
    page: "Senior Recruiter",
    date: "1/2/2019",
    event: "Rated (4/3/2019)",
  },
  {
    user: "Melissa",
    page: "Recruitment Manager",
    date: "21/4/2019",
    event: "Rated (9/2/2018)",
  },
  {
    user: "Andrew",
    page: "Senior Recruiter",
    date: "1/2/2019",
    event: "Rated (4/3/2019)",
  },
  {
    user: "Andrew",
    page: "Senior Recruiter",
    date: "1/2/2019",
    event: "Rated (4/3/2019)",
  },
  {
    user: "Melissa",
    page: "Recruitment Manager",
    date: "21/4/2019",
    event: "Rated (9/2/2018)",
  },
  {
    user: "Andrew",
    page: "Senior Recruiter",
    date: "1/2/2019",
    event: "Rated (4/3/2019)",
  },
];

export const mockDataTrack = [
  {
    journey: "Coke Scholarship Test",
    status: "Completed",
    date: "30 May 2021 | 10:43:10",
  },
  {
    journey: "RHB Test",
    status: "Completed",
    date: "25 Jun 2020 | 09:43:10",
  },
  {
    journey: "Bank Islam Test",
    status: "In Progress",
    date: "23 Aug 2019 | 10:50:10",
  },
  {
    journey: "HRDF",
    status: "In Progress",
    date: "11 Jun 2019 | 12:35:07",
  },
];

export const mockDataLookup = [
  {
    no: 1,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 2,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 3,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 4,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 5,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 6,
    name: "Accendo",
    level: "All",
    reportType:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    mdLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel: "",
  },
  {
    no: 7,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 8,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 9,
    name: "Accendo",
    level: "All",
    reportType:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    mdLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel: "",
  },
  {
    no: 4,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 10,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 11,
    name: "Accendo",
    level: "All",
    reportType:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    mdLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel: "",
  },
  {
    no: 12,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 13,
    name: "Accendo",
    level: "All",
    reportType: "",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    midLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
  },
  {
    no: 14,
    name: "Accendo",
    level: "All",
    reportType:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    mdLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    highLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    veryHighLevel:
      "(Name) is prone to meet new situations and change with reservation, focusing on the negative aspects, preferring a stable and predictable work environment, thus (Name) may find it difficult to adapt, especially when faced with obstacles.",
    reference: "Description",
    textType: "Adap_shapes",
    score: "shapes_management_wo_open_to_change_z",
    veryLowLevel: "",
  },
];

export const mockDataMapping = [
  {
    project: "Coca-Cola Senior Manager Workbook (with F2F) v1.0",
    status: "Finalized",
    owner: "Super Admin",
    last: undefined,
  },
  {
    project: "Coca-Cola Senior Manager Workbook (with F2F) v1.0",
    status: "Finalized",
    owner: "Super Admin",
    last: undefined,
  },
  {
    project: "Coca-Cola Senior Manager Workbook (with F2F) v1.0",
    status: "Finalized",
    owner: "Super Admin",
    last: undefined,
  },
  {
    project: "Coca-Cola Senior Manager Workbook (with F2F) v1.0",
    status: "Finalized",
    owner: "Super Admin",
    last: undefined,
  },
  {
    project: "Coca-Cola Senior Manager Workbook (with F2F) v1.0",
    status: "Finalized",
    owner: "Super Admin",
    last: undefined,
  },
];
