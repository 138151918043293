import * as React from "react";
import style from "./index.module.scss";
import { Button, IButtonProps } from "components/Button";
import { ReactNode } from "react";
import { PageBase } from "components/PageBase";

interface HeaderFooterContainerProps {
  header?: ReactNode | Element;
  cancelBtnLabel?: string;
  actionBtnLabel?: string;
  extraBtnLabel?: string;
  actionBtnOnClick?: (e) => void;
  actionBtnProps?: IButtonProps;
  extraBtnOnClick?: (e) => void;
  extraBtnProps?: IButtonProps;
  showActionBtn?: boolean;
  showExtraButton?: boolean;
  cancelBtnOnClick?: (e) => void;
  className?: string;
  showFooter?: boolean;
  showAutoSaving?: boolean;
}

export const HeaderFooterContainer: React.FC<HeaderFooterContainerProps> = ({
  header,
  actionBtnOnClick,
  actionBtnProps,
  extraBtnProps,
  actionBtnLabel = "Save",
  cancelBtnLabel = "Back",
  extraBtnLabel = "Skip",
  showActionBtn = true,
  showExtraButton = false,
  cancelBtnOnClick,
  extraBtnOnClick,
  className,
  showFooter = true,
  showAutoSaving,
  children,
}) => {
  const renderDefaultFooter = (): React.ReactNode => (
    <>
      <Button
        onClick={(e) => {
          cancelBtnOnClick && cancelBtnOnClick(e);
        }}
        color={"transparent"}
        className={`${style.footerBtn}`}
        buttonLabelClass={`w-100`}
      >
        {cancelBtnLabel}
      </Button>
      {showActionBtn && (
        <Button
          color={"primary"}
          onClick={(e) => {
            actionBtnOnClick(e);
          }}
          className={` ${style.footerBtn}`}
          buttonLabelClass={`w-100`}
          loading={actionBtnProps && actionBtnProps.loading}
          {...actionBtnProps}
        >
          {actionBtnLabel}
        </Button>
      )}
      {showExtraButton && (
        <Button
          onClick={(e) => {
            extraBtnOnClick && extraBtnOnClick(e);
          }}
          color={"transparent"}
          className={`${style.footerBtn} m-0`}
          buttonLabelClass={`w-100`}
        >
          {extraBtnLabel}
        </Button>
      )}
    </>
  );

  return (
    <>
      {header && <div className={style.header}>{header}</div>}
      <PageBase className={`${style.mainFooter} ${className}`}>
        {children}
      </PageBase>
      {showFooter && (
        <div className={style.footer}>{renderDefaultFooter()}</div>
      )}
      {showAutoSaving && <div className={style.autoSaving}>auto-saving...</div>}
    </>
  );
};
