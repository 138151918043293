//Store for MapperLanding

import {deleteMapperTemplate, getMapperTemplate, MapperLandingApisPayload} from "./mapperLanding.api";
import { mapperTemplateTableData, Option } from "./mapperLanding.types";
import { makeAutoObservable } from "mobx";
import { mapperLandingFormStore } from "container/MapperTemplate/MapperLanding/store/mapperLanding.formstore";
import { ChangeEvent } from "react";
import { isExcelFile } from "utlis/files";
import { Message } from "components/Message";
import {
  getClient,
  getJourneys,
  getLevel,
} from "container/_globalContainerStore/globalContainerStore.api";
import { ClientBase } from "container/_globalContainerStore/globalContainerStore.types";
import {debounce, get} from "lodash";
import {getLookupData, LookUpXLApisPayload} from "container/LookUpXL/store/lookUpXL.api";

class MapperLandingStore {
  constructor() {
    makeAutoObservable(this);
  }
  loading: boolean = false;
  uploadOpen = false;
  createOpen = false;
  mappingName;
  dataRef;

  page;
  search = '';

  client = mapperLandingFormStore.$("client");
  journey = mapperLandingFormStore.$("journey");
  normGroup = mapperLandingFormStore.$("norm_group_ids");
  file = mapperLandingFormStore.$("file");
  mapperTemplateId = mapperLandingFormStore.$("mapper_template_id");

  isDataLoading = false;

  setMappingName(val) {
    this.mappingName = val;
  }

  setIsDataLoading(bool: boolean) {
    this.isDataLoading = bool;
  }
  setFile = (val) => {
    this.file = val;
  };

  setSearch = (search: string) => {
    this.search = search === '' ? undefined : search;
    this.page = 1;


    this.callGetMapperTemplateApiWithDebounce({
      name: search === '' ? undefined : search,
      page: 1,
    });
  };

  setPage = (page) => {
    this.page = page;

    this.callGetMapperTemplateApi({
      name: this.search,
      page: page,
    });
  };

  get clientListOptions() {
    return getClient.data?.map<Option>((client: ClientBase) => ({
      value: client.id,
      label: client.name,
    }));
  }
  get journeyList() {
    return getJourneys.data?.map<Option>((journey: ClientBase) => ({
      value: journey.id,
      label: journey.name,
    }));
  }

  get normList() {
    return getLevel.data?.map<Option>((journey: ClientBase) => ({
      value: journey.id,
      label: journey.name,
    }));
  }

  handleUploadData = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      this.setIsDataLoading(true);
      this.setFile(e.target.files ? e.target.files[0] : undefined);

      if (!isExcelFile(e.target.value)) {
        Message.warning("Unsupported File");
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      this.setIsDataLoading(false);
    }
  };

  callGetMapperTemplateApi = async (payload?: MapperLandingApisPayload) => {
    await getMapperTemplate.callApi(payload);
  };

  callGetMapperTemplateApiWithDebounce = debounce((payload: MapperLandingApisPayload) => {
    getMapperTemplate.callApi(payload);
  }, 1500);

  callDeleteMapperTemplateApi = async (mapper_id) => {
    await deleteMapperTemplate.callApi({mapper_id})
  }

  get getTableData() {
    return getMapperTemplate.data as mapperTemplateTableData[] || [];
  }

  get getMapperMeta() {
    return getMapperTemplate.meta;
  }

  setUploadOpen = (bool: boolean) => {
    this.uploadOpen = bool;
  };
  setCreateOpen = (bool: boolean) => {
    this.createOpen = bool;
  };
  setLoading = (bool: boolean) => {
    this.loading = bool;
  };

  uploadButton = async () => {
    try {
      this.setLoading(true);
      await mapperLandingFormStore.submit();
    } catch (e) {
      this.setLoading(false);
      console.error("Error in uploading mapping template: ", get(e, 'response'))
      if (get(e, 'response.status') === 400) Message.error(get(e, 'response.data.errorMessage'))
      else Message.error("The data did not upload");
    }
    mapperLandingFormStore.set(null)
  };
  cancelButton = async () => {
    mapperLandingFormStore.set(null)
    this.setFile(undefined);
  };
}
export const mapperLandingStore = new MapperLandingStore();
