import * as React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableElementProps,
} from "react-sortable-hoc";
import {
  FormulaBox,
  FormulaBoxProps,
} from "container/Workbook/WorkbookConfiguration/component/FormulaBox/FormulaBox";
import * as _ from "lodash";
import { Fragment, useState } from "react";
import { observer } from "mobx-react";
import { workbookFormulaMapping } from "container/Workbook/WorkbookConfiguration/store/workbookFormulaMapping.store";
import { Message } from "components/Message";

export interface FormulaContainerProps {}

const SortableBlock = SortableContainer(({ children }) => {
  return <div className={`w-100`}>{children}</div>;
});
const FormulaItemSortable: React.ComponentClass<
  FormulaBoxProps & SortableElementProps
> = SortableElement((props: any) => {
  return <FormulaBox {...props} />;
});

export const FormulaContainer: React.FC<FormulaContainerProps> = observer(
  () => {
    return (
      <SortableBlock
        onSortEnd={workbookFormulaMapping.onSortFormulaList}
        useDragHandle={true}
      >
        {_.map(
          workbookFormulaMapping.getFormulaCombination,
          (item, key: number) => {
            return (
              <Fragment key={item.id}>
                <FormulaItemSortable
                  id={item.id}
                  dragDrop={true}
                  indexNumber={key}
                  onClickRemove={async () => {
                    try {
                      await workbookFormulaMapping.callDeleteFormulaOrderApi(
                        item.id
                      );
                      Message.success("Successfully remove mapping formula");
                      workbookFormulaMapping.removeFromFormulaCreate(item.id);
                    } catch (e) {
                      Message.error("Failed remove mapping formula");
                    }
                  }}
                  onClickEdit={() => {
                    workbookFormulaMapping.setEditFormulaData(item);
                    workbookFormulaMapping.setIsOpen(true);
                  }}
                  index={key}
                  formulaName={_.get(item, "formula_name")}
                  formula={_.get(item, "formula")}
                />
              </Fragment>
            );
          }
        )}
      </SortableBlock>
    );
  }
);
