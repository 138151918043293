export const MAPPING_DOMAIN = 'mapping';
export const WORKBOOK_DOMAIN = 'workbook';
export const CATEGORY_MANAGEMENT_DOMAIN = 'categoryManagement';
export const COMPETENCY_LIBRARY_DOMAIN = 'competencyLibrary';
export const CLIENT_MANAGEMENT = 'clientManagement';
export const LOOKUP_DOMAIN = 'lookup';
export const MOTIVATING_FACTOR_DOMAIN = 'motivatingFactor';
export const WORK_RELATED_BEHAVIOURS_DOMAIN = 'workRelatedBehaviours';
export const MAPPER_TEMPLATE_DOMAIN = 'mapperTemplate';
export const MAPPER_TEMPLATE_DOMAIN_SETUP = `create/:mapper_template_id`;
export const RESULT_POST_LOG_DOMAIN = 'resultPostLog';
export const ACCESS_CONTROL_DOMAIN = 'accessControl';
export const UNAUTHORIZED = 'unauthorized';