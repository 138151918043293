import { TextOverflowWithTooltip } from "components/TextOverflowWithTooltip";
import React, { useState } from "react";
import { CheckBox } from "components/CheckBox";
import { viewDatasetStore } from "container/Workbook/ViewDataset/store/viewDataset.store";
import { find, isEmpty } from "lodash";

export const columnViewDataFixed = [
  {
    Header: "Candidate",
    accessor: "name",
    width: 250,
    minWidth: 250,
  },
  {
    Header: "Email",
    accessor: "email",
    width: 250,
    minWidth: 250,
  },
];
