import * as React from "react";
import style from "./index.module.scss";

interface TypeColorTextProps {
  text: string;
  color: "default" | "custom";
  className?: string;
}

export const TypeColorText: React.FC<TypeColorTextProps> = ({
  text,
  color = "default",
  className,
}) => {
  return <div className={`${style.textContainer} ${style[color]} ${className}`}>{text}</div>;
};
