/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-19
 */
// screen file for Landing

import React from "react";
import style from "./index.module.scss";
import { LandingProps } from "container/Landing/store/landing.types";
import { PageTitle } from "components/PageTitle";
import { Title } from "components/Title";
import { SearchInput } from "components/SearchInput";
import { Button } from "components/Button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "components/Select";
import { TableWithVirtualization } from "components/TableWithVirtualization";
import { useNavigate } from "react-router-dom";
import { WORKBOOK_DOMAIN } from "constants/routes";
import { mockColumnsMappingRecent } from "container/Landing/tableColumn";
import { PageBase } from "components/PageBase";
import {
  DownloadModal,
  ShareModal,
  TrackFinalizeModal,
  ViewLogModal,
} from "container/Landing/LandingPageModals";
import { landingStore } from "container/Landing/store/landing.store";
import { observer } from "mobx-react";
import { useEffectAsync } from "utlis/hooks";
import { globalContainerStore } from "container/_globalContainerStore/globalContainerStore.store";
import { authStore } from "stores/AuthStore";

export const Landing: React.FC<LandingProps> = observer(({}) => {
  let navigate = useNavigate();
  useEffectAsync(async () => {
    landingStore.setLoading(true);
      await globalContainerStore.callGetLevelApi();
      await landingStore.callWorkBookApi();
      landingStore.setLoading(false);
  }, [authStore.getClientIdToken]);

  useEffectAsync(() => {
    landingStore.callWorkBookApiWithDebounce({
      name: landingStore.getSearch || undefined,
      "level_ids[]": landingStore.selectedLevel?.value || undefined,
      "client_ids[]": landingStore.selectedClient?.value || undefined,
    });
  }, [landingStore.getSearch, landingStore.selectedLevel, landingStore.selectedClient]);

  return (
    <PageBase className={style.landingContainer}>
      <PageTitle
        pageTitle={`Mapping Engine`}
        subTitle={"Mapping engine allows you to calculate raw data from various sources into a simplified score for clients to download. In the future, we are thinking to insert the guide and help files here in this banner."}
      />
      <div className={style.topBar}>
        <Title count={landingStore.getWorkbookData.length}>WORKBOOK LIST</Title>
        <div className={`d-flex`}>
          <SearchInput
            placeholder={"Search Workbook"}
            onChange={(e) => landingStore.setSearch(e.target.value)}
          />
          <Select
            className={`ms-2 ${style.selectLevel}`}
            placeholder={"Level"}
            options={globalContainerStore.getLevelOptionData}
            value={landingStore.selectedLevel}
            onChange={(e: any) => landingStore.setSelectedLevel(e)}
            isClearable={true}
          />
          <Button
            className={`ms-2 ${style.button}`}
            onClick={() => navigate(`/${WORKBOOK_DOMAIN}/create/null`)}
          >
            <FontAwesomeIcon icon={faPlus} /> Workbook
          </Button>
        </div>
      </div>
      <div className={style.tableContent}>
        <TableWithVirtualization
          columns={mockColumnsMappingRecent(true)}
          data={landingStore.getWorkbookDataLast7Days}
          isLoading={landingStore.loading}
        />
        <TableWithVirtualization
          columns={mockColumnsMappingRecent(false)}
          data={landingStore.getWorkbookDataAfter7Days}
          isLoading={landingStore.loading}
        />
      </div>
      <DownloadModal
        isOpen={landingStore.download}
        toggle={() => landingStore.setDownload(!landingStore.download)}
      />
      <ShareModal
        isOpen={landingStore.share}
        toggle={() => landingStore.setShare(!landingStore.share)}
      />
      <ViewLogModal
        isOpen={landingStore.log}
        toggle={() => landingStore.setLog(!landingStore.log)}
      />
      <TrackFinalizeModal
        isOpen={landingStore.track}
        toggle={() => landingStore.setTrack(!landingStore.track)}
      />
    </PageBase>
  );
});
