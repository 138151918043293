// Apis For CategoryManagement
import { ApiStore } from 'stores/ApiStore';
import { RestMethods } from 'stores/baseApi';
import {ClientBase} from "container/_globalContainerStore/globalContainerStore.types";

export interface GlobalContainerStoreApiPayload {

}
export interface GlobalContainerStoreApiSuccessResponse {
  data: ClientBase[];
}

const ENDPOINT_TEMPLATE = '/api'

class GlobalContainerStoreApi extends ApiStore<GlobalContainerStoreApiPayload, ClientBase[], GlobalContainerStoreApiSuccessResponse, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static getClient() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getClients`;
    return new GlobalContainerStoreApi(endpoint, { requestType: RestMethods.GET });
  }

  static getLevel() {
    const endpoint = `${ENDPOINT_TEMPLATE}/providerIntegration/getNormGroups`;
    return new GlobalContainerStoreApi(endpoint, { requestType: RestMethods.GET });
  }
  static getJourneys() {
    const endpoint = `${ENDPOINT_TEMPLATE}/journey/getJourneys`;
    return new GlobalContainerStoreApi(endpoint, { requestType: RestMethods.GET });
  }
}

export const getClient = GlobalContainerStoreApi.getClient();
export const getJourneys = GlobalContainerStoreApi.getJourneys();
export const getLevel = GlobalContainerStoreApi.getLevel();

