 //Store for WorkRelatedBehaviours

import {makeAutoObservable} from "mobx";
 import {ChangeEvent} from "react";
 import {downloadExcelFile, isExcelFile} from "utlis/files";
 import {Message} from "components/Message";
 import {debounce} from "lodash";
 import {
    getWorkRelatedBehaviors,
    uploadWorkRelatedBehaviors,
     WorkRelatedBehavioursApisPayload
 } from "container/WorkRelatedBehaviours/store/workRelatedBehaviours.api";
 import {WorkRelatedBehavioursTableData} from "container/WorkRelatedBehaviours/store/workRelatedBehaviours.types";

 class WorkRelatedBehavioursStore {

     constructor() {
         makeAutoObservable(this);
     }

     motivatingFactorRef;
     isWorkRelatedBehavioursUploadLoading = false;
     search;
     page;

     setIsWorkRelatedBehavioursUploadLoading(bool: boolean) {
         this.isWorkRelatedBehavioursUploadLoading = bool;
     }

     setSearch = (search: string) => {
         this.search = search;
         this.page = 1;

         this.callGetWorkRelatedBehavioursWithDebounce({
             search: search,
             page: 1,
         });
     };

     setPage = (page) => {
         this.page = page;

         this.callGetWorkRelatedBehavioursApi({
             search: this.search,
             page: page,
         });
     };

     handleUploadWorkRelatedBehaviours = async (e: ChangeEvent<HTMLInputElement>) => {
         try {
             this.setIsWorkRelatedBehavioursUploadLoading(true);
             const excelFile = e.target.files ? e.target.files[0] : undefined;

             if (!isExcelFile(e.target.value)) {
                 Message.error("Unsupported File");
             }

             const payload = {
                 work_related_behaviors: excelFile,
             };
             await uploadWorkRelatedBehaviors.callApiWithFormData(payload);
             Message.success("Successfully uploaded Work Related Behaviours");
             await getWorkRelatedBehaviors.callApi();
         } catch (error: any) {
             if (error.response.data.errors) {
                 Message.error(error.response.data.errors?.work_related_behaviors[0]);
             } else {
                 Message.error("Failed to upload Work Related Behaviours");
             }
         } finally {
             this.setIsWorkRelatedBehavioursUploadLoading(false);
         }
     };
     downloadWorkRelatedBehavioursData = async () => {
         try {
             const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/descriptors/workRelatedBehaviors/downloadWorkRelatedBehaviors`;

             await downloadExcelFile(endpoint, "Work Related Behaviours", "", "POST");
             Message.success("Downloaded Work Related Behaviours File");
         } catch (error) {
             Message.error("Could not download");
         }
     };

     callGetWorkRelatedBehavioursApi = async (
         payload?: WorkRelatedBehavioursApisPayload
     ) => {
         await getWorkRelatedBehaviors.callApi(payload);
     };

     callGetWorkRelatedBehavioursWithDebounce = debounce(
         (payload?: WorkRelatedBehavioursApisPayload) => {
             getWorkRelatedBehaviors.callApi(payload);
         },
         1500
     );

     get getTableData() {
         return (getWorkRelatedBehaviors.data as WorkRelatedBehavioursTableData[]) || [];
     }

     get getTotalData() {
         return getWorkRelatedBehaviors.meta?.total || "";
     }

     get getMetaData() {
         return getWorkRelatedBehaviors.meta;
     }


 }

export const workRelatedBehavioursStore = new WorkRelatedBehavioursStore();


