// Apis For WorkRelatedBehaviours
import { ApiStore } from 'stores/ApiStore';
import { RestMethods } from 'stores/baseApi';

export interface WorkRelatedBehavioursApisPayload {
  
}

const ENDPOINT_TEMPLATE = '/api/descriptors/workRelatedBehaviors'

class WorkRelatedBehavioursApis extends ApiStore<WorkRelatedBehavioursApisPayload, null, null, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static uploadWorkRelatedBehaviors() {
    const endpoint = `${ENDPOINT_TEMPLATE}/uploadWorkRelatedBehaviors`;
    return new WorkRelatedBehavioursApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }
  static getWorkRelatedBehaviors() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getWorkRelatedBehaviors`;
    return new WorkRelatedBehavioursApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }
  static downloadWorkRelatedBehaviors() {
    const endpoint = `${ENDPOINT_TEMPLATE}/downloadWorkRelatedBehaviors`;
    return new WorkRelatedBehavioursApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }
}

export const uploadWorkRelatedBehaviors: any = WorkRelatedBehavioursApis.uploadWorkRelatedBehaviors();
export const getWorkRelatedBehaviors = WorkRelatedBehavioursApis.getWorkRelatedBehaviors();
export const downloadWorkRelatedBehaviors = WorkRelatedBehavioursApis.downloadWorkRelatedBehaviors();

