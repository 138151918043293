/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-24
 */
// screen file for CategoryManagement

import React, { useState } from "react";
import style from "./index.module.scss";
import { CategoryManagementProps } from "container/CategoryManagement/store/categoryManagement.types";
import { PageTitle } from "components/PageTitle";
import { DropdownButton } from "components/Dropdown/DropdownButton";
import { Title } from "components/Title";
import { SearchInput } from "components/SearchInput";
import { PageBase } from "components/PageBase";
import { useEffectAsync } from "utlis/hooks";
import { observer } from "mobx-react";
import { columnsCategories } from "./tableColumn";
import { categoryManagementStore } from "./store/categoryManagement.store";
import { Modal } from "components/Modal";
import { Input } from "components/Input";
import {
  createCategoriesApi,
  deleteCategoriesApi,
  editCategoriesApi,
  getCategoriesApi,
  getCategoryWorkbooks,
} from "container/CategoryManagement/store/categoryManagement.api";
import { map } from "lodash";
import { Pagination } from "components/Pagination";
import { CreateEditCategoryModal } from "container/CategoryManagement/CategoryManagementModals";
import { authStore } from "stores/AuthStore";
import {Table} from "components/Table";

export const CategoryManagement: React.FC<CategoryManagementProps> = observer(
  ({}) => {
    useEffectAsync(async () => {
      await categoryManagementStore.callGetCategoriesApi();
    }, [
      editCategoriesApi.successResponse,
      createCategoriesApi.successResponse,
      deleteCategoriesApi.successResponse,
      authStore.getClientIdToken,
    ]);

    useEffectAsync(() => {
      categoryManagementStore.callGetCategoriesApiWithDebounce({
        search: categoryManagementStore.getSearch,
        type: categoryManagementStore.type?.value,
        page: categoryManagementStore.getPage,
      });
    }, [
      categoryManagementStore.getSearch,
      categoryManagementStore.getPage,
      categoryManagementStore.type,
    ]);

    const dropDownItem = [
      {
        name: "Create New",
        onClick: () => {
          categoryManagementStore.setIsEdit(false);
          categoryManagementStore.setEditOpen(
            !categoryManagementStore.editOpen
          );
          categoryManagementStore.setSearch("");
          categoryManagementStore.setType("");
          categoryManagementStore.setCategoryEditData(null);
        },
      },
      {
        name: "Upload",
        onClick: () => {
          alert("Hello! I am an alert box!!");
        },
        disabled: true,
      },
    ];

    return (
      <PageBase className={style.categoryContainer}>
        <PageTitle
          pageTitle={`Category Management Page`}
          subTitle={
            "Create and manage your category for your mapping engine workbook?"
          }
        />
        <div className={style.topDropdown}>
          <Title count={categoryManagementStore.getTotalData}>
            CATEGORY LIST
          </Title>
          <div className={`d-flex gap-2`}>
            <SearchInput
              placeholder={"Search Category"}
              onChange={(e) =>
                categoryManagementStore.setSearch(e.target.value)
              }
              onClickDelete={() => categoryManagementStore.setSearch('')}
              value={categoryManagementStore.search}
            />
            <DropdownButton
              buttonProps={{ className: `${style.newCategory} ms-2` }}
              dropdownItem={dropDownItem}
            >
              New Category
            </DropdownButton>
          </div>
        </div>
        <div className={style.tableHeight}>
          <Table
            columns={columnsCategories}
            data={categoryManagementStore.getTableData}
            isLoading={getCategoriesApi.isLoading}
          />
        </div>
        <Pagination
          currentPage={categoryManagementStore.getMetaData?.current_page}
          lastPage={categoryManagementStore.getMetaData?.last_page}
          onPageClick={(e) => categoryManagementStore.setPage(e)}
          onNextClick={() =>
            categoryManagementStore.setPage(
              categoryManagementStore.getMetaData?.current_page + 1
            )
          }
          onPreviousClick={() =>
            categoryManagementStore.setPage(
              categoryManagementStore.getMetaData?.current_page - 1
            )
          }
          onLastClick={() =>
            categoryManagementStore.setPage(
              categoryManagementStore.getMetaData?.last_page
            )
          }
          onFirstClick={() => categoryManagementStore.setPage(1)}
        />
        <CreateEditCategoryModal
          isOpen={categoryManagementStore.editOpen}
          toggle={() =>
            categoryManagementStore.setEditOpen(
              !categoryManagementStore.editOpen
            )
          }
          callGetCategoriesApi={false}
        />
        <Modal
          toggle={() =>
            categoryManagementStore.setDeleteOpen(
              !categoryManagementStore.deleteOpen
            )
          }
          isOpen={categoryManagementStore.deleteOpen}
          headerTitle={"Delete Category"}
          actionBtnLabel={"Confirm"}
          cancelBtnLabel={"Cancel"}
          showActionBtn={categoryManagementStore.catWorkbook == 0}
          actionBtnOnClick={categoryManagementStore.confirmButton}
        >
          <div className={style.deleteModal}>
            {categoryManagementStore.catWorkbook == 0 ? (
              <div>
                Are you sure you want to delete{" "}
                <span className={style.catName}>
                  {categoryManagementStore.catName}
                </span>{" "}
                category?
              </div>
            ) : (
              <div>
                <span className={style.catName}>
                  {categoryManagementStore.catName}
                </span>{" "}
                category can't be deleted as{" "}
                {categoryManagementStore.catWorkbook > 1
                  ? "these workbooks are"
                  : "this workbook is"}{" "}
                using it:
                {map(getCategoryWorkbooks.data?.workbooks, (item) => (
                  <li>{item.name}</li>
                ))}
              </div>
            )}
          </div>
        </Modal>
        <Modal
          toggle={() =>
            categoryManagementStore.setCloneOpen(
              !categoryManagementStore.cloneOpen
            )
          }
          isOpen={categoryManagementStore.cloneOpen}
          headerTitle={"Clone Category"}
          actionBtnLabel={"Confirm"}
          cancelBtnLabel={"Cancel"}
          actionBtnOnClick={categoryManagementStore.cloneButton}
          actionBtnProps={{ disabled: !categoryManagementStore.newCode }}
        >
          <Input
            label={"Old Category Code"}
            defaultValue={
              categoryManagementStore.categoryCloneData?.category_code
            }
            readOnly={true}
          />
          <Input
            label={"New Category Code"}
            placeholder={"Type new different code"}
            onChange={(e) => categoryManagementStore.setNewCode(e.target.value)}
          />
        </Modal>
      </PageBase>
    );
  }
);
