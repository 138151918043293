// screen file for AccessControl
import React from "react";
import style from "container/AccessControl/index.module.scss";
import { AccessControlProps } from "container/AccessControl/store/accessControl.types";
import { PageTitle } from "components/PageTitle";
import { Title } from "components/Title";
import { Button } from "components/Button";
import { Table } from "components/Table";
import { PageBase } from "components/PageBase";
import { columnsAccessControl } from "container/AccessControl/tableColumns";
import { useEffectAsync } from "utlis/hooks";
import { authStore } from "stores/AuthStore";
import { accessControlStore } from "container/AccessControl/store/accessControl.store";
import { getUsersByClient } from "container/AccessControl/store/accessControl.api";
import { observer } from "mobx-react";
import { Modal } from "components/Modal";
import { accessControlCreationFormStore } from "container/AccessControl/store/accessControlCreationFormStore";
import { Input } from "components/Input";
import { Message } from "components/Message";

export const AccessControl: React.FC<AccessControlProps> = observer(({}) => {
  useEffectAsync(async () => {
    await accessControlStore.callGetUsersByClientApi({
      client_id: authStore.getClient,
    });
  }, [authStore.getClientIdToken]);

  const name = accessControlCreationFormStore.$("name");
  const email = accessControlCreationFormStore.$("email");

  const onSubmit = async () => {
    try {
      accessControlStore.setLoading(true);
      await accessControlCreationFormStore.submit();
    } catch (e) {
      Message.error(`Failed to create user`);
      accessControlStore.setLoading(false);
    }
  };

  return (
    <PageBase className={style.accessControlContainer}>
      <PageTitle pageTitle={`Access Control`} />
      <div className={style.topDropdown}>
        <div className={style.titleSearch}>
          <Title count={accessControlStore.getTableData.length}>Users</Title>
          <div className={`d-flex`}>
            <Button
              onClick={() =>
                accessControlStore.setIsOpen(!accessControlStore.isOpen)
              }
            >
              Add User
            </Button>
          </div>
        </div>
      </div>
      <div className={style.tableHeight}>
        <Table
          columns={columnsAccessControl}
          data={accessControlStore.getTableData}
          isLoading={getUsersByClient.isLoading}
        />
      </div>
      <Modal
        toggle={() => accessControlStore.setIsOpen(!accessControlStore.isOpen)}
        isOpen={accessControlStore.isOpen}
        headerTitle={"Add New Users"}
        actionBtnLabel={"Create"}
        cancelBtnLabel={"Cancel"}
        actionBtnOnClick={() => onSubmit()}
      >
        <div>
          <Input {...name.bind()} />
          <Input {...email.bind()} />
        </div>
      </Modal>
    </PageBase>
  );
});
