import * as React from "react";
import style from "./dropdownProfilePhoto.module.scss";
import { DropdownBase, DropdownBaseProps } from "../Dropdown/DropdownBase";
import { UserProfile } from "../UserProfile";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DropdownProfilePhotoProps extends DropdownBaseProps {
    name?: string
}

export const DropdownProfilePhoto: React.FC<DropdownProfilePhotoProps> = (
    {name, ...otherProps}
) => {
  return (
    <div className={style.dropdownProfileContainer}>
      <UserProfile name={name} />
      <DropdownBase dropDownClassName={style.arrow} {...otherProps}>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={style.chevronDownIcon}
        />
      </DropdownBase>
    </div>
  );
};
