import * as React from "react";
import style from "container/Header/index.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faThLarge } from "@fortawesome/free-solid-svg-icons";
import { DropdownActionButton } from "components/Dropdown/DropdownActionButton";
import { DropdownItemData } from "components/Dropdown/DropdownBase";
import { DropdownProfilePhoto } from "components/Dropdown/DropdownProfilePhoto";
import { MappingAccendo } from "components/MappingLogo/MappingAccendo";
import {
  ACCESS_CONTROL_DOMAIN,
  CATEGORY_MANAGEMENT_DOMAIN,
  COMPETENCY_LIBRARY_DOMAIN,
  LOOKUP_DOMAIN,
  MAPPER_TEMPLATE_DOMAIN,
  MAPPING_DOMAIN,
  MOTIVATING_FACTOR_DOMAIN,
  RESULT_POST_LOG_DOMAIN,
  WORK_RELATED_BEHAVIOURS_DOMAIN,
} from "constants/routes";
import { Link } from "react-router-dom";
import { globalContainerStore } from "container/_globalContainerStore/globalContainerStore.store";
import { Select } from "components/Select";
import { authStore } from "stores/AuthStore";
import { useEffectAsync } from "utlis/hooks";
import { useState } from "react";
import { observer } from "mobx-react";
interface HeaderProps {
  name?: string;
}

export const Header: React.FC<HeaderProps> = observer(({ name }) => {
  const [value, setValue] = useState(true);
  const [defaultValue, setDefaultValue] = useState({
    label: "-",
    value: undefined,
  });
  useEffectAsync(() => {
    const option = globalContainerStore.getClientOptionData;
    if (option[0] && value) {
      authStore.setClient(option[0].value);
      setDefaultValue({ label: option[0].label, value: option[0].value });
      setValue(false);
    }
  }, [globalContainerStore.getClientOptionData]);

  useEffectAsync(async () => {
    await globalContainerStore.callGetClientApi();
  }, []);

  const dropdownItems: DropdownItemData[] = [
    {
      name: "Application Menu",
      mainHeader: true,
    },
    {
      name: "Upload Lookup XL",
      to: LOOKUP_DOMAIN,
    },
    {
      name: "Upload Motivating Factor",
      to: MOTIVATING_FACTOR_DOMAIN,
    },
    {
      name: "Upload Work Related Behaviours",
      to: WORK_RELATED_BEHAVIOURS_DOMAIN,
    },
    {
      name: "Upload Competency Library",
      to: COMPETENCY_LIBRARY_DOMAIN,
    },
    {
      name: "Category Management",
      to: CATEGORY_MANAGEMENT_DOMAIN,
    },
    {
      name: "Mapper Template",
      to: MAPPER_TEMPLATE_DOMAIN,
    },
    {
      name: "Access Control",
      to: ACCESS_CONTROL_DOMAIN,
    },
    {
      name: "Result Post Log",
      to: RESULT_POST_LOG_DOMAIN,
    },
  ];

  console.log('process.env.REACT_APP_ACCOUNT_FRONT_END_URL', process.env.REACT_APP_ACCOUNT_FRONT_END_URL);
  console.log('process.env.REACT_APP_BACKEND_URL', process.env.REACT_APP_BACKEND_URL);
  const dropdownAccountOption: DropdownItemData[] = [
    {
      name: "Logout",
      onClick: () => window.location.replace(process.env.REACT_APP_ACCOUNT_FRONT_END_URL),
    },
  ];

  return (
    <div className={style.headerContainer}>
      <Link to={MAPPING_DOMAIN}>
        <MappingAccendo size={40} />
      </Link>
      <div className={style.rightContainer}>
        <Select
          className={`ms-2 ${style.selectClient}`}
          placeholder={"Client"}
          options={globalContainerStore.getClientOptionData}
          showError={false}
          onChange={(e: any) => {
            setDefaultValue(e);
            authStore.setClient(e.value);
          }}
          value={defaultValue}
        />
        <Link to={MAPPING_DOMAIN}>
          <FontAwesomeIcon icon={faHome} className={style.homeIcon} />
        </Link>
        <div className={`ms-3`}>
          <DropdownActionButton
            dropdownItem={dropdownItems}
            icon={faThLarge}
            className={`${style.homeIcon}`}
          />
        </div>
        <div className={`ms-4 me-3`}>
          Hello, <span className={style.name}>{name}</span>
        </div>
        <DropdownProfilePhoto
          dropdownItem={dropdownAccountOption}
          name={name}
        />
      </div>
    </div>
  );
});
