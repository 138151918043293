// screen file for MotivatingFactors
import React from "react";
import style from "container/MotivatingFactors/index.module.scss";
import { MotivatingFactorsProps } from "container/MotivatingFactors/store/motivatingFactors.types";
import { observer } from "mobx-react";
import { useEffectAsync } from "utlis/hooks";
import { authStore } from "stores/AuthStore";
import { PageBase } from "components/PageBase";
import { PageTitle } from "components/PageTitle";
import { Title } from "components/Title";
import { SearchInput } from "components/SearchInput";
import { Button } from "components/Button";
import { Table } from "components/Table";
import { Pagination } from "components/Pagination";
import { motivatingFactorsStore } from "./store/motivatingFactors.store";
import { getMotivatingFactors } from "container/MotivatingFactors/store/motivatingFactors.api";
import { columnsMotivatingFactors } from "container/MotivatingFactors/tableColumn";

export const MotivatingFactors: React.FC<MotivatingFactorsProps> = observer(
  ({}) => {
    useEffectAsync(async () => {
      await motivatingFactorsStore.callGetMotivatingFactorsApi();
    }, [authStore.getClientIdToken]);

    return (
      <PageBase className={style.motivatingFactorContainer}>
        <PageTitle pageTitle={`Upload Motivating Factors`} />
        <div className={style.topDropdown}>
          <div className={style.titleSearch}>
            <Title count={motivatingFactorsStore.getTotalData}>
              Motivating Factors
            </Title>
            <SearchInput
              placeholder={"Search"}
              onChange={(e) => motivatingFactorsStore.setSearch(e.target.value)}
            />
          </div>
          <div className={style.filesContainer}>
            <div className={style.uploadFile}>
              <div className={style.label}>Upload New Document:</div>
              <Button
                className={`ms-2`}
                onClick={() =>
                  motivatingFactorsStore.motivatingFactorRef.click()
                }
                loading={
                  motivatingFactorsStore.isMotivatingFactorsUploadLoading
                }
                disabled={
                  motivatingFactorsStore.isMotivatingFactorsUploadLoading
                }
              >
                Upload File
              </Button>
              <input
                className="d-none"
                type="file"
                accept=".xlsx, .xls"
                id={"input"}
                ref={(ref) =>
                  (motivatingFactorsStore.motivatingFactorRef = ref)
                }
                value=""
                onChange={(e) =>
                  motivatingFactorsStore.handleUploadMotivatingFactors(e)
                }
              />
            </div>
            <div className={style.downloadSection}>
              <div className={style.label}>
                Download Previous Uploaded Document:
              </div>
              <Button
                className={`ms-2`}
                onClick={() =>
                  motivatingFactorsStore.downloadMotivatingFactorsData()
                }
              >
                Download
              </Button>
            </div>
          </div>
        </div>
        <div className={style.tableHeight}>
          <Table
            columns={columnsMotivatingFactors}
            data={motivatingFactorsStore.getTableData}
            isLoading={getMotivatingFactors.isLoading}
          />
        </div>
        <Pagination
          currentPage={motivatingFactorsStore.getMetaData?.current_page}
          lastPage={motivatingFactorsStore.getMetaData?.last_page}
          onPageClick={(e) => motivatingFactorsStore.setPage(e)}
          onNextClick={() =>
            motivatingFactorsStore.setPage(
              motivatingFactorsStore.getMetaData?.current_page + 1
            )
          }
          onPreviousClick={() =>
            motivatingFactorsStore.setPage(
              motivatingFactorsStore.getMetaData?.current_page - 1
            )
          }
          onLastClick={() =>
            motivatingFactorsStore.setPage(
              motivatingFactorsStore.getMetaData?.last_page
            )
          }
          onFirstClick={() => motivatingFactorsStore.setPage(1)}
        />
      </PageBase>
    );
  }
);
