/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-26
 */

// screen file for ResultPostLog
import React from "react";
import style from "./index.module.scss";
import { ResultPostLogProps } from "container/ResultPostLog/store/resultPostLog.types";
import { PageTitle } from "components/PageTitle";
import { TableWithVirtualization } from "components/TableWithVirtualization";
import { Title } from "components/Title";
import { SearchInput } from "components/SearchInput";
import { PageBase } from "components/PageBase";
import { ColumnsResult } from "./tableColumn";
import { resultPostLogStore } from "container/ResultPostLog/store/resultPostLog.store";
import { useEffectAsync } from "utlis/hooks";
import { authStore } from "stores/AuthStore";
import { observer } from "mobx-react";
import { getResultPostLog } from "container/ResultPostLog/store/resultPostLog.api";
import {Modal} from "components/Modal";
import ReactJson from "react-json-view";
import {Pagination} from "components/Pagination";

export const ResultPostLog: React.FC<ResultPostLogProps> = observer(({}) => {
  useEffectAsync(async () => {
    await resultPostLogStore.callResultPostLogApi();
  }, [authStore.getClientIdToken]);

  return (
    <PageBase className={style.resultContainer}>
      <PageTitle
        pageTitle={`Result Post Log`}
        subTitle={"Track and export result history."}
      />
      <div className={style.topDropdown}>
        <Title count={resultPostLogStore.getTotalData}>
          RESULT POST LOG LIST
        </Title>
        <SearchInput
          placeholder={"Search Post Log"}
          onChange={(e) => resultPostLogStore.setSearch(e.target.value)}
        />
      </div>
        <div className={style.tableHeight}>
            <TableWithVirtualization
                columns={ColumnsResult}
                data={resultPostLogStore.getResultPostLogData}
                isLoading={getResultPostLog.isLoading}
            />
        </div>
        <Pagination
            currentPage={resultPostLogStore.getMetaData?.current_page}
            lastPage={resultPostLogStore.getMetaData?.last_page}
            onPageClick={(e) => resultPostLogStore.setPage(e)}
            onNextClick={() =>
                resultPostLogStore.setPage(resultPostLogStore.getMetaData?.current_page + 1)
            }
            onPreviousClick={() =>
                resultPostLogStore.setPage(resultPostLogStore.getMetaData?.current_page - 1)
            }
            onLastClick={() =>
                resultPostLogStore.setPage(resultPostLogStore.getMetaData?.last_page)
            }
            onFirstClick={() => resultPostLogStore.setPage(1)}
        />
        <Modal
            toggle={resultPostLogStore.setOpenModal}
            isOpen={resultPostLogStore.isOpen}
            headerTitle={resultPostLogStore.selectedData?.journey.name}
            cancelBtnLabel={"Close"}
            actionBtnLabel={"Copy Json"}
            actionBtnOnClick={resultPostLogStore.copyJsonResponse}
            actionDisableToggle={true}
            modalSize={style.modalSize}
        >
            <div className={style.jsonContainer}>
                <ReactJson src={resultPostLogStore.getSelectedData} enableClipboard={false} />
            </div>
        </Modal>
    </PageBase>
  );
});
