import * as React from "react";
import style from "./index.module.scss";
import { Modal, ModalProps } from "components/Modal";
import { Input } from "components/Input";
import { workbookFormulaMapping } from "container/Workbook/WorkbookConfiguration/store/workbookFormulaMapping.store";

import { workbookFormulaFormStore } from "container/Workbook/WorkbookConfiguration/store/workbookFormula.formStore";
import { Label } from "components/Label";
import { observer } from "mobx-react";
import { useEffectAsync } from "utlis/hooks";
import { searchFields } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.api";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MonocoEditor } from "container/Workbook/WorkbookConfiguration/component/FormulaCombinationModal/monocoEditor";

interface FormulaCombinationModalProps extends ModalProps {}

export const FormulaCombinationModal = observer(({ toggle, isOpen }) => {
  const formulaName = workbookFormulaFormStore.$("formula_name");

  useEffectAsync(() => {
    if (workbookFormulaMapping.editFormulaDetails?.id) {
      formulaName.set(
        "value",
        workbookFormulaMapping.editFormulaDetails.formula_name
      );
    }
  }, [workbookFormulaMapping.editFormulaDetails]);

  useEffectAsync(async () => {
    await workbookFormulaMapping.callSearchFieldsApi("");
  }, []);

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      mainModalContentClassName={style.modalContent}
      headerTitle={"Create Formula Combination"}
      actionBtnLabel={
        workbookFormulaMapping.editFormulaDetails ? "Edit" : "Create"
      }
      cancelBtnLabel={"Cancel"}
      modalSize={style.modalSize}
      actionBtnOnClick={() => workbookFormulaFormStore.submit()}
      cancelBtnOnClick={() => {
        workbookFormulaMapping.setEditFormulaData(undefined);
        workbookFormulaFormStore.reInit();
      }}
    >
      <div>
        <Input {...formulaName.bind()} />
        <div className={`d-flex justify-content-between align-items-center`}>
          <Label>Write your formula here</Label>
          {searchFields.isLoading && (
            <div className={`d-flex align-items-center`}>
              <Label>Fetching Option From Api</Label>
              <FontAwesomeIcon
                className={style.loading}
                icon={faSpinner}
                spin={true}
              />
            </div>
          )}
        </div>
        <div className={style.formulaContainer}>
          {isOpen && <MonocoEditor />}
        </div>
      </div>
    </Modal>
  );
});
