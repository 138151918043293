// Apis For ViewDataset
import { ApiStore } from 'stores/ApiStore';
import { RestMethods } from 'stores/baseApi';

export interface ViewDatasetApisPayload {
  
}

const ENDPOINT_TEMPLATE_CALCULATION = '/api/calculate'
const ENDPOINT_TEMPLATE_WORKBOOK = '/api/workbook'
const ENDPOINT_TEMPLATE_FINALIZE = '/api/finalize'

class ViewDatasetApis extends ApiStore<ViewDatasetApisPayload, null, null, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE_CALCULATION, options = {}) {
    super(endpoint, options);
  }

  static getCalculatedData() {
    const endpoint = `${ENDPOINT_TEMPLATE_CALCULATION}/getCalculatedData`;
    return new ViewDatasetApis(endpoint, { requestType: RestMethods.GET });
  }

  static finalizeWorkbook() {
    const endpoint = `${ENDPOINT_TEMPLATE_FINALIZE}/finalizeWorkbook`;
    return new ViewDatasetApis(endpoint, { requestType: RestMethods.POST });
  }

  static getWorkbookFields() {
    const endpoint = `${ENDPOINT_TEMPLATE_WORKBOOK}/:workbook_id/getWorkbookFields`;
    return new ViewDatasetApis(endpoint, { requestType: RestMethods.GET });
  }

  static setWorkbookFields() {
    const endpoint = `${ENDPOINT_TEMPLATE_WORKBOOK}/:workbook_id/setWorkbookFields`;
    return new ViewDatasetApis(endpoint, { requestType: RestMethods.POST });
  }
}

export const getCalculatedData = ViewDatasetApis.getCalculatedData();
export const finalizeWorkbook = ViewDatasetApis.finalizeWorkbook();
export const getWorkbookFields = ViewDatasetApis.getWorkbookFields();
export const setWorkbookFields = ViewDatasetApis.setWorkbookFields();

