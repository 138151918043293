import {Message} from "components/Message";

export const handleFailureResponse = (failureResponse, defaultError) => {
    if (failureResponse && failureResponse.errors) {
        const error = failureResponse.errors;
        const errorArray = Object.keys(error).map(key => {
            return `${error[key]}`;
        });
        Message.error(errorArray[0])
    } else {
        Message.error(defaultError)
    }
};
