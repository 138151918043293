import * as React from "react";
import style from "./index.module.scss";
import { FormulaContainer } from "container/Workbook/WorkbookConfiguration/component/FormulaBox/DragableComponent";
import { CheckBox } from "components/CheckBox";
import { ToggleSwitch } from "components/ToggleSwitch";
import { Input } from "components/Input";
import { observer } from "mobx-react";
import { workbookConfigurationStore } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.store";
import { useEffectAsync } from "utlis/hooks";
import { workbookFormulaMapping } from "container/Workbook/WorkbookConfiguration/store/workbookFormulaMapping.store";
import { get, toNumber } from "lodash";
import { Button } from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormulaCombinationModal } from "container/Workbook/WorkbookConfiguration/component/FormulaCombinationModal";
import { LoadingData } from "components/Loading";
import { Label } from "components/Label";
import { Tooltip } from "components/Tooltip";
import { SingleTableData } from "container/Workbook/WorkbookConfiguration/component/MappingDetails/SingleTableData";
import { useState } from "react";

interface MappingDetailsProps {}

export const MappingDetails: React.FC<MappingDetailsProps> = observer(
  (props) => {
    const [showThresholdError, setShowThresholdError] = useState(false);

    useEffectAsync(async () => {
      if (workbookConfigurationStore.selectedCategoryId) {
        await workbookFormulaMapping.callWbCategoryDataApi(
          workbookConfigurationStore.selectedCategoryId
        );
      }
    }, [workbookConfigurationStore.selectedCategoryId]);

    const scoreArray = [
      { label: "Original Score", key: "is_original_score" },
      { label: "Percentile", key: "is_percentile" },
      { label: "Sten", key: "is_sten" },
      { label: "Sten 5", key: "is_sten5" },
      { label: "Sten 5 (Decimal)", key: "is_sten5_decimal" },
      { label: "Sten 9", key: "is_sten9" },
      { label: "Percentage", key: "is_percentage" },
    ];

    const mappingDetailScreen = (
      <div className={style.formulaDetailContainer}>
        {workbookConfigurationStore.selectedCategoryId ? (
          <div className={style.detailContainerFade}>
            <div className={`d-flex`}>
              <div className={style.subTitle}>
                {" "}
                <span className={`me-2`}>Set New Formula</span>
                <Button
                  className={style.addButton}
                  onClick={() =>
                    workbookFormulaMapping.setIsOpen(
                      !workbookFormulaMapping.isOpen
                    )
                  }
                >
                  <FontAwesomeIcon icon={faPlus} className={style.addIcon} />
                </Button>
              </div>
            </div>
            <div className={style.description}>
              Ensure that you use the z-score fields when setting up formulas.
            </div>
            <div className={style.newFormulaContainer}>
              <FormulaContainer />
            </div>
            <div className={style.border}>
              <div className={style.subTitle}>Type of Scores:</div>
              <div className={style.checkBoxContainer}>
                {scoreArray.map((item, index) => (
                  <CheckBox
                    key={index}
                    className={`me-2`}
                    label={item.label}
                    checked={get(
                      workbookFormulaMapping.getWbCategoryData,
                      `${item.key}`,
                      false
                    )}
                    onChange={() =>
                      workbookFormulaMapping.setWbCategoryData({
                        ...workbookFormulaMapping.getWbCategoryData,
                        [item.key]: !get(
                          workbookFormulaMapping.getWbCategoryData,
                          `${item.key}`
                        ),
                      })
                    }
                  />
                ))}
              </div>
            </div>
            <div className={style.border}>
              <div className={style.subTitle}>Other Options:</div>
              <div className={`d-flex`}>
                <Input
                  className={style.inputLabel}
                  inputContainerClassName={`${style.inputInnerContainer} ${style.inputMargin}`}
                  label={"Number of Decimals :"}
                  type={"number"}
                  value={get(
                    workbookFormulaMapping.getWbCategoryData,
                    "no_of_decimals",
                    ""
                  )}
                  onChange={(e) =>
                    workbookFormulaMapping.setWbCategoryData({
                      ...workbookFormulaMapping.getWbCategoryData,
                      no_of_decimals: parseInt(e.target.value),
                    })
                  }
                />
                <div className={style.toggleSwitch}>
                  <ToggleSwitch
                    id="toggle"
                    labelText={"Show in Excel:"}
                    checked={get(
                      workbookFormulaMapping.getWbCategoryData,
                      "show_in_excel",
                      false
                    )}
                    onChange={() =>
                      workbookFormulaMapping.setWbCategoryData({
                        ...workbookFormulaMapping.getWbCategoryData,
                        show_in_excel:
                          !workbookFormulaMapping.getWbCategoryData
                            .show_in_excel,
                      })
                    }
                  />
                </div>
              </div>
              <div className={`d-flex align-items-center`}>
                <Label>Set Passing Threshold :</Label>
                <Tooltip
                  content={
                    <div>
                      <div>Converter guide (key in z-score)</div>
                      <SingleTableData sten={"sten"} zScore={"z-score"} />
                      <SingleTableData sten={"1"} zScore={"-2.5"} />
                      <SingleTableData sten={"2"} zScore={"-2"} />
                      <SingleTableData sten={"3"} zScore={"-1.5"} />
                      <SingleTableData sten={"4"} zScore={"-1"} />
                      <SingleTableData sten={"5"} zScore={"-0.5"} />
                      <SingleTableData sten={"6"} zScore={"0"} />
                      <SingleTableData sten={"7"} zScore={"0.5"} />
                      <SingleTableData sten={"8"} zScore={"1"} />
                      <SingleTableData sten={"9"} zScore={"1.5"} />
                      <SingleTableData sten={"10"} zScore={"2"} />
                    </div>
                  }
                >
                  <FontAwesomeIcon className={style.info} icon={faInfoCircle} />
                </Tooltip>
                <Input
                  className={style.inputThreshold}
                  inputContainerClassName={`${style.inputInnerContainer} mt-2 ms-2`}
                  step={0.01}
                  type={"number"}
                  value={get(
                    workbookFormulaMapping.getWbCategoryData,
                    "threshold",
                    ""
                  )}
                  onChange={(e) => {
                    if (
                      (toNumber(e.target.value) >= -3 &&
                        toNumber(e.target.value) <= 3) ||
                      e.target.value === "-"
                    ) {
                      setShowThresholdError(false);
                      workbookFormulaMapping.setWbCategoryData({
                        ...workbookFormulaMapping.getWbCategoryData,
                        threshold: e.target.value,
                      });
                    } else {
                      setShowThresholdError(true);
                    }
                  }}
                  error={
                    showThresholdError &&
                    "The threshold must be between -3 and 3."
                  }
                />
              </div>
            </div>
            <FormulaCombinationModal
              isOpen={workbookFormulaMapping.isOpen}
              toggle={() =>
                workbookFormulaMapping.setIsOpen(!workbookFormulaMapping.isOpen)
              }
            />
          </div>
        ) : (
          <div className={style.placeholderContainer}>
            <div className={style.placeholderFont}>
              Select A Mapping Branch to Configure
            </div>
          </div>
        )}
      </div>
    );

    return workbookFormulaMapping.workbookLoading ? (
      <LoadingData
        className={`d-flex justify-content-center align-items-center ${style.formulaDetailContainer} ${style.height}`}
        loadingText={"Populating Details"}
      />
    ) : (
      mappingDetailScreen
    );
  }
);
