import * as React from "react";
import {
  Column,
  TableInstance,
  useSortBy,
  useTable,
  useRowSelect,
} from "react-table";
import style from "./index.module.scss";
import { SkeletonBox } from "components/SkeletonBox";
import { CheckBox } from "components/CheckBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface TableProps {
  data?: any;
  columns?: Column[];
  isLoading?: boolean;
  useCheckBox?: boolean;
  returnCheckBoxData?: (checkedData) => void;
  tableClassName?: string;
  stickyHeader?: boolean;
}

export const Table: React.FC<TableProps> = ({
  data,
  columns,
  isLoading,
  useCheckBox,
  returnCheckBoxData,
  tableClassName,
    stickyHeader= true,
}) => {
  const tableInstance: TableInstance = useTable(
    { data, columns },
    useSortBy,
    useRowSelect,
    (hooks) => {
      useCheckBox &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div className={style.checkboxSpacingHeader}>
                <CheckBox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div className={style.checkboxSpacingCell}>
                <CheckBox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
            disableSortBy: true,
          },
          ...columns,
        ]);
    }
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = tableInstance;
  const theaderEl = React.useRef(undefined);

  React.useEffect(() => {
    if (useCheckBox) {
      returnCheckBoxData(selectedFlatRows.map((d) => d.original));
    }
  }, [selectedRowIds]);

  const handleOnScroll = (e) => {
    if (stickyHeader && theaderEl.current) {
      theaderEl.current.style.transform = `translateY(${e.target.scrollTop}px)`;
    }
  };

  const renderSorting = (isSorted, isSortedDesc) => {
    return (
      <span className={style.sortingSpacing}>
        <div className={style.heightSpacing}>
          <FontAwesomeIcon
            className={`${style.chevron} ${
              isSorted && !isSortedDesc && style.chevronActive
            }`}
            icon={faChevronUp}
          />
        </div>
        <div className={style.heightSpacing}>
          <FontAwesomeIcon
            className={`${style.chevron} ${
              isSorted && isSortedDesc && style.chevronActive
            }`}
            icon={faChevronDown}
          />
        </div>
      </span>
    );
  };

  return (
    <div
      className={`${style.container__Table} ${tableClassName}`}
      onScroll={handleOnScroll}
    >
      <table className={style.table} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className={style.headerRow}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className={style.headerSpacing}>
                    {column.render("Header")}
                    {!column.disableSortBy &&
                      renderSorting(column.isSorted, column.isSortedDesc)}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading
            ? [1, 2, 3].map((row) => (
                <tr className={style.loaderRow} key={row}>
                  <td
                    colSpan={useCheckBox ? columns.length + 1 : columns.length}
                  >
                    <SkeletonBox
                      width={"100%"}
                      height={32}
                      borderRadius={"8px"}
                    />
                  </td>
                </tr>
              ))
            : data.length === 0 ? (
                  <tr className={style.noData}>
                    <td>No Data For This Table</td>
                  </tr>
              ) : rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr className={style.row} key={i} {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};
