import * as React from "react";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownButton } from "../Dropdown/DropdownButton";
import style from "./index.module.scss";
import { map } from "lodash";

export interface PaginatorProps {
  currentPage: number;
  lastPage: number;
  perPage?: number;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onLastClick?: () => void;
  onFirstClick?: () => void;
  onPageClick?: (pageNo: number) => void;
  onRowsPerPageChange?: (noOfRow) => void;
  showPerPage?: boolean
}

export const Pagination = ({
  currentPage,
  lastPage,
  perPage = 20,
  onNextClick,
  onPreviousClick,
  onLastClick,
  onFirstClick,
  onPageClick,
  onRowsPerPageChange,
                             showPerPage = false,
}: PaginatorProps) => {
  const pages = [...Array(lastPage)].map((v, idx) => ({
    name: `Page ${idx + 1}`,
    onClick: () => onPageClick(idx + 1),
  }));
  const rows = [10, 20, 50, 100];

  return (
    <div className={style.container__paginator}>
      <div className={style.leftSection}>
        {showPerPage && <div className="d-flex flex-row align-items-center">
          <span className={`me-2`}>Select Rows: </span>
          <div className={style.availablePageWrapper}>
            {map(rows, (item, index) => (
                <button
                    key={index}
                    className={`${style.pageNumbersBtn} ${
                        item === perPage && style.active
                    }`}
                    onClick={() => {
                      onRowsPerPageChange(item);
                    }}
                >
                  {item}
                </button>
            ))}
          </div>
        </div>}
      </div>
      <div className={style.rightSection}>
        <div className={style.pages}>
          <div
            className={[
              style.pagesButton,
              currentPage === 1 && style.disabled,
            ].join(" ")}
            onClick={currentPage !== 1 ? onFirstClick : undefined}
          >
            <FontAwesomeIcon
              icon={faAngleDoubleLeft}
              color={currentPage === 1 ? "var(--disabled-text)" : undefined}
            />
          </div>
          <div
            className={[
              style.pagesButton,
              currentPage === 1 && style.disabled,
            ].join(" ")}
            onClick={currentPage !== 1 ? onPreviousClick : undefined}
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              color={currentPage === 1 ? "var(--disabled-text)" : undefined}
            />
          </div>
          <DropdownButton
            dropdownItem={pages}
            dropdownProps={{ direction: "up" }}
          >
            Page {currentPage}
          </DropdownButton>
          <div
            className={[
              style.pagesButton,
              currentPage === lastPage && style.disabled,
            ].join(" ")}
            onClick={currentPage !== lastPage ? onNextClick : undefined}
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              color={
                currentPage === lastPage ? "var(--disabled-text)" : undefined
              }
            />
          </div>
          <div
            className={`
              ${style.pagesButton}
              ${currentPage === lastPage && style.disabled}`}
            onClick={currentPage !== lastPage ? onLastClick : undefined}
          >
            <FontAwesomeIcon
              icon={faAngleDoubleRight}
              color={
                currentPage === lastPage ? "var(--disabled-text)" : undefined
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
