import * as React from "react";
import style from "./index.module.scss";
import { TableInstance, useTable, Column, useSortBy } from "react-table";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface ReviewTableBreakdownProps {
  data: any;
  columns: Column[];
  stickyHeader?: boolean;
  tableClassName?: string;
}

export const ReviewTableBreakdown: React.FC<ReviewTableBreakdownProps> = ({
  data,
  columns,
  stickyHeader = true,
  tableClassName,
}: ReviewTableBreakdownProps) => {
  const tableInstance: TableInstance = useTable({ columns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  const theaderEl = React.useRef(undefined);

  const handleOnScroll = (e) => {
    if (stickyHeader && theaderEl.current) {
      theaderEl.current.style.transform = `translateY(${e.target.scrollTop}px)`;
    }
  };

  const renderSorting = (isSorted, isSortedDesc) => {
    return (
        <span className={style.sortingSpacing}>
        <div className={style.heightSpacing}>
          <FontAwesomeIcon className={`${style.chevron} ${isSorted && !isSortedDesc && style.chevronActive}`} icon={faChevronUp} />
        </div>
        <div className={style.heightSpacing}>
          <FontAwesomeIcon className={`${style.chevron} ${isSorted && isSortedDesc && style.chevronActive}`} icon={faChevronDown} />
        </div>
      </span>
    );
  };

  return (
    <div className={`${style.container__Table} ${tableClassName}`} onScroll={handleOnScroll}>
      <table
        className={`table`}
        {...getTableProps()}
      >
        <thead ref={theaderEl}>
          {headerGroups.map((headerGroup) => (
            <tr
              className={style.headerRow}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {!column.disableSortBy && renderSorting(column.isSorted, column.isSortedDesc)}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr className={style.row} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td className={style.td} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
