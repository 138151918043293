import React from "react";
import { isNil } from "lodash";
import { format, parseISO } from "date-fns";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { accessControlStore } from "container/AccessControl/store/accessControl.store";
import { authStore } from "stores/AuthStore";
import { ConfirmationBox } from "components/ConfirmationBox";
import style from "./index.module.scss";
import { Message } from "components/Message";

export const columnsAccessControl = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ row }) => <div>{row.original.name || "-"}</div>,
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ row }) => <div>{row.original.email || "-"}</div>,
  },
  {
    Header: "Created at",
    accessor: "created_at",
    Cell: ({ row }) => (
      <div>
        {isNil(row.original.created_at)
          ? "-"
          : format(parseISO(row.original.created_at), "PPpp")}
      </div>
    ),
  },
  {
    Header: "Action",
    accessor: "action",
    disableSortBy: true,
    Cell: ({ row }) => (
      <FontAwesomeIcon
        icon={faTrash}
        onClick={async () => {
          await ConfirmationBox.alert(
            <div>
              Are you sure want to delete
              <span className={`${style.boldText} mx-1`}>
                {row.original?.name}
              </span>
              ?
            </div>,
            {
              confirmBtnOnClick: async () => {
                try {
                  await accessControlStore.callRemoveUserApi(
                    row.original.name,
                    row.original.email,
                    authStore.getClient,
                    row.original.id
                  );
                    await accessControlStore.callGetUsersByClientApi({
                        client_id: authStore.getClient,
                    })
                  Message.success(`Successfully remove ${row.original.name} `);
                } catch (e) {
                  Message.error(`Failed to removed ${row.original.name}.`);
                  console.error("Error removing users.");
                }
              },
            }
          );
        }}
      />
    ),
  },
];
