import * as React from 'react';
import style from './index.module.scss';

interface TitleProps {
    count?: string | number;
    className?: string;
    showScore?: boolean;
}

export const Title: React.FC<TitleProps> = ({count, className, children, showScore = true}) => {
  return (
      <div className={`${style.titleContainer} ${className}`}>
          {children} {showScore && <span>({count || 0})</span>}
      </div>
  );
};