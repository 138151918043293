// Apis For WorkbookConfiguration
import { ApiStore } from "stores/ApiStore";
import { RestMethods } from "stores/baseApi";

export interface WorkbookConfigurationApisPayload {}

const ENDPOINT_TEMPLATE = "/api/formula";
const CATEGORY_ENDPOINT_TEMPLATE = "/api/categories";

class WorkbookConfigurationApis extends ApiStore<
  WorkbookConfigurationApisPayload,
  null,
  null,
  null
> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static searchFields() {
    const endpoint = `${ENDPOINT_TEMPLATE}/searchFields`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }

  static getFormulaTree() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getFormulaTree`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }

  static createFormulaCombination() {
    const endpoint = `${ENDPOINT_TEMPLATE}/createFormulaCombination`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }

  static updateFormulaCombination() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:formula_id/updateFormulaCombination`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }

  static updateFormulaOrder() {
    const endpoint = `${ENDPOINT_TEMPLATE}/updateFormulaOrder`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }

  static getFormulaCombinations() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getFormulaCombinations`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }

  static deleteFormulaCombination() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:formula_id/deleteFormulaCombination`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }

  static getWbCategoryData() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getWbCategoryData`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }

  static setScoreOptions() {
    const endpoint = `${ENDPOINT_TEMPLATE}/setScoreOptions`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }

  static addCategory() {
    const endpoint = `${CATEGORY_ENDPOINT_TEMPLATE}/add`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }

  static removeCategory() {
    const endpoint = `${CATEGORY_ENDPOINT_TEMPLATE}/remove`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }

  static reorderMappingTree() {
    const endpoint = `${CATEGORY_ENDPOINT_TEMPLATE}/reorder`;
    return new WorkbookConfigurationApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }
}

export const searchFields = WorkbookConfigurationApis.searchFields();
export const getFormulaTree = WorkbookConfigurationApis.getFormulaTree();
export const createFormulaCombination =
  WorkbookConfigurationApis.createFormulaCombination();
export const updateFormulaCombination =
  WorkbookConfigurationApis.updateFormulaCombination();
export const updateFormulaOrder =
  WorkbookConfigurationApis.updateFormulaOrder();
export const getFormulaCombinations =
  WorkbookConfigurationApis.getFormulaCombinations();
export const deleteFormulaCombination =
  WorkbookConfigurationApis.deleteFormulaCombination();
export const getWbCategoryData = WorkbookConfigurationApis.getWbCategoryData();
export const setScoreOptions = WorkbookConfigurationApis.setScoreOptions();

export const addCategory = WorkbookConfigurationApis.addCategory();
export const removeCategory = WorkbookConfigurationApis.removeCategory();
export const reorderMappingTree =
  WorkbookConfigurationApis.reorderMappingTree();
