import * as React from "react";
import style from "./index.module.scss";

interface SkeletonBoxProps {
  height: string | number;
  width: string | number;
  borderRadius?: string;
  bgColor?: string;
  className?: string;
}

/**
 * Modular skeleton loader for loading page. Set height, width, and border radius according to your needs
 * @param param0
 * @returns
 */
export const SkeletonBox: React.FC<SkeletonBoxProps> = ({
  height,
  width,
  className,
  borderRadius = 0,
  bgColor = "#e0e0e0",
}) => {
  return (
    <div
      className={`${style.skeletonBox} ${className}`}
      style={{ height, width, borderRadius, backgroundColor: bgColor }}
    />
  );
};
