/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-32
 */

// screen file for MapperSetup
import React, { useState } from "react";
import style from "./index.module.scss";
import { MapperSetupProps } from "container/MapperTemplate/MapperSetup/store/mapperSetup.types";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faInfoCircle,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Select } from "components/Select";
import { mapperSetupStore } from "./store/mapperSetup.store";
import { HeaderFooterContainer } from "components/HeaderFooterContainer";
import { MAPPER_TEMPLATE_DOMAIN } from "constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import {
  mappedCsvFields,
  mappingCsv,
  updateMapperTemplate,
} from "container/MapperTemplate/MapperSetup/store/mapperSetup.api";
import { debounce, isEmpty, map } from "lodash";
import { useEffectAsync } from "utlis/hooks";
import { observer } from "mobx-react";
import { Message } from "components/Message";
import { mapperSetupFormStore } from "container/MapperTemplate/MapperSetup/store/mapperSetup.formstore";
import { Tooltip } from "components/Tooltip";
import { authStore } from "stores/AuthStore";

export const MapperSetup: React.FC<MapperSetupProps> = observer(({}) => {
  let navigate = useNavigate();
  const { mapperId } = useParams();
  const [autoSave, setAutoSave] = useState(false);
  const [mappingName, setMappingName] = useState<string>();

  useEffectAsync(async () => {
    mapperSetupStore.setMapperId(mapperId);
    await mapperSetupStore.init();
    setMappingName(mappedCsvFields.data?.mapperTemplateName);
  }, [authStore.getClientIdToken]);

  return (
    <HeaderFooterContainer
      header={
        <div className={style.subHeader}>
          <div className={style.mappingName}>
            <div className={style.mappingLabel}>Mapping Setup</div>
            <Input
              placeholder={"Enter name here ..."}
              className={style.inputMapping}
              name={"templateName"}
              defaultValue={mappingName}
              onChange={debounce(async (e) => {
                const val = e.target.value;
                await setMappingName(val);
                try {
                  if (val) {
                    setAutoSave(true);
                    await updateMapperTemplate.callApi({
                      id: mapperId,
                      name: val,
                    });
                  } else if (!val) {
                    await updateMapperTemplate.callApi({
                      id: mapperId,
                      name: mapperId,
                    });
                    setAutoSave(true);
                  }
                } catch (error) {
                  Message.error("Something went wrong");
                }
                setTimeout(() => setAutoSave(false), 300);
              }, 500)}
            />
          </div>
          <div className={`d-flex`}>
            <Button
              color={"white"}
              loading={mapperSetupStore.isCSVHeaderUploadLoading}
              onClick={() => mapperSetupStore.downloadCSVTemplate()}
            >
              <FontAwesomeIcon
                icon={faArrowDown}
                className={`${style.downloadIcon}`}
              />
              CSV Template
            </Button>
            <Button
              className={`ms-2`}
              color={"white"}
              onClick={() => mapperSetupStore.csvHeaderRef.click()}
              loading={mapperSetupStore.isCSVHeaderUploadLoading}
              disabled={mapperSetupStore.isCSVHeaderUploadLoading}
            >
              <FontAwesomeIcon
                icon={faArrowUp}
                className={`${style.uploadIcon}`}
              />
              CSV Header
            </Button>
            <input
              className="d-none"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id={"input"}
              ref={(ref) => (mapperSetupStore.csvHeaderRef = ref)}
              value=""
              onChange={(e) => mapperSetupStore.handleUploadCSVHeader(e)}
            />
            <Button
              className={`ms-2 ${style.plusMapping}`}
              buttonLabelClass={`w-100`}
              onClick={mapperSetupStore.addTemplate}
            >
              <FontAwesomeIcon icon={faPlus} className={`${style.plusSign}`} />
              Mapping
            </Button>
          </div>
        </div>
      }
      cancelBtnOnClick={() => {
        mapperSetupFormStore.init();
        navigate(`/${MAPPER_TEMPLATE_DOMAIN}`);
      }}
      actionBtnOnClick={async () => {
        await mapperSetupStore.handleSave();
        if (mappingCsv.isSuccess) {
          navigate(`/${MAPPER_TEMPLATE_DOMAIN}`);
        }
      }}
      actionBtnProps={{
        disabled: isEmpty(mapperSetupFormStore.$("csv_mapping").value),
        loading: mappingCsv.isLoading,
      }}
      showAutoSaving={autoSave}
    >
      <>
        <div className={style.mappingList}>
          <div className={style.mappingListHeader}>
            <div className={style.csvSelectLabel}>
              CSV Field{" "}
              <Tooltip
                content={`If you are not able to view any option in this section, please upload a csv header`}
              >
                <FontAwesomeIcon className={style.info} icon={faInfoCircle} />
              </Tooltip>
            </div>
            <div className={style.databaseFieldLabel}>Database Field</div>
          </div>
          <div className={`mt-3`}>
            {mapperSetupFormStore.has("csv_mapping") &&
              mapperSetupFormStore.$("csv_mapping").map((form, index) => {
                const csv = form.$("mapper_csv_field");
                const database = form.$("score_field");
                return (
                  <div className={`${style.mappingListItem}`} key={index}>
                    <div className={style.indexNumber}>{index}</div>
                    <div className={`${style.csvSelect} pl-2`}>
                      <Select
                        options={mapperSetupStore.csvFieldList}
                        placeholder={"Select CSV"}
                        className={style.selectField}
                        onChange={(e: any) => {
                          form.update({ mapper_csv_field: e.label });
                        }}
                        value={mapperSetupStore.csvFieldList?.find(
                          (item) => item.label === csv.value
                        )}
                      />
                    </div>
                    <div className={`${style.databaseField} pl-2`}>
                      <Select
                        isCreatable={true}
                        options={mapperSetupStore.databaseFieldList}
                        placeholder={"Select Database"}
                        className={style.selectField}
                        onChange={(e: any) => {
                          form.update({ score_field: e.value });
                        }}
                        onInputChange={(e) => {
                          mapperSetupStore.setFilterText(e, index);
                          mapperSetupStore.handleSearchInputChange(e, index);
                        }}
                        inputValue={mapperSetupStore.filterText[index]}
                        value={{ label: database.value, value: database.value }}
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={style.closeButton}
                      onClick={() => mapperSetupStore.removeFromMapping(index)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </>
    </HeaderFooterContainer>
  );
});
