//Store for Landing

import { makeAutoObservable } from "mobx";
import {
  getTrackingByWorkbook,
  logApi,
  workBookCloneApi,
  workBookDeleteApi,
  workBookDetailApi,
  workBookDownloadApi,
} from "container/Landing/store/landing.api";
import {compact, debounce, find, get, map} from "lodash";
import { globalContainerStore } from "container/_globalContainerStore/globalContainerStore.store";
import { SearchLandingApi } from "container/Landing/store/landing.types";
import {format, isAfter, isBefore, isPast, parseISO, startOfToday, sub} from "date-fns";
import {getFilename, saveToCSV} from "utlis/files";

class LandingStore {
  constructor() {
    makeAutoObservable(this);
  }

  download;
  share;
  log;
  track;

  workbookId;
  workbookName;

  loading;
  search;
  selectedLevel;
  selectedClient;
  selectedCloneClient;

  downloadSelectJourney;
  downloadSelectLevel;

  setDownloadJourneyId = (ids) => {
    this.downloadSelectJourney = ids;
  };

  setWorkbookId = (workbookId, name) => {
    this.workbookId = workbookId;
    this.workbookName = name;
  };

  setDownloadLevel = (ids) => {
    this.downloadSelectLevel = ids;
  };

  resetDownloadPayloads = () => {
    this.setDownloadLevel(undefined);
    this.setDownloadJourneyId(undefined);
  };

  setLoading = (bool: boolean) => {
    this.loading = bool;
  };

  setSearch = (search: string) => {
    this.search = search;
  };

  setSelectedLevel = (id) => {
    this.selectedLevel = id;
  };

  setSelectedClient = (id) => {
    this.selectedClient = id;
  };

  setSelectedCloneClient = (id) => {
    this.selectedCloneClient = id;
  };

  //api call section
  callWorkBookApi = async () => {
    await workBookDetailApi.callApi();
  };

  callWorkBookApiWithDebounce = debounce((payload?: SearchLandingApi) => {
    workBookDetailApi.callApi(payload);
  }, 1500);

  callWorkBookDownloadApi = async (journey_id, norm_group_id) => {
    await workBookDownloadApi.callApi({ workbook_id: this.workbookId, journey_id, norm_group_id }).then((res) => {
      saveToCSV(res.data, getFilename(res))
    });
  };


  callGetTrackingByWorkbookApi = async (workbook_id) => {
    await getTrackingByWorkbook.callApi({ workbook_id });
  };

  callLogApi = async (workbook_id) => {
    await logApi.callApi({ workbook_id });
  };

  callWorkBookDeleteApi = async (workbookId) => {
    await workBookDeleteApi.callApi({ workbook_id: workbookId });
  };

  callWorkBookCloneApi = async (workbookId, cleint_id) => {
    await workBookCloneApi.callApi({ workbook_id: workbookId, client_id: cleint_id });
  };

  //get data section
  get getWorkbookDataLast7Days() {
    const data = map(workBookDetailApi.data, (item: any) => {
      const dateLast7Days = sub(startOfToday(), { days: 7 });
      if (isAfter(parseISO(item.updated_at), dateLast7Days)) {
        return item;
      }
    });
    return compact(data) || [];
  }

  get getWorkbookData() {
    return workBookDetailApi.data || [];
  }

  get getWorkbookDataAfter7Days() {
    const data = map(workBookDetailApi.data, (item: any) => {
      const dateLast7Days = sub(startOfToday(), { days: 7 });
      if (isBefore(parseISO(item.updated_at), dateLast7Days)) {
        return item;
      }
    });
    return compact(data) || [];
  }

  get getSearch() {
    return this.search;
  }

  get getTrackingWorkbookData() {
    return getTrackingByWorkbook.data || [];
  }

  get getLogData() {
    return logApi.data || [];
  }

  ownerNameBasedOnId = (id) => {
    const clientDetails = find(globalContainerStore.getClientOptionData, {
      value: id,
    });
    return get(clientDetails, "label", "-");
  };

  clientSelectedOption = (id) => {
    return find(globalContainerStore.getClientOptionData, {
      value: id,
    });
  };

  setDownload = (bool) => {
    this.download = bool;
  };

  setShare = (bool) => {
    this.share = bool;
  };

  setLog = (bool) => {
    this.log = bool;
  };

  setTrack = (bool) => {
    this.track = bool;
  };
}

export const landingStore = new LandingStore();
