// Apis For CategoryManagement
import { ApiStore } from 'stores/ApiStore';
import { RestMethods } from 'stores/baseApi';

export interface AccountApiPayload {

}

const ENDPOINT_TEMPLATE = '/api/tenant'

class AccountApi extends ApiStore<AccountApiPayload, null, null, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static loginByToken() {
    const endpoint = `${ENDPOINT_TEMPLATE}/loginByToken`;
    return new AccountApi(endpoint, { requestType: RestMethods.POST });
  }

  static impersonate() {
    const endpoint = `${ENDPOINT_TEMPLATE}/impersonate`;
    return new AccountApi(endpoint, { requestType: RestMethods.POST });
  }

  static myProfile() {
    const endpoint = `${ENDPOINT_TEMPLATE}/myProfile`;
    return new AccountApi(endpoint, { requestType: RestMethods.GET });
  }
}

export const impersonate = AccountApi.impersonate();
export const loginByToken = AccountApi.loginByToken();
export const myProfile = AccountApi.myProfile();

