// Apis For MapperSetup
import { ApiStore } from "stores/ApiStore";
import { RestMethods } from "stores/baseApi";
import {
  CSVFields,
  CSVFieldsData, CSVMapping,
  MappedCSVFieldsData,
  MapperTemplateData,
} from "container/MapperTemplate/MapperSetup/store/mapperSetup.types";

export interface MapperSetupApisPayload {}

const ENDPOINT_API = "/api";
const ENDPOINT_TEMPLATE = "/api/mapperTemplate";

class MapperSetupApis extends ApiStore<
  MapperSetupApisPayload,
  MapperTemplateData,
  null,
  null
> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static createMapperTemplateApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/createMapperTemplate`;
    return new MapperSetupApis(endpoint, { requestType: RestMethods.POST });
  }
  static updateMapperTemplate() {
    const endpoint = `${ENDPOINT_TEMPLATE}/updateMapperTemplate`;
    return new MapperSetupApis(endpoint, { requestType: RestMethods.POST });
  }
  static uploadCsvFile() {
    const endpoint = `${ENDPOINT_TEMPLATE}/uploadCsvFile`;
    return new MapperSetupApis(endpoint, { requestType: RestMethods.POST });
  }
  static mappingCsv() {
    const endpoint = `${ENDPOINT_TEMPLATE}/mappingCsv`;
    return new MapperSetupApis(endpoint, { requestType: RestMethods.POST });
  }
}

class MapperSetupCSVApis extends ApiStore<
  MapperSetupApisPayload,
  CSVFields[],
  CSVFieldsData,
  null
> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }
  static getCsvFields() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getCsvFields/:mapper_template_id`;
    return new MapperSetupCSVApis(endpoint, { requestType: RestMethods.GET });
  }
  static getFields() {
    const endpoint = `${ENDPOINT_API}/formula/getFields`;
    return new MapperSetupCSVApis(endpoint, { requestType: RestMethods.GET });
  }
}

class MapperSetupCSVFieldsApis extends ApiStore<MapperSetupApisPayload, MappedCSVFieldsData, null, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }
  static mappedCsvFields() {
    const endpoint = `${ENDPOINT_TEMPLATE}/mappedCsvFields/:mapper_template_id`;
    return new MapperSetupCSVFieldsApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }
}
export const createMapperTemplateApi =
  MapperSetupApis.createMapperTemplateApi();
export const updateMapperTemplate = MapperSetupApis.updateMapperTemplate();
export const mappedCsvFields = MapperSetupCSVFieldsApis.mappedCsvFields();
export const uploadCsvFile = MapperSetupApis.uploadCsvFile();
export const getCsvFields = MapperSetupCSVApis.getCsvFields();
export const mappingCsv = MapperSetupApis.mappingCsv();
export const getFields = MapperSetupCSVApis.getFields();
