import * as React from "react";
import style from "./index.module.scss";
import { MappingLogo } from "components/MappingLogo";

interface LoadingDataProps {
  className?: string;
  loadingText?: string;
}

export const LoadingData: React.FC<LoadingDataProps> = ({
  loadingText,
  className,
}) => {
  return (
    <div className={`${style.loadingContent} ${className}`}>
      <MappingLogo className={style.logAnimation} size={60}/>
      <div className={style.loadingText}>{loadingText || "Fetching Data ...."}</div>
    </div>
  );
};
