import * as React from 'react';
import style from './index.module.scss';

interface ErrorTextProps {
  show: boolean
  className?: string;
}

export const ErrorText: React.FC<ErrorTextProps> = ({show,children, className}) => {
  return <div className={`${style.error} ${show && style.show} ${className}`}>{children || ''}</div>;
};
