import * as React from "react";
import { DropdownItemData } from "../Dropdown/DropdownBase";
import { DropdownButton } from "../Dropdown/DropdownButton";
import { DropdownActionButton } from "../Dropdown/DropdownActionButton";
import { faThLarge } from "@fortawesome/free-solid-svg-icons";
import {DropdownProfilePhoto} from "../Dropdown/DropdownProfilePhoto";

interface DropdownDemoProps {}

export const DropdownDemo: React.FC<DropdownDemoProps> = (props) => {
  const dropdownItems: DropdownItemData[] = [
    {
      name: "Application Menu",
      mainHeader: true,
    },
    {
      name: "Upload Lookup XL",
      onClick: () => {
        alert("Hello! I am an alert box!!");
      },
    },
    {
      name: "Upload Competency Library",
      onClick: () => {
        alert("Hello! I am an alert box!!");
      },
    },
    {
      name: "Category Management",
      onClick: () => {
        alert("Hello! I am an alert box!!");
      },
    },
    {
      name: "Mapper Template",
      onClick: () => {
        alert("Hello! I am an alert box!!");
      },
    },
    {
      name: "Client Management",
      onClick: () => {
        alert("Hello! I am an alert box!!");
      },
    },
    {
      name: "Result Post Log",
      onClick: () => {
        alert("Hello! I am an alert box!!");
      },
    },
  ];

  return (
    <div className={`d-flex flex-row`}>
      <DropdownButton dropdownItem={dropdownItems}>
        Dropdown Button{" "}
      </DropdownButton>
      <div className={`ms-5`}>
        <DropdownActionButton dropdownItem={dropdownItems} />
      </div>
      <div className={`ms-5`}>
        <DropdownProfilePhoto dropdownItem={dropdownItems}/>
      </div>
    </div>
  );
};
