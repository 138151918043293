import React from "react";
import { TextOverflowWithTooltip } from "components/TextOverflowWithTooltip";
import style from "./index.module.scss";

export const columnsLookup = [
  {
    Header: "Index",
    accessor: "id",
    Cell: ({ row }) => <div className={style.w50}>{row.original.id}</div>,
  },
  {
    Header: "Framework",
    accessor: "framework",
    Cell: ({ row }) => (
      <div className={style.w100}>
        <TextOverflowWithTooltip>
          {row.original.framework || "-"}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Level",
    accessor: "level",
    Cell: ({ row }) => (
      <div className={style.w100}>
        <TextOverflowWithTooltip>
          {row.original.level || "-"}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Report Type",
    accessor: "report_type",
    disableSortBy: true,
    Cell: ({ row }) => (
      <div className={style.w100}>
        <TextOverflowWithTooltip>
          {row.original.report_type || "-"}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Reference",
    accessor: "reference",
    Cell: ({ row }) => (
      <div className={style.w100}>
        <TextOverflowWithTooltip>
          {row.original.reference}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Text Type",
    accessor: "text_type",
    disableSortBy: true,
    Cell: ({ row }) => (
      <div className={style.w100}>
        <TextOverflowWithTooltip>
          {row.original.very_low_score_text || "-"}
        </TextOverflowWithTooltip>
      </div>
    ),
  },
  {
    Header: "Score To Base On",
    accessor: "score_to_base_on",
    Cell: ({ row }) => (
      <div className={style.score_text}>
        {row.original.score_to_base_on || "-"}
      </div>
    ),
  },
  {
    Header: "Very Low Level Score",
    accessor: "very_low_score_text",
    disableSortBy: true,
    Cell: ({ row }) => (
      <div
        className={
          row.original.very_low_score_text === null
            ? style.w250
            : style.score_text
        }
      >
        {row.original.very_low_score_text || "No Score Text Available"}
      </div>
    ),
  },
  {
    Header: "Low Level Score",
    accessor: "low_score_text",
    disableSortBy: true,
    Cell: ({ row }) => (
      <div className={style.score_text}>
        {row.original.low_score_text || "No Score Text Available"}
      </div>
    ),
  },
  {
    Header: "Mid Level Score",
    accessor: "medium_score_text",
    disableSortBy: true,
    Cell: ({ row }) => (
      <div className={style.score_text}>
        {row.original.medium_score_text || "No Score Text Available"}
      </div>
    ),
  },
  {
    Header: "High  Level Score",
    accessor: "high_score_text",
    disableSortBy: true,
    Cell: ({ row }) => (
      <div className={style.score_text}>
        {row.original.high_score_text || "No Score Text Available"}
      </div>
    ),
  },
  {
    Header: "Very High Level Score",
    accessor: "very_high_score_text",
    disableSortBy: true,
    Cell: ({ row }) => (
      <div
        className={
          row.original.very_high_score_text === null
            ? style.w250
            : style.score_text
        }
      >
        {row.original.very_high_score_text || "No Score Text Available"}
      </div>
    ),
  },
];
