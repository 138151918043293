import { useEffect, useState } from 'react';
import * as _ from 'lodash';

export const useShowAsterisk = (value: any) => {
    const [showAsterisk, setShowAsterisk] = useState<boolean>(true);

    useEffect(() => {
        setShowAsterisk(_.isEmpty(value));
    }, [value]);

    return showAsterisk;
};

export const checkRequiredByRules = (rules: string) => {
    return rules && rules.split('|').indexOf('required') !== -1;
};
