import * as React from "react";
import style from "./index.module.scss";
import Editor, { useMonaco } from "@monaco-editor/react";
import { last, map, split } from "lodash";
import { workbookFormulaMapping } from "container/Workbook/WorkbookConfiguration/store/workbookFormulaMapping.store";
import { useEffect, useState } from "react";
import { IDisposable, languages } from "monaco-editor";
import { ModalProps } from "components/Modal";
import { workbookFormulaFormStore } from "container/Workbook/WorkbookConfiguration/store/workbookFormula.formStore";
import ProviderResult = languages.ProviderResult;
import { useEffectAsync } from "utlis/hooks";

interface MonocoEditorProps extends ModalProps {}

export const MonocoEditor: React.FC<MonocoEditorProps> = ({ isOpen }) => {
  const formula = workbookFormulaFormStore.$("formula");
  const [completionDisposable, setCompletionDisposable] =
    useState<IDisposable>();

  useEffect(() => completionDisposable?.dispose, [completionDisposable]);

  const onChange = (newValue, e) => {
    formula.set("value", newValue);

    const searchWord = last(
      split(newValue, /(-?\d+(?:\.\d+)?|sqrt?|[^\w\s]|[*/+-])/g)
    );
    workbookFormulaMapping.callSearchFieldsApiDebounce(searchWord || "");
  };

  const editorWillMount = (monaco: any) => {
    setCompletionDisposable(
      monaco?.languages?.registerCompletionItemProvider("plaintext", {
        provideCompletionItems(
          model,
          position,
          context,
          token
        ): ProviderResult<any> {
          const completionFields = map(
            workbookFormulaMapping.getSearchData,
            (field) => {
              return {
                label: field,
                kind: languages.CompletionItemKind.Text,
                insertText: field,
              };
            }
          );

          return {
            suggestions: [...completionFields],
          };
        },
      })
    );
  };

  useEffectAsync(() => {
    if (workbookFormulaMapping.editFormulaDetails?.id) {
      formula.set("value", workbookFormulaMapping.editFormulaDetails.formula);
    }
  }, [workbookFormulaMapping.editFormulaDetails]);

  return (
    <Editor
      height={400}
      defaultLanguage="plaintext"
      beforeMount={editorWillMount}
      options={{
        minimap: {
          enabled: false,
        },
        wordWrap: "wordWrapColumn",
        inlineSuggest: {
          enabled: true,
        },
        autoClosingBrackets: "never",
        suggest: {
          showStatusBar: true,
          previewMode: "prefix",
        },
      }}
      onChange={onChange}
      value={formula.value}
      className={style.editor}
    />
  );
};
