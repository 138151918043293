import * as React from 'react';
import style from './dropdownButton.module..scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DropdownBase, DropdownBaseProps } from '../Dropdown/DropdownBase';
import { Button, IButtonProps } from '../Button';

interface DropdownButtonProps extends DropdownBaseProps {
  buttonProps?: IButtonProps;
  color?: "primary" | "transparent" | "white";
  chevronClassName?: string
}

export const DropdownButton: React.FC<DropdownButtonProps> = props => {
  return (
    <div className={style.dropdownButton}>
      <DropdownBase {...props}>
        <Button color={props.color} className={style.buttonStyle} {...props.buttonProps}>
          {props.children}
          <FontAwesomeIcon icon={faChevronDown} className={`ms-2 ${style.chevronDownIcon} ${props.chevronClassName}`} />
        </Button>
      </DropdownBase>
    </div>
  );
};
