import * as React from "react";
import style from "./index.module.scss";
import { Button } from "./Button";
import { Modal } from ".//Modal";
import { Input } from "./Input";
import { CheckBox } from "./CheckBox";
import { SelectBasicDemo, SelectCheckBoxDemo } from "./Select/SelectDemo";
import { DropdownDemo } from "./Dropdown/DropdownDemo";
import { ToggleSwitch } from "./ToggleSwitch";
import { FormulaContainer } from "../container/Workbook/WorkbookConfiguration/component/FormulaBox/DragableComponent";
import { Select } from "./Select";
import {
  dummyData,
  mockColumns,
  mockColumnsLog,
  mockColumnsTrack,
  mockData,
  mockDataLog,
  mockDataTrack,
} from "./TableWithVirtualization/mock";
import { PageTitle } from "./PageTitle";
import { TableWithVirtualization } from "./TableWithVirtualization";
import { AccordionTree } from "../container/Workbook/WorkbookConfiguration/component/AccordionTree";
import { UserProfile } from "./UserProfile";
import { Header } from "../container/Header";
import { SearchInput } from "components/SearchInput";
import { ReviewTableBreakdown } from "components/ReviewTableBreakdown";
import Editor from "@monaco-editor/react";
import { last, map, split } from "lodash";
import { languages } from "monaco-editor";
import ProviderResult = languages.ProviderResult;
import { LoadingData } from "components/Loading";
import { workbookFormulaMapping } from "container/Workbook/WorkbookConfiguration/store/workbookFormulaMapping.store";

interface ComponentProps {}

export const formulaData = [
  {
    id: 2,
    formula_code: 324,
    formula_name: "Overall Score",
    formula: "(2 + 4)",
  },
  {
    id: 1,
    formula_code: 323,
    formula_name: "Overall Score of leadership",
    formula: "(2 + 4) * (10 + 15)",
  },
  {
    id: 3,
    formula_code: 323,
    formula_name: "Overall Score of leadership 2",
    formula: "(2 + 4) * (10 + 15)",
  },
  {
    id: 4,
    formula_code: 323,
    formula_name: "Overall Score of leadership 3",
    formula: "(2 + 4) * (10 + 15)",
  },
  {
    id: 5,
    formula_code: 323,
    formula_name: "Overall Score of leadership 4",
    formula: "(2 + 4) * (10 + 15)",
  },
];

export const accordionData = [
  {
    key: "0",
    title: "node 0",
    children: [
      { key: "0-0", title: "node 0-0" },
      { key: "0-1", title: "node 0-1" },
      {
        key: "0-2",
        title: "node 0-2",
        children: [
          { key: "0-2-0", title: "node 0-2-0" },
          { key: "0-2-1", title: "node 0-2-1" },
          { key: "0-2-2", title: "node 0-2-2" },
        ],
      },
      { key: "0-3", title: "node 0-3" },
      { key: "0-4", title: "node 0-4" },
      { key: "0-5", title: "node 0-5" },
      { key: "0-6", title: "node 0-6" },
      { key: "0-7", title: "node 0-7" },
      { key: "0-8", title: "node 0-8" },
      {
        key: "0-9",
        title: "node 0-9",
        children: [
          { key: "0-9-0", title: "node 0-9-0" },
          {
            key: "0-9-1",
            title: "node 0-9-1",
            children: [
              { key: "0-9-1-0", title: "node 0-9-1-0" },
              { key: "0-9-1-1", title: "node 0-9-1-1" },
              { key: "0-9-1-2", title: "node 0-9-1-2" },
              { key: "0-9-1-3", title: "node 0-9-1-3" },
              { key: "0-9-1-4", title: "node 0-9-1-4" },
            ],
          },
          {
            key: "0-9-2",
            title: "node 0-9-2",
            children: [
              { key: "0-9-2-0", title: "node 0-9-2-0" },
              { key: "0-9-2-1", title: "node 0-9-2-1" },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "1",
    title: "node 1",
    // children: new Array(1000)
    //   .fill(null)
    //   .map((_, index) => ({ title: `auto ${index}`, key: `auto-${index}` })),
    children: [
      {
        key: "1-0",
        title: "node 1-0",
        children: [
          { key: "1-0-0", title: "node 1-0-0" },
          {
            key: "1-0-1",
            title: "node 1-0-1",
            children: [
              { key: "1-0-1-0", title: "node 1-0-1-0" },
              { key: "1-0-1-1", title: "node 1-0-1-1" },
            ],
          },
          { key: "1-0-2", title: "node 1-0-2" },
        ],
      },
    ],
  },
];

export const dummyColumn = [
  {
    Header: "Mapping Category",
    accessor: "category",
    Cell: ({ row }) => (
      <div className={`d-flex`}>
        <div
          className={`${style.indicator} ${
            style[`indicatorOf${row.original.level}`]
          }`}
        />
        <div>{row.original.category}</div>
      </div>
    ),
  },
  {
    Header: "Formula Name",
    accessor: "formulaName",
  },
  {
    Header: "Formula",
    accessor: "formula",
    Cell: ({ row }) => (
      <div className={`d-flex text-break`}>{row.original.formula}</div>
    ),
  },
];

export const Component: React.FC<ComponentProps> = ({}) => {
  const [modal, setModal] = React.useState(false);
  const [download, setDownload] = React.useState(false);
  const [share, setShare] = React.useState(false);
  const [log, setLog] = React.useState(false);
  const [track, setTrack] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);
  const [formula, setFormula] = React.useState("");

  const onChange = (newValue, e) => {
    setFormula(newValue);
    const searchWord = last(split(newValue, "+"));
    workbookFormulaMapping.callSearchFieldsApiDebounce(searchWord || "");
  };

  const editorWillMount = (monaco) => {
    monaco?.languages.registerCompletionItemProvider("plaintext", {
      provideCompletionItems(
        model,
        position,
        context,
        token
      ): ProviderResult<any> {
        // a simple completion item which inserts `Hello World!`

        const completionFields = map(
          workbookFormulaMapping.getSearchData,
          (field) => {
            return {
              label: field,
              kind: languages.CompletionItemKind.Text,
              insertText: field,
            };
          }
        );

        return {
          suggestions: [...completionFields],
        };
      },
    });
  };

  const onToggleChange = (checked) => {
    setToggle(checked);
  };
  return (
    <div className={style.container}>
      <PageTitle
        pageTitle={`Mapping Engine`}
        subTitle={"What can you do with this mapping engine web service?"}
      />
      <div className={`d-flex mb-3`}>
        <Button onClick={() => setModal(!modal)}>Open Modal</Button>
        <Button color={"transparent"} onClick={() => setModal(!modal)}>
          Open Modal
        </Button>
        <Button disabled={true} onClick={() => setModal(!modal)}>
          Open Modal
        </Button>
      </div>
      <Input />
      <Modal
        toggle={() => setModal(!modal)}
        isOpen={modal}
        showActionBtn={true}
      />
      <CheckBox label={"Check Box Component"} />
      <SelectBasicDemo />
      <SelectCheckBoxDemo />
      <DropdownDemo />
      <div className={`w-25`}>
        <Modal
          toggle={() => setDownload(!download)}
          isOpen={download}
          showActionBtn={true}
          headerTitle={"Download - Coca-Cola Senior Manager Workbook"}
          actionBtnLabel={"Download"}
          cancelBtnLabel={"Cancel"}
        >
          <div className={style.downloadModal}>
            <div className={style.modalTop}>Show Projects</div>
            <div>
              <Select label={"Please select journey"} />
            </div>
            <div>
              <Select label={"Please select level"} />
            </div>
          </div>
        </Modal>
        <Modal
          toggle={() => setShare(!share)}
          isOpen={share}
          showActionBtn={true}
          headerTitle={"Share - Coca-Cola Senior Manager Workbook"}
          actionBtnLabel={"Share"}
          cancelBtnLabel={"Cancel"}
        >
          <div className={style.downloadModal}>
            <div className={style.modalTop}>Share Workbook to..</div>
            <div>
              <Input
                label={"Please enter email address"}
                placeholder={"user@accendo.com.my"}
              />
            </div>
          </div>
        </Modal>
        <Modal
          toggle={() => setLog(!log)}
          isOpen={log}
          showActionBtn={true}
          headerTitle={"View Log - Coca-Cola Senior Manager Workbook"}
          actionBtnLabel={"Export"}
          cancelBtnLabel={"Cancel"}
          modalSize={style.modalSize}
        >
          <div className={style.downloadModal}>
            <div className={style.modalTop}>View Log History</div>
            <div className={style.tableHeight}>
              <TableWithVirtualization
                columns={mockColumnsLog}
                data={mockDataLog}
              />
            </div>
          </div>
        </Modal>
        <Modal
          toggle={() => setTrack(!track)}
          isOpen={track}
          showActionBtn={true}
          headerTitle={"Track Finalize - Coca-Cola Senior Manager Workbook"}
          actionBtnLabel={"Export"}
          cancelBtnLabel={"Cancel"}
          modalSize={style.modalSize}
        >
          <div className={style.downloadModal}>
            <div className={style.modalTop}>View Finalize Track Status</div>
            <div className={style.tableHeight}>
              <TableWithVirtualization
                columns={mockColumnsTrack}
                data={mockDataTrack}
              />
            </div>
          </div>
        </Modal>
        <ToggleSwitch
          id="toggle"
          checked={toggle}
          onChange={onToggleChange}
          labelText={"Show in Excel:"}
        />
        <Button onClick={() => setDownload(!download)}>Download Scores</Button>
        <Button onClick={() => setShare(!share)}>Share</Button>
        <Button onClick={() => setLog(!log)}>View Log</Button>
        <Button onClick={() => setTrack(!track)}>Track Finalize</Button>
      </div>
      <UserProfile name={"Super Admin"} />
      <Header />
      <AccordionTree prefixCls={"rc-tree"} data={accordionData} />
      <div className={style.tableHeight}>
        {" "}
        <TableWithVirtualization columns={mockColumns} data={mockData} />
      </div>
      <SearchInput />
      <ReviewTableBreakdown
        stickyHeader={true}
        tableClassName={style.reviewTable}
        columns={dummyColumn}
        data={dummyData}
      />
      <Editor
        height={400}
        defaultLanguage="plaintext"
        options={{
          minimap: {
            enabled: false,
          },
        }}
        onChange={onChange}
        beforeMount={editorWillMount}
      />
      <LoadingData />
    </div>
  );
};
