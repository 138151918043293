// Apis For ResultPostLog
import { ApiStore } from 'stores/ApiStore';
import { RestMethods } from 'stores/baseApi';

export interface ResultPostLogApisPayload {
  search?: string;
  page?: string | number;

}

const ENDPOINT_TEMPLATE = '/api/resultPostLog'

class ResultPostLogApis extends ApiStore<ResultPostLogApisPayload, null, null, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static getResultPostLog() {
    const endpoint = `${ENDPOINT_TEMPLATE}`;
    return new ResultPostLogApis(endpoint, { requestType: RestMethods.GET });
  }
}

export const getResultPostLog = ResultPostLogApis.getResultPostLog();

