import * as React from "react";
import * as style from "./confirmationBox.module.scss";
import { useState } from "react";
import ReactDOM from "react-dom";
import { Modal, ModalProps } from "components/Modal";

interface ConfirmationBoxProps extends Partial<ModalProps> {
  confirmBtnOnClick?: () => void;
  cancelBtnOnClick?: () => void;
  confirmBtnText?: string;
  modalHeaderText?: string;
}

export const ConfirmationBoxComponent: React.FC<ConfirmationBoxProps> = (
  props
) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const {
    confirmBtnOnClick,
    cancelBtnOnClick,
    confirmBtnText = "Confirm",
    modalHeaderText = "Confirmation",
    ...otherProps
  } = props;

  const onClickClose = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      cancelBtnOnClick();
    }, 300);
  };

  const onClickConfirm = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      confirmBtnOnClick();
    }, 300);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClickClose}
      headerTitle={modalHeaderText}
      actionBtnLabel={confirmBtnText}
      actionBtnOnClick={onClickConfirm}
      cancelBtnLabel="Cancel"
      {...otherProps}
    >
      {props.children}
    </Modal>
  );
};

const willUnMountElement = (divElement) => {
  ReactDOM.unmountComponentAtNode(divElement);
  document.body.removeChild(divElement);
};

const alert = (
  content: string | React.ReactNode | Element,
  config?: ConfirmationBoxProps
) => {
  const div = document.createElement("div");

  return new Promise((resolve, reject) => {
    document.body.appendChild(div);

    ReactDOM.render(
      <ConfirmationBoxComponent
        confirmBtnOnClick={() => {
          willUnMountElement(div);
          // resolve();
        }}
        cancelBtnOnClick={() => {
          willUnMountElement(div);
          reject(`Cancel`);
        }}
        {...config}
      >
        {content}
      </ConfirmationBoxComponent>,
      div
    );
  });
};

export const ConfirmationBox = {
  alert,
};
