/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-21
 */

// screen file for WorkbookConfiguration
import React from "react";
import style from "./index.module.scss";
import { WorkbookConfigurationProps } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.types";
import { WORKBOOK_DOMAIN } from "constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { HeaderFooterContainer } from "components/HeaderFooterContainer";
import { useNavigate, useParams } from "react-router-dom";
import { HeaderWorkbook } from "container/Workbook/HeaderWorkbook";
import { AccordionTree } from "container/Workbook/WorkbookConfiguration/component/AccordionTree";
import { Button } from "components/Button";
import { Title } from "components/Title";
import { workbookConfigurationStore } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.store";
import { observer } from "mobx-react";
import { useEffectAsync } from "utlis/hooks";
import { MappingDetails } from "container/Workbook/WorkbookConfiguration/component/MappingDetails";
import { CreateEditCategoryModal } from "container/CategoryManagement/CategoryManagementModals";
import { authStore } from "stores/AuthStore";

export const WorkbookConfiguration: React.FC<WorkbookConfigurationProps> =
  observer(({}) => {
    const [draggable, setDraggable] = React.useState(false);

    let navigate = useNavigate();
    const { workbookId } = useParams();
    useEffectAsync(async () => {
      workbookConfigurationStore.setNewBranch(false);
      workbookConfigurationStore.setSelectedCategoryId(undefined);
      await Promise.all([
        workbookConfigurationStore.callGetFormulaTreeApi(workbookId),
        workbookConfigurationStore.callGetCategoriesApi(),
      ]);
      workbookConfigurationStore.setWorkbookId(workbookId);
    }, [authStore.getClientIdToken]);

    return (
      <HeaderFooterContainer
        header={<HeaderWorkbook />}
        cancelBtnOnClick={() =>
          navigate(`/${WORKBOOK_DOMAIN}/create/${workbookId}`)
        }
        actionBtnOnClick={() =>
          navigate(`/${WORKBOOK_DOMAIN}/review/${workbookId}`)
        }
        actionBtnLabel={"Next"}
        extraBtnOnClick={() =>
          navigate(`/${WORKBOOK_DOMAIN}/review/${workbookId}`)
        }
        showExtraButton={true}
      >
        <div className={style.workbookConfigureContainer}>
          <div className={style.configureContainer}>
            <div
              className={`d-flex align-items-center justify-content-between mb-3`}
            >
              <Title showScore={false}>Configure Mapping</Title>
              <div>
                <Button
                  color={draggable ? "transparentPrimary" : "transparent"}
                  className={`me-2`}
                  onClick={() => setDraggable(!draggable)}
                >
                  {draggable ? `Disable Re-order` : `Re-order`}
                </Button>
                <Button onClick={workbookConfigurationStore.addNewParentBranch}>
                  <FontAwesomeIcon icon={faPlus} /> Branch
                </Button>
              </div>
            </div>
            <div className={style.accordionContainer}>
              <AccordionTree
                prefixCls={"rc-tree"}
                data={workbookConfigurationStore.getFormulaTreeData}
                draggable={draggable}
              />
            </div>
          </div>
          <div className={style.mappingContainer}>
            <Title className={`mb-3`} showScore={false}>
              Mapping Details
            </Title>
            <MappingDetails />
          </div>
        </div>
        <CreateEditCategoryModal
          fromWorkbookConfiguration={true}
          isOpen={workbookConfigurationStore.createEditModalIsOpen}
          toggle={() =>
            workbookConfigurationStore.setCreateEditModalIsOpen(
              !workbookConfigurationStore.createEditModalIsOpen
            )
          }
        />
      </HeaderFooterContainer>
    );
  });
