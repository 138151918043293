import { FormStore } from "stores/FormStore";
import { Message } from "components/Message";
import {
  createMapperTemplateApi,
  mappedCsvFields,
  mappingCsv,
} from "container/MapperTemplate/MapperSetup/store/mapperSetup.api";
import { mapperSetupStore } from "container/MapperTemplate/MapperSetup/store/mapperSetup.store";
interface CSVMapping {
  mapper_csv_field: string;
  score_field: string;
}

interface MapperSetupFormStore {
  csv_mapping: CSVMapping[];
}

export const mapperSetupFormStore = new FormStore<MapperSetupFormStore>(
  ["csv_mapping[].mapper_csv_field", "csv_mapping[].score_field"],
  {
    options: {
      validateOnChange: true,
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const value = form.values();
        await mappingCsv.callApi({
          id: mapperSetupStore.mapperId,
          ...value,
        });
        await mappedCsvFields.callApi({
          mapper_template_id: mapperSetupStore.mapperId,
        });
        Message.success(`CSV template has been saved Successfully`);
        return Promise.resolve();
      },
      onError: (form) => {
        Message.error("Incorrect form information");
        return Promise.reject(`Incorrect form information`);
      },
    },
  }
);
