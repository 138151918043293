//Store for ViewDataset

import { makeAutoObservable } from "mobx";
import { getJourneys } from "container/Workbook/ReviewMapping/store/reviewMapping.api";
import {
  finalizeWorkbook,
  getCalculatedData,
  getWorkbookFields,
  setWorkbookFields,
} from "container/Workbook/ViewDataset/store/viewDataset.api";
import {
  FinalizeWorkbookPayload,
  GetCalculatedDataPayload,
  GetWorkbookFieldsPayload,
  SetWorkbookFieldsPayload,
} from "container/Workbook/ViewDataset/store/viewDataset.types";
import { debounce, find, keys, map, mapKeys } from "lodash";
import { setScoreOptions } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.api";
import { workbookConfigurationStore } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.store";

class ViewDatasetStore {
  constructor() {
    makeAutoObservable(this);
  }

  filter = false;
  filter_ids = [];
  selectJourney;
  finalizeJourney;
  journeyName;

  setFilter = (bool) => {
    this.filter = bool;
  };

  setFilterIds = (id) => {
    this.filter_ids = [...this.filter_ids, id];
  };

  setFinalizeJourney = (ids) => {
    this.finalizeJourney = ids;
  };

  setJourneyId = (ids) => {
    this.selectJourney = ids;
    this.journeyName = ids?.label;
  };

  setSelectedJourneyName = (journey_id) => {
    const data = find(getJourneys.data, (item: any) => {
      return item.id.toString() === journey_id;
    });
    this.setJourneyId({ label: data?.name, value: data?.id });
    this.setFinalizeJourney([{ label: data?.name, value: data?.id }]);
    this.journeyName = data?.name;
  };

  clearFields = () => {
    this.filter = false;
    this.filter_ids = [];
    this.selectJourney = null;
    this.journeyName = null;
  }

  removeFilterIds = (id) => {
    this.filter_ids = this.filter_ids.filter((x) => x != id);
  };

  callGetCalculatedData = async (payload: GetCalculatedDataPayload) => {
    await getCalculatedData.callApi(payload);
  };

  callFinalizeWorkbook = async (payload: FinalizeWorkbookPayload) => {
    await finalizeWorkbook.callApi(payload);
  };

  callGetWorkbookFields = debounce((payload: GetWorkbookFieldsPayload) => {
    getWorkbookFields.callApi(payload);
  }, 1500);

  callSetWorkbookFields = async (payload: SetWorkbookFieldsPayload) => {
    await setWorkbookFields.callApi(payload);
  };

  get getAdditionalFields() {
    const data = map(getCalculatedData.data, (item: any) => {
      return keys(item.fields);
    });

    const mergeStringData = data.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );
    return mergeStringData.filter((v, i) => mergeStringData.indexOf(v) === i);
  }

  get getCalculatedData() {
    return map(getCalculatedData.data, (item: any) => ({
      name: item.user.name,
      email: item.user.email,
      ...item.fields,
    }));
  }

  get getWorkbookFields() {
    return getWorkbookFields.data || [];
  }

  get getSelectedJourneyName() {
    return this.journeyName;
  }
}

export const viewDatasetStore = new ViewDatasetStore();
