import * as React from 'react';
import style from './index.module.scss';
import {SelectBoxWithCheckbox} from "./SelectBoxWithCheckbox";
import {Select} from "./index";

interface SelectExampleProps {}

export const SelectCheckBoxDemo: React.FC<SelectExampleProps> = props => {
  return (
    <SelectBoxWithCheckbox
      label={'Select with Grouped Options'}
      options={groupedOptions as any}
    />
  );
};

export const SelectBasicDemo: React.FC<SelectExampleProps> = props => {
  return (
      <Select
          label={'Select Basic'}
          options={groupedOptions as any}
      />
  );
};

const colourOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9' },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' }
];

const flavourOptions = [
  { value: 'vanilla', label: 'Vanilla', rating: 'safe' },
  { value: 'chocolate', label: 'Chocolate', rating: 'good' },
  { value: 'strawberry', label: 'Strawberry', rating: 'wild' },
  { value: 'salted-caramel', label: 'Salted Caramel', rating: 'crazy' }
];

const groupedOptions = [
  {
    label: 'Colours',
    options: colourOptions
  },
  {
    label: 'Flavours',
    options: flavourOptions
  }
];
