// Apis For CategoryManagement
import { ApiStore } from "stores/ApiStore";
import { RestMethods } from "stores/baseApi";
import {
  CategoryWorkbooksData,
  Errors,
} from "container/CategoryManagement/store/categoryManagement.types";

export interface CategoryManagementApisPayload {}
export interface CategoryApisPayload {
  search?: string;
  type?: string;
  page?: number;
  sort_by?: string;
  sort_order?: "ASC" | "DESC";
}

const ENDPOINT_TEMPLATE = "/api/categories";

class CategoryManagementApis extends ApiStore<
  CategoryManagementApisPayload,
  null,
  Errors,
  null
> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static editCategoriesApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:category_id/edit`;
    return new CategoryManagementApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }
  static createCategoriesApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/create`;
    return new CategoryManagementApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }
  static deleteCategoriesApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:categories_id/delete`;
    return new CategoryManagementApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }
  static cloneCategoriesApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:categories_id/clone`;
    return new CategoryManagementApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }
}
class CategoryWorkbooksApis extends ApiStore<
  CategoryManagementApisPayload,
  CategoryWorkbooksData,
  null,
  null
> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }
  static getCategoryWorkbooks() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:categories_id/getCategoryWorkbooks`;
    return new CategoryWorkbooksApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }
}
class CategoryApis extends ApiStore<CategoryApisPayload, null, null, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }
  static getCategoriesApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getCategoryList`;
    return new CategoryApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }
}

export const getCategoriesApi = CategoryApis.getCategoriesApi();
export const editCategoriesApi = CategoryManagementApis.editCategoriesApi();
export const createCategoriesApi = CategoryManagementApis.createCategoriesApi();
export const deleteCategoriesApi = CategoryManagementApis.deleteCategoriesApi();
export const cloneCategoriesApi = CategoryManagementApis.cloneCategoriesApi();
export const getCategoryWorkbooks =
  CategoryWorkbooksApis.getCategoryWorkbooks();
