import * as React from "react";
import style from "./dropdownBase.module.scss";
import {
  Dropdown,
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownMenuProps,
  DropdownProps,
  DropdownToggle,
  DropdownToggleProps,
} from "reactstrap";
import { ReactNode, useState } from "react";
import * as _ from "lodash";
import { Link } from "react-router-dom";

export interface DropdownItemData extends DropdownItemProps {
  name?: Element | ReactNode | string;
  to?: string;
  className?: string;
}

export interface DropdownBaseProps<T = DropdownItemData[]>
  extends DropdownMenuProps {
  dropdownItem: T;
  dropdownToggleProps?: DropdownToggleProps;
  dropdownProps?: DropdownProps;
  additionalContent?: Element | ReactNode | string;
  renderMenuItems?: (items: T) => Element | ReactNode | Element[] | ReactNode[];
  className?: string;
  dropDownClassName?: string;
  enableScroll?: boolean;
}

export const DropdownBase: React.FC<DropdownBaseProps> = ({
  dropdownItem,
  className,
  additionalContent,
  renderMenuItems,
  dropDownClassName,
  dropdownProps,
  dropdownToggleProps,
  children,
  enableScroll = false,
  ...otherProps
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [dropdownMenuContainerRef, setDropdownMenuContainerRef] =
    React.useState<any>();
  const [stateStyle, setStateStyle] = React.useState({});

  const toggleDropdownIsOpen = () => {
    setDropdownIsOpen(!dropdownIsOpen);
    const dropdownHeightInTermsOfItemsNumber = dropdownItem.length * 50;
    const dropdownAttitude =
      dropdownMenuContainerRef.getBoundingClientRect().top +
      dropdownHeightInTermsOfItemsNumber + 300;
    const overflow = dropdownAttitude > window.innerHeight;
    if (overflow && enableScroll) {
      setStateStyle({
        height: dropdownHeightInTermsOfItemsNumber * 0.5,
        overflowY: "scroll",
      });
    } else {
      setStateStyle({});
    }
  };

  const renderDropdownItems = () => {
    if (_.isEmpty(dropdownItem)) {
      return <DropdownItem disabled>{"No Data Available"}</DropdownItem>;
    } else {
      return _.map(
        dropdownItem,
        ({ name, to, mainHeader, ...itemProps }, i: number) => {
          if (mainHeader) {
            return (
              <DropdownItem
                key={i}
                className={`${style.mainHeader} ${i === 0 && style.first} ${
                  dropdownItem.length - 1 === i && style.last
                }`}
                {...itemProps}
              >
                {name}
              </DropdownItem>
            );
          } else if (to) {
            return (
              <Link
                key={i}
                tabIndex={0}
                to={to}
                className={style.dropdownItemLink}
              >
                <DropdownItem
                  className={`${i === 0 && style.first} ${
                    dropdownItem.length - 1 === i && style.last
                  }`}
                  {...itemProps}
                >
                  {itemProps.children || name}
                </DropdownItem>
              </Link>
            );
          } else {
            return (
              <DropdownItem
                className={`${i === 0 && style.first} ${
                  dropdownItem.length - 1 === i && style.last
                }`}
                {...itemProps}
                key={i}
              >
                {name}
              </DropdownItem>
            );
          }
        }
      );
    }
  };

  return (
    <div className={`${dropDownClassName} ${style.dropdownUnique}`}>
      <Dropdown
        isOpen={dropdownIsOpen}
        toggle={toggleDropdownIsOpen}
        {...dropdownProps}
      >
        {additionalContent}
        <DropdownToggle tag={"div"} {...dropdownToggleProps}>
          <div className={className}>{children}</div>
        </DropdownToggle>
        <div ref={(r) => setDropdownMenuContainerRef(r)}>
          <DropdownMenu {...otherProps} style={stateStyle}>
            {renderMenuItems
              ? renderMenuItems(dropdownItem)
              : renderDropdownItems()}
          </DropdownMenu>
        </div>
      </Dropdown>
    </div>
  );
};
