import React from "react";
import { DropdownActionButton } from "components/Dropdown/DropdownActionButton";
import { isNil } from "lodash";
import { format, parseISO } from "date-fns";
import { resultPostLogStore } from "container/ResultPostLog/store/resultPostLog.store";

const dropDownItems = (data) => [
  {
    name: "View Data",
    onClick: async () => {
      resultPostLogStore.setSelectedData(data);
      resultPostLogStore.setOpenModal(true);
    },
  },
];

export const ColumnsResult = [
  {
    Header: "Si No.",
    accessor: "id",
    width: 150,
    minWidth: 100,
    Cell: ({ row }) => <div>{row.index + 1}</div>,
  },
  {
    Header: "Project Name",
    accessor: "journey.name",
    width: 550,
    minWidth: 400,
  },
  {
    Header: "Post Date",
    accessor: "created_at",
    width: 450,
    minWidth: 400,
    Cell: ({ row }) => (
      <div>
        {isNil(row.original.created_at)
          ? "-"
          : format(parseISO(row.original.created_at), "PPpp")}
      </div>
    ),
  },
  {
    Header: "Action",
    id: "action",
    disableSortBy: true,
    width: 90,
    minWidth: 70,
    Cell: ({ row }) => (
      <div>
        <DropdownActionButton dropdownItem={dropDownItems(row.original)} />
      </div>
    ),
  },
];
