import { AxiosError, AxiosInstance, AxiosResponse, ResponseType } from "axios";
import { generatePath } from "react-router";
import {
  action,
  computed,
  makeAutoObservable,
  makeObservable,
  observable,
} from "mobx";
import * as qs from "qs";
import * as _ from "lodash";

import { authStore } from "./AuthStore";
import {
  BackendErrorResponse,
  BackendMeta,
  BackendSuccessResponse,
} from "../_declare/api";
import { ApiStoreCreate, RestMethods } from "./baseApi";
import { isNil } from "lodash";

interface ApiStoreConfig {
  requestType?: RestMethods;
  initialData?: any;
  responseType?: ResponseType;
  defaultPayload?: any;
}

interface ApiCallConfig {
  queryString?: {};
  silentLoading?: boolean;
}

interface AxiosErrorGeneric extends AxiosError {
  response?: AxiosResponse;
}

export class ApiStore<
  // Types of payloads that needs to be sent in response
  Payload = any,
  // Types of data that obtained from Axios response in response.data.data
  Data = any,
  SuccessResponse = BackendSuccessResponse<Data>,
  FailureResponse = BackendErrorResponse,
  Meta = BackendMeta
> {
  protected readonly api: AxiosInstance;
  protected readonly requestType: RestMethods;
  protected readonly defaultPayload: {};
  protected readonly urlTemplate: string;
  protected readonly config: ApiStoreConfig;

  constructor(
    urlTemplate: string,
    config: ApiStoreConfig = {
      requestType: RestMethods.GET,
      initialData: [],
    }
  ) {
    this.api = ApiStoreCreate({
      params: {
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        ...config,
      },
      interceptorRequestOnFulFilled: (config) => {
        const token = authStore.getToken;
        const client_id = authStore.getClient;
        config.headers.Authorization = `Bearer ${token}`;
        config.headers["X-client"] = client_id;
        return config;
      },
    });
    this.urlTemplate = urlTemplate;
    this.requestType = config.requestType;
    this.defaultPayload = config.defaultPayload;
    this.config = config;
    makeObservable(this);
  }

  @observable successResponse;
  @observable failureResponse;

  @observable isCalled: boolean = false;
  @observable isLoading;
  @observable isSuccess;
  @observable isFailure;
  @observable axiosResponse: AxiosResponse;

  @observable lastSuccessPayload;

  // Getter method to get data
  @computed
  get data() {
    return _.get(this.successResponse, "data", this.config.initialData) as Data;
  }

  // Getter method to get meta
  @computed
  get meta() {
    return _.get(this.successResponse, "meta") as Meta;
  }

  @computed
  get hasList() {
    return !_.isEmpty(this.data);
  }

  @action
  setAxiosResponse = (response: AxiosResponse) => {
    this.axiosResponse = response;
  };

  @action
  setIsLoading = (bool: boolean) => {
    this.isCalled = true;
    this.isLoading = bool;
  };

  @action
  setLastSuccessPayload = (payload: Payload) => {
    this.lastSuccessPayload = payload;
  };

  callApi = async (
    payload?: Payload,
    allowToPass?: boolean,
    config?: ApiCallConfig
  ) => {
    try {
      if (!isNil(authStore.getClientIdToken || allowToPass)) {
        if (_.get(config, "silentLoading", false) === false) {
          this.setIsLoading(true);
        }
        const response = await this.request(
          payload,
          _.get(config, "queryString", "")
        );

        this.setAxiosResponse(response);
        this.apiCallSucceed(response.data);
        this.setLastSuccessPayload(payload);

        return response;
      }
    } catch (e) {
      console.error("Error calling Api");
      console.error("e: ", e);
      const err = e as AxiosErrorGeneric;
      if (!err.response) {
        // This isn't an axios error, throwing it out. Something else should handle it.
        throw e;
      }
      this.setAxiosResponse(err.response);
      this.apiCallFailed(err.response.data);
      if (err.response.status === 401) {
        window.location.assign(`${process.env.REACT_APP_FRONT_END_URL}/`);
      }
      throw err;
    }
  };

  callApiIfEmpty = async (payload?: Payload, config?: ApiCallConfig) => {
    if (_.isEmpty(this.data)) {
      return await this.callApi(payload, false, config);
    }

    return Promise.resolve(this.data);
  };

  callApiWithPreviousPayload = async (config?: ApiCallConfig) => {
    if (this.lastSuccessPayload) {
      return await this.callApi(this.lastSuccessPayload, false, config);
    } else {
      return Promise.reject(`No previous payload found.`);
    }
  };

  callApiWithFormData = (payload: Payload | any) => {
    const formData = new FormData();
    _.each(payload, (value, key) => {
      if (Array.isArray(value)) {
        _.each(value, (item) => {
          formData.append(`${key}[]`, item as any as string);
        });
      } else if (
        value &&
        typeof value !== "string" &&
        Object.keys(value).length > 1
      ) {
        _.each(value, (item) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, value);
      }
    });

    return this.callApi(formData as any, false, {
      queryString: payload,
    });
  };

  callApiIfPayloadDifferent = async (
    payload: Payload,
    config?: ApiCallConfig
  ) => {
    if (!_.isEqual(this.lastSuccessPayload, payload)) {
      return await this.callApi(payload, false, config);
    }

    return Promise.resolve(this.data);
  };

  @action
  apiCallSucceed = (successResponse: BackendSuccessResponse<any>) => {
    this.successResponse = successResponse;
    this.isFailure = false;
    this.isSuccess = true;
    this.isLoading = false;
  };

  @action
  apiCallFailed = (failureResponse: BackendErrorResponse) => {
    this.failureResponse = failureResponse;

    this.isFailure = true;
    this.isSuccess = false;
    this.isLoading = false;
  };

  request = (payload, queryString?: {}) => {
    const query = { ...this.defaultPayload, ...payload };
    const payloadMappedUrl = generatePath(this.urlTemplate, query);

    const queryStringified = !_.isEmpty(queryString)
      ? `?${qs.stringify(queryString)}`
      : "";
    const payloadStringified = !_.isEmpty(payload)
      ? `?${qs.stringify(payload)}`
      : "";


    switch (this.requestType) {
      case RestMethods.GET:
        return this.api.get(
          `${payloadMappedUrl}${queryStringified}${payloadStringified}`
        );
      case RestMethods.POST:
        return this.api.post(`${payloadMappedUrl}${queryStringified}`, payload);
      case RestMethods.PATCH:
        return this.api.patch(
          `${payloadMappedUrl}${queryStringified}`,
          payload
        );
      case RestMethods.PUT:
        return this.api.put(`${payloadMappedUrl}${queryStringified}`, payload);
      case RestMethods.DELETE:
        return this.api.delete(`${payloadMappedUrl}${queryStringified}`);
      default:
        return Promise.reject("request method invalid");
    }
  };

  @action
  reInit = () => {
    this.successResponse = undefined;
    this.failureResponse = undefined;
    this.isCalled = undefined;
    this.isLoading = undefined;
    this.isSuccess = undefined;
    this.isFailure = undefined;
    this.axiosResponse = undefined;
    this.lastSuccessPayload = undefined;
  };
}
