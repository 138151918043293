import * as React from "react";

export interface MappingAccendoProps {
  className?: string;
  size?: number;
}

export const MappingAccendo: React.FC<MappingAccendoProps> = ({
  className,
  size,
}) => {
  return (
    <div className={`${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="268.072"
        height={size || 32}
        viewBox="0 0 268.072 39"
      >
        <g
          id="Group_14024"
          data-name="Group 14024"
          transform="translate(-40 -20.5)"
        >
          <g id="Group_1" data-name="Group 1" transform="translate(40 20.778)">
            <text
              id="CCENDO"
              transform="translate(38.646 23.584)"
              fill="#51656f"
              fontSize="17"
              fontFamily="Raleway-SemiBold, Raleway"
              fontWeight="600"
              letterSpacing="0.1em"
            >
              <tspan x="0" y="0">
                CCENDO
              </tspan>
            </text>
            <g
              id="Group_6151"
              data-name="Group 6151"
              transform="translate(0 0)"
            >
              <g
                id="Group_6129"
                data-name="Group 6129"
                transform="translate(0 0)"
              >
                <g id="poweredby-accendo">
                  <g id="Group_2" data-name="Group 2">
                    <g id="Group_1-2" data-name="Group 1">
                      <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M300.189-796.3a39.038,39.038,0,0,0,21.763,34.259,16.837,16.837,0,0,0-10.395,2.843c-9.321-9.125-15.711-21.535-16.113-38.44h4.792Z"
                        transform="translate(-273.725 797.637)"
                        fill="#3bbced"
                      />
                      <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M295.493-796.255v.105a62.253,62.253,0,0,0,3.01,16.976,35.4,35.4,0,0,0,2.231-10.945,36.5,36.5,0,0,1-.549-6.136l.05-1.379h-4.792C295.455-797.171,295.469-796.711,295.493-796.255Z"
                        transform="translate(-273.726 797.637)"
                        fill="#0c95c9"
                      />
                      <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M301.293-797.634c-.4,16.906-6.793,29.315-16.113,38.437a16.84,16.84,0,0,0-10.4-2.843A39.035,39.035,0,0,0,296.547-796.3a3.1,3.1,0,0,1-1.128-.457,2.974,2.974,0,0,1-.875-.883Q297.919-797.637,301.293-797.634Z"
                        transform="translate(-274.784 797.637)"
                        fill="#0373ac"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <line
            id="Line_3"
            data-name="Line 3"
            y2="39"
            transform="translate(182.5 20.5)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <g
            id="Group_13901"
            data-name="Group 13901"
            transform="translate(6.709)"
          >
            <g
              id="Group_13900"
              data-name="Group 13900"
              transform="translate(197.28 29)"
            >
              <g
                id="Group_6129-2"
                data-name="Group 6129"
                transform="translate(-0.635 0)"
              >
                <g id="poweredby-accendo-2" data-name="poweredby-accendo">
                  <g
                    id="Group_2-2"
                    data-name="Group 2"
                    transform="translate(14.526)"
                  >
                    <g
                      id="Group_1-3"
                      data-name="Group 1"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_1-2"
                        data-name="Path 1"
                        d="M312.576-796.955a17.186,17.186,0,0,1-4.767,12.5l1.838,2.872c3.4-4.215,5.165-8.894,5.336-16.055h-2.431Z"
                        transform="translate(-307.809 797.636)"
                        fill="#3bbced"
                      />
                      <path
                        id="Path_2-2"
                        data-name="Path 2"
                        d="M298.1-796.934v.053a31.579,31.579,0,0,1-1.527,8.612,17.958,17.958,0,0,1-1.132-5.552,18.52,18.52,0,0,0,.278-3.113l-.026-.7h2.431C298.121-797.4,298.113-797.166,298.1-796.934Z"
                        transform="translate(-290.952 797.636)"
                        fill="#0c95c9"
                      />
                      <path
                        id="Path_3-2"
                        data-name="Path 3"
                        d="M274.784-797.635a27.109,27.109,0,0,0,8.174,19.5,8.542,8.542,0,0,1,5.274-1.442,19.8,19.8,0,0,1-11.04-17.378,1.575,1.575,0,0,0,.572-.232,1.509,1.509,0,0,0,.444-.448Z"
                        transform="translate(-270.04 797.637)"
                        fill="#0373ac"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_2-3"
                    data-name="Group 2"
                    transform="translate(0)"
                  >
                    <g
                      id="Group_1-4"
                      data-name="Group 1"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_1-3"
                        data-name="Path 1"
                        d="M310.217-796.955a17.186,17.186,0,0,0,4.767,12.5l-1.838,2.872c-3.4-4.215-5.165-8.894-5.336-16.055h2.431Z"
                        transform="translate(-296.792 797.636)"
                        fill="#3bbced"
                      />
                      <path
                        id="Path_2-3"
                        data-name="Path 2"
                        d="M295.468-796.934v.053a31.579,31.579,0,0,0,1.527,8.612,17.958,17.958,0,0,0,1.132-5.552,18.52,18.52,0,0,1-.278-3.113l.026-.7h-2.431C295.449-797.4,295.456-797.166,295.468-796.934Z"
                        transform="translate(-284.426 797.636)"
                        fill="#0c95c9"
                      />
                      <path
                        id="Path_3-3"
                        data-name="Path 3"
                        d="M288.232-797.635a27.109,27.109,0,0,1-8.174,19.5,8.542,8.542,0,0,0-5.273-1.442,19.8,19.8,0,0,0,11.04-17.378,1.574,1.574,0,0,1-.572-.232,1.509,1.509,0,0,1-.444-.448Z"
                        transform="translate(-274.784 797.637)"
                        fill="#0373ac"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <text
              id="APPING"
              transform="translate(229.363 45)"
              fill="#51656f"
              fontSize="17"
              fontFamily="Raleway-SemiBold, Raleway"
              fontWeight="600"
              letterSpacing="0.1em"
            >
              <tspan x="0" y="0">
                APPING
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </div>
  );
};
