// Apis For MotivatingFactors
import { ApiStore } from "stores/ApiStore";
import { RestMethods } from "stores/baseApi";
import {BackendErrorResponse} from "_declare/api";

export interface MotivatingFactorsApisPayload {}

const ENDPOINT_TEMPLATE = "/api/descriptors/motivatingFactors";

class MotivatingFactorsApis extends ApiStore<
  MotivatingFactorsApisPayload,
  null,
    BackendErrorResponse,
  null
> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static uploadMotivatingFactors() {
    const endpoint = `${ENDPOINT_TEMPLATE}/uploadMotivatingFactors`;
    return new MotivatingFactorsApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }
  static getMotivatingFactors() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getMotivatingFactors`;
    return new MotivatingFactorsApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }
  static downloadMotivatingFactors() {
    const endpoint = `${ENDPOINT_TEMPLATE}/downloadMotivatingFactors`;
    return new MotivatingFactorsApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }
}

export const uploadMotivatingFactors: any = MotivatingFactorsApis.uploadMotivatingFactors();
export const getMotivatingFactors = MotivatingFactorsApis.getMotivatingFactors();
export const downloadMotivatingFactors = MotivatingFactorsApis.downloadMotivatingFactors();
