import * as React from "react";
import style from './index.module.scss'

export interface PageTitleProps {
  pageTitle?: string;
  subTitle?: string;
  className?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({
  pageTitle,
  subTitle,
  className,
}) => {
  return (
    <div className={`${style.pageTitle} ${className}`}>
      <div className={style.title}>{pageTitle.toUpperCase()}</div>
      <div className={style.subTitle}>{subTitle}</div>
    </div>
  );
};
