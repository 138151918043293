import * as React from "react";
import style from "./dropdownActionButton.module.scss";
import { DropdownBase, DropdownBaseProps } from "../Dropdown/DropdownBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface DropdownActionButtonProps extends DropdownBaseProps {
  verticalIcon?: boolean;
  icon?: IconProp;
  className?: string;
}

export const DropdownActionButton: React.FC<DropdownActionButtonProps> = ({
  verticalIcon,
  icon,
  className,
  ...otherProps
}) => {
  return (
    <div className={style.dropdownActionButton}>
      <DropdownBase {...otherProps}>
        <FontAwesomeIcon
          icon={icon ? icon : verticalIcon ? faEllipsisV : faEllipsisH}
          color={"var(--primary-color, #4466b0)"}
          className={`${style.ellipsisIcon} ${className}`}
        />
      </DropdownBase>
    </div>
  );
};
