import { FormStore } from "stores/FormStore";
import { Message } from "components/Message";
import { uploadData } from "container/MapperTemplate/MapperLanding/store/mapperLanding.api";
import {mapperLandingStore} from "container/MapperTemplate/MapperLanding/store/mapperLanding.store";
import {map} from "lodash";

interface MapperLandingFormStore {
  client: number | string;
  journey: number | string;
  norm_group_ids: [number];
  mapper_template_id: number;
  file: File;
}

export const mapperLandingFormStore = new FormStore<MapperLandingFormStore>(
  {
    client: {
      label: "Select Client",
      placeholder: "Select",
      bindings: "extended",
      rules: 'required'
    },
    journey: {
      label: "Select project",
      placeholder: "Select",
      bindings: "extended",
      rules: 'required'
    },
    norm_group_ids: {
      label: "Select Norms",
      placeholder: "Select",
      bindings: "extended",
      rules: 'required'
    },
    file: {
      bindings: "extended",
    },
    mapper_template_id: {},
  },
  {
    options: {
      validateOnChange: true,
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const value = form.values();
        const arrayIds = map(value.norm_group_ids, item => item.value)
        await uploadData.callApiWithFormData({
          client_id: value.client.value,
          journey_id: value.journey.value,
          mapper_template_id: value.mapper_template_id,
          norm_group_ids: arrayIds,
          file: mapperLandingStore.file,
        });
        Message.success(`Mapper template data uploaded. Processing the data will take a while, please check and verify the uploaded data after ~5 minutes`);
        return Promise.resolve();
      },
      onError: (form) => {
        Message.error("Incorrect form information");
        return Promise.reject(`Incorrect form information`);
      },
    },
  }
);
