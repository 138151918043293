import * as React from "react";
import style from "./index.module.scss";
import { Button } from "components/Button";
import { useNavigate } from "react-router-dom";

interface UnauthorizedProps {}

export const Unauthorized: React.FC<UnauthorizedProps> = (props) => {
  let navigate = useNavigate();
  return (
    <div className={style.unauthorizedContainer}>
      <div className={style.header}>You are unauthorized</div>
      <div className={style.subtext}>Please login to talent pulse and navigate from there.</div>
      <Button onClick={() => navigate(`/`)}>Try Again</Button>
    </div>
  );
};
