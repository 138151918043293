//Store for CategoryManagement

import {
  getClient,
  getJourneys,
  getLevel,
} from "container/_globalContainerStore/globalContainerStore.api";
import { get, map } from "lodash";
import { makeAutoObservable } from "mobx";
import { getWorkBookApi } from "container/Workbook/WorkbookCreation/store/workbookCreation.api";

class GlobalContainerStore {
  constructor() {
    makeAutoObservable(this);
  }

  callGetClientApi = async () => {
    await getClient.callApi({}, true);
  };

  callGetLevelApi = async () => {
    await getLevel.callApi();
  };

  callGetJourneysApi = async (client_ids) => {
    await getJourneys.callApi({ "client_ids[]": client_ids });
  };

  get getClientOptionData() {
    return map(getClient.data, (item: any) => ({
      label: item.name,
      value: item.id,
    }));
  }

  get getLevelOptionData() {
    return map(getLevel.data, (item: any) => ({
      label: item.name,
      value: item.id,
    }));
  }

  callGetWorkbookApi = async (payload) => {
    await getWorkBookApi.callApi(payload);
  };

  get getWorkbookApi() {
    return getWorkBookApi.data;
  }

  get getWorkbookName() {
    return get(getWorkBookApi.data, "name", "No Name");
  }

  get getWorkbookClients() {
    return map(
      get(getWorkBookApi.data, "clients"),
      (item: any) => item.name
    ).toString();
  }

  get getWorkbookClientsIds() {
    return map(get(getWorkBookApi.data, "clients"), (item: any) => item.id);
  }

  get getWorkbookLevels() {
    return map(
      get(getWorkBookApi.data, "levels"),
      (item: any) => item.name
    ).toString();
  }

  get getWorkbookLevelOptionData() {
    return map(get(getWorkBookApi.data, "levels"), (item: any) => ({
      label: item.name,
      value: item.id,
    }));
  }

  get getWorkbookClientOptionData() {
    return map(getJourneys.data, (item: any) => ({
      label: item.name,
      value: item.id,
    }));
  }
}

export const globalContainerStore = new GlobalContainerStore();
