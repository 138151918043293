/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-22
 */

// screen file for ReviewMapping
import React from "react";
import style from "./index.module.scss";
import { ReviewMappingProps } from "container/Workbook/ReviewMapping/store/reviewMapping.types";
import { WORKBOOK_DOMAIN } from "constants/routes";
import { HeaderFooterContainer } from "components/HeaderFooterContainer";
import { useNavigate, useParams } from "react-router-dom";
import { HeaderWorkbook } from "container/Workbook/HeaderWorkbook";
import { Title } from "components/Title";
import { Select } from "components/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { ReviewTableBreakdown } from "components/ReviewTableBreakdown";
import { reviewColumns } from "container/Workbook/ReviewMapping/tableColumns";
import { globalContainerStore } from "container/_globalContainerStore/globalContainerStore.store";
import { useEffectAsync } from "utlis/hooks";
import { observer } from "mobx-react";
import { workbookConfigurationStore } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.store";
import { reviewMappingStore } from "container/Workbook/ReviewMapping/store/reviewMapping.store";
import { Message } from "components/Message";
import { isNil } from "lodash";

export const ReviewMapping: React.FC<ReviewMappingProps> = observer(({}) => {
  let navigate = useNavigate();
  const { workbookId } = useParams();
  useEffectAsync(async () => {
    reviewMappingStore.clearSelected();
    await globalContainerStore.callGetWorkbookApi({
      workbook_id: workbookId,
    });
    await Promise.all([
      reviewMappingStore.callGetJourneysApi(
        globalContainerStore.getWorkbookClientsIds
      ),
      workbookConfigurationStore.callGetFormulaTreeApi(workbookId),
    ]);
  }, []);

  const finalizeCreate = async () => {
    try {
      const journey_id = reviewMappingStore.selectJourney.value;
      const norm_group_id = reviewMappingStore.selectLevel.value;
      Message.success("Successfully create the requested combination");
      navigate(
        `/${WORKBOOK_DOMAIN}/viewData/${workbookId}/${norm_group_id}/${journey_id}`
      );
    } catch (e) {
      if (isNil(reviewMappingStore.selectLevel)) {
        Message.error("Please select a level");
        reviewMappingStore.setShowErrorLevel(true);
      }
      if (isNil(reviewMappingStore.selectJourney)) {
        Message.error("Please select a view dataset");
        reviewMappingStore.setShowErrorDataset(true);
      }
    }
  };

  return (
    <HeaderFooterContainer
      header={<HeaderWorkbook />}
      cancelBtnOnClick={() =>
        navigate(`/${WORKBOOK_DOMAIN}/configure/${workbookId}`)
      }
      actionBtnOnClick={finalizeCreate}
      actionBtnLabel={"Create"}
    >
      <div className={style.reviewMappingContainer}>
        <div className={style.headerSection}>
          <div className={style.levelContainer}>
            <Title
              className={`mb-1`}
              count={globalContainerStore.getWorkbookLevelOptionData.length}
            >
              Review Mapping Level
            </Title>
            <div className={`d-flex`}>
              <Select
                error={
                  reviewMappingStore.showErrorLevel
                    ? "Please select a level"
                    : ""
                }
                errorClassName={`ms-2`}
                className={`ms-2 ${style.selectLevel}`}
                placeholder={"Select Level"}
                options={globalContainerStore.getWorkbookLevelOptionData}
                value={reviewMappingStore.selectLevel}
                onChange={(e) => reviewMappingStore.setLevelId(e)}
              />
            </div>
          </div>
          <div className={style.projectDataContainer}>
            <Title
              className={`mb-1`}
              count={reviewMappingStore.getJourneyOptionData.length}
            >
              Check Your Project Data With This Mapping
            </Title>
            <div className={`d-flex `}>
              <Select
                error={
                  reviewMappingStore.showErrorDataset
                    ? "Please select a view data set"
                    : ""
                }
                errorClassName={`ms-2`}
                className={`ms-2 ${style.selectProject}`}
                placeholder={"View Dataset"}
                options={reviewMappingStore.getJourneyOptionData}
                value={reviewMappingStore.selectJourney}
                onChange={(e) => reviewMappingStore.setJourneyId(e)}
              />
              <FontAwesomeIcon className={style.fontColor} icon={faDownload} />
            </div>
          </div>
        </div>
        <div>
          <ReviewTableBreakdown
            stickyHeader={true}
            tableClassName={style.reviewTable}
            columns={reviewColumns}
            data={reviewMappingStore.getFormulaTreeData}
          />
        </div>
      </div>
    </HeaderFooterContainer>
  );
});
