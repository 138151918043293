import * as React from "react";
import style from "./index.module.scss";
import { DropdownBase } from "../Dropdown/DropdownBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Input, InputProps } from "components/Input";

export interface ISearchInputProps extends InputProps {
  onChange?: (e: any) => void;
  onClickDelete?: (e: any) => void;
  value?: string;
  placeholder?: string;
  className?: string;
  loading?: boolean;
  options?: any[];
  resultItemRenderer?: (item) => React.ReactNode | HTMLElement;
}

export const SearchInput: React.FC<ISearchInputProps> = ({
  onChange,
  onClickDelete,
  value,
  placeholder,
  loading,
  options,
  resultItemRenderer,
}) => {
  const menuRef = React.useRef<any>();
  const inputRef = React.useRef<any>();
  const [isOpen, setIsOpen] = React.useState(false);
  const renderFilterButton = () => {
    if (loading) {
      return (
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin={true}
          className={style.fontIcon}
        />
      );
    }
    if (value) {
      return (
        <FontAwesomeIcon
          icon={faTrash}
          className={style.fontIcon}
          onClick={onClickDelete}
        />
      );
    }
    return <FontAwesomeIcon icon={faSearch} className={style.fontIcon} />;
  };

  const resultRenderer = (item) => {
    if (resultItemRenderer) {
      return <div ref={menuRef}>{resultItemRenderer(item)}</div>;
    } else {
      return [];
    }
  };

  const handleOnFocus = () => {
    // We open the dropdown only when the search input is focused
    setIsOpen(true);
  };

  const handleClickOutside = (e) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      inputRef.current &&
      !inputRef.current.contains(e.target)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <DropdownBase
      dropdownItem={options}
      renderMenuItems={resultRenderer}
      dropdownProps={{ isOpen: isOpen && options && !!options.length }}
      end
    >
      <Input
        onFocus={handleOnFocus}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        rightAppendAddon={renderFilterButton()}
        removeError={true}
      />
    </DropdownBase>
  );
};
