//Store for AccessControl

import { makeAutoObservable } from "mobx";
import {
  getLookupData,
  LookUpXLApisPayload,
} from "container/LookUpXL/store/lookUpXL.api";
import {
  addUsersToClient,
  getUsersByClient, removeUsersToClient,
} from "container/AccessControl/store/accessControl.api";
import { debounce } from "lodash";

class AccessControlStore {
  constructor() {
    makeAutoObservable(this);
  }

  search;
  page;
  isOpen;

  loading = false;

  setLoading = (bool: boolean) => {
    this.loading = bool;
  };

  setSearch = (search: string) => {
    this.search = search;
  };

  setPage = (page) => {
    this.page = page;
  };

  setIsOpen = (bool) => {
    this.isOpen = bool;
  };

  callGetUsersByClientApi = async (payload?: any) => {
    await getUsersByClient.callApi(payload);
  };

  callGetUsersByClientApiWithDebounce = debounce(
    (payload?: LookUpXLApisPayload) => {
      getUsersByClient.callApiIfPayloadDifferent(payload);
    },
    1500
  );

  callCreateUserApi = async (name, email, client_id) => {
    await addUsersToClient.callApi({ name: name, email: email, client_id: client_id });
  };

  callRemoveUserApi = async (name, email, client_id, user_id) => {
    await removeUsersToClient.callApi({ name: name, email: email, client_id: client_id, user_id: user_id });
  };

  get getTableData() {
    return (getUsersByClient.data as any[]) || [];
  }

  get getMetaData() {
    return getUsersByClient.meta;
  }

  get getSearch() {
    return this.search;
  }

  get getPage() {
    return this.page;
  }
}

export const accessControlStore = new AccessControlStore();
