// Apis For AccessControl
import { ApiStore } from 'stores/ApiStore';
import { RestMethods } from 'stores/baseApi';

export interface AccessControlApisPayload {
  
}

const ENDPOINT_TEMPLATE = '/api/clients'

class AccessControlApis extends ApiStore<AccessControlApisPayload, null, null, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static getUsersByClient() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:client_id/users`;
    return new AccessControlApis(endpoint, { requestType: RestMethods.GET });
  }

  static addUsersToClient() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:client_id/users/add`;
    return new AccessControlApis(endpoint, { requestType: RestMethods.POST });
  }

  static removeUsersToClient() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:client_id/users/:user_id/remove`;
    return new AccessControlApis(endpoint, { requestType: RestMethods.POST });
  }
}

export const getUsersByClient = AccessControlApis.getUsersByClient();
export const addUsersToClient = AccessControlApis.addUsersToClient();
export const removeUsersToClient = AccessControlApis.removeUsersToClient();

