import * as React from "react";
import style from "./index.module.scss";
import { useRef, useState } from "react";
import Truncate from "react-truncate";
import { Tooltip } from "components/Tooltip";

interface TextOverflowWithTooltipProps {
  lines?: number | false;
  ellipsis?: React.ReactNode;
  trimWhitespace?: boolean;
  width?: number;
  onTruncate?: (isTruncated: boolean) => void;
  className?: string;
}

export const TextOverflowWithTooltip: React.FC<TextOverflowWithTooltipProps> = (
  props
) => {
  const { lines = 1, ...otherProps } = props;

  return (
    <>
      <Tooltip content={props.children}>
        <Truncate
          lines={lines}
          {...otherProps}
          className={props.className}
        >
          {props.children}
        </Truncate>
      </Tooltip>
    </>
  );
};
