import * as React from "react";
import style from "./index.module.scss";
import {Label} from "components/Label";

interface ToggleSwitchProps {
  id?: string;
  labelText?: string;
  checked?: boolean;
  onChange: any;
  optionLabels?: [];
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  checked,
  onChange,
  labelText,
  optionLabels = ["Yes", "No"]
}) => {
  return (
    <>
      <Label>{labelText}</Label>
      <div className={`${style.toggleSwitch}`}>
        <input
          type="checkbox"
          className={style.checkbox}
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <label className={style.label} htmlFor={id}>
          <span
            className={style.inner}
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
          />
          <span className={style.switch} />
        </label>
      </div>
    </>
  );
};
