import style from "./index.module.scss";
import * as React from "react";
import { TypeColorText } from "components/TypeColorText";
import {isEmpty, map} from "lodash";

export const reviewColumns = [
  {
    Header: "Mapping Category",
    accessor: "name",
    Cell: ({ row }) => (
      <div className={style.categoryContainer}>
        <div
          className={`${style.indicator} ${
            style[`indicatorOf${row.original.level}`]
          }`}
        />
        <span className={`d-flex`}>{row.original.category.name} </span>
      </div>
    ),
  },
  {
    Header: "Type",
    accessor: "type",
    Cell: ({ row }) => (
      <TypeColorText
        className={style.typeContainer}
        color={row.original.category.type}
        text={`(${row.original.category.category_source})`}
      />
    ),
  },
  {
    Header: "Formula",
    accessor: "formula",
    Cell: ({ row }) => (
      <div className={style.formulaContainer}>
        {!isEmpty(row.original.formula_combinations) ? <ol>
          {map(row.original.formula_combinations, (item, index) => (
              <li key={index}>
                <span className={style.nameBold}>{item.formula_name}</span> :{" "}
                {item.formula}
              </li>
          ))}
        </ol> : <span>No formula for this category</span>}
      </div>
    ),
  },
];
