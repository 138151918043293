import * as React from "react";
import style from "container/Workbook/WorkbookConfiguration/component/AccordionTree/index.module.scss";
import Tree, { TreeProps } from "rc-tree";
import { useEffect, useState } from "react";
import { DataNode } from "rc-tree/lib/interface";
import { faCog, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { workbookConfigurationStore } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.store";
import { TypeColorText } from "components/TypeColorText";
import { SelectBoxWithCode } from "container/Workbook/WorkbookConfiguration/component/SelectWithCode";
import { observer } from "mobx-react";
import { categoryManagementStore } from "container/CategoryManagement/store/categoryManagement.store";
import { getCategoriesApi } from "container/CategoryManagement/store/categoryManagement.api";

export interface TreeComponentData {
  id: number | string;
  title: string;
  children?: TreeComponentData[];
}

interface AccordionTreeProps extends TreeProps {
  data?: DataNode[];
}

const STYLE = `.node-motion {
  transition: all .3s;
  overflow-y: hidden;}`;

const motion = {
  motionName: "node-motion",
  motionAppear: false,
  onAppearStart: () => ({ height: 0 }),
  onAppearActive: (node) => ({ height: node.scrollHeight }),
  onLeaveStart: (node) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0 }),
};

export const AccordionTree: React.FC<AccordionTreeProps> = observer(
  ({ data, ...treeProps }) => {
    const [newData, setNewData] = useState(
      workbookConfigurationStore.getFormulaTreeData
    );
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [expandedKeys, setExpandedKeys] = useState([]);

    const onDragStart = (info) => {};

    const onDragEnter = ({ expandedKeys }) => {
      setExpandedKeys(expandedKeys);
    };

    useEffect(() => {
      const loop = (arrayData, number) => {
        return arrayData.map((item) => {
          if (item.children) {
            return {
              ...item,
              children: loop(item.children, number + 1),
              level: number,
            };
          } else {
            return { ...item, level: number };
          }
        });
      };
      setNewData(loop(data, 0));
    }, [data]);

    const onDrop = (info) => {
      const dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      const dropPos = info.node.pos.split("-");
      const dropPosition =
        info.dropPosition - Number(dropPos[dropPos.length - 1]);

      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            callback(item, index, arr);
            return;
          }
          if (item.children) {
            loop(item.children, key, callback);
          }
        });
      };
      const data = [...newData];

      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });

      if (dropPosition === 0) {
        // Drop on the content
        loop(data, dropKey, (item) => {
          dragObj = {
            ...dragObj,
            level: item.level + 1,
            parent_id: item.id,
            order: 0,
          };
          item.children = item.children || [];
          item.children.unshift(dragObj);
        });
      } else {
        // Drop on the gap (insert before or insert after)
        let ar;
        let i;
        let newItem;
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
          newItem = item;
        });
        if (dropPosition === -1) {
          dragObj = {
            ...dragObj,
            level: newItem.level,
            parent_id: newItem.parent_id,
            order: i,
          };
          ar.splice(i, 0, dragObj);
        } else {
          dragObj = {
            ...dragObj,
            level: newItem.level,
            parent_id: newItem.parent_id,
            order: i + 1,
          };
          ar.splice(i + 1, 0, dragObj);
        }
      }
      workbookConfigurationStore.callReorderMappingApi(
        dragObj.id,
        dragObj.parent_id,
        dragObj.order
      );
      setNewData(data);
    };

    const onExpand = (expandedKeys) => {
      setAutoExpandParent(false);
      setExpandedKeys(expandedKeys);
    };

    const filterOptions = (
      candidate: { label: string; value: string; data: any },
      input: string
    ) => {
      if (input) {
        return candidate.label.toLowerCase().includes(input.toLowerCase()) || candidate.value === "create";
      }
      return true;
    };


    //Need to remove this later
    console.log(getCategoriesApi.isLoading);
    return (
      <>
        <style dangerouslySetInnerHTML={{ __html: STYLE }} />
        <Tree
          expandedKeys={expandedKeys}
          onExpand={onExpand}
          autoExpandParent={autoExpandParent}
          draggable
          onDragStart={onDragStart}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          className={style.treeComponent}
          defaultExpandParent={false}
          showIcon={false}
          showLine={true}
          virtual={false}
          motion={motion}
          treeData={newData}
          titleRender={(data: any) => {
            if (data.title === "") {
              return (
                <div
                  style={{
                    width: `calc(100% - 48px - ${data.level * 18}px)`,
                  }}
                >
                  <div className={`d-flex`}>
                    <SelectBoxWithCode
                      containerClassName={`w-100`}
                      showValue={false}
                      isLoading={getCategoriesApi.isLoading}
                      filterOption={filterOptions}

                      options={workbookConfigurationStore.getCategoryOptionData}
                      onInputChange={(e) => {
                        workbookConfigurationStore.callGetCategoriesApiWithDebounce(
                          { search: e }
                        );
                      }}
                      onChange={(e: any) => {
                        if (e.value === "create") {
                          categoryManagementStore.setCategoryEditData(
                            undefined
                          );
                          categoryManagementStore.setIsEdit(false);
                          workbookConfigurationStore.setCreateEditModalIsOpen(
                            true
                          );
                          workbookConfigurationStore.setFromWorkbookConfiguration(
                            true,
                            data
                          );
                        } else {
                          workbookConfigurationStore.addCategoryApi(e, data);
                        }
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={style.cancelOption}
                      onClick={() => {
                        if (data.level === 0) {
                          workbookConfigurationStore.removeNewParentBranch(
                            data.key
                          );
                        } else {
                          workbookConfigurationStore.removeChildrenBranch(data);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className={`${style.title} ${
                    data.id === workbookConfigurationStore.selectedCategoryId &&
                    style.selected
                  }`}
                  key={data.id}
                  style={{ width: `calc(100% - 35px - ${data.level * 18}px)` }}
                  onClick={() =>
                    workbookConfigurationStore.setSelectedCategoryId(
                      data.id !== workbookConfigurationStore.selectedCategoryId
                        ? data.id
                        : ""
                    )
                  }
                >
                  <div className={`d-flex flex-wrap`}>
                    {data.title}
                    <TypeColorText
                      className={"ms-1"}
                      text={`(${data?.category?.category_code})`}
                      color={data?.category?.type}
                    />
                  </div>
                  <div className={`d-flex`}>
                    <FontAwesomeIcon
                      icon={faCog}
                      className={style.icon}
                      onClick={() => {
                        categoryManagementStore.setIsEdit(true);
                        workbookConfigurationStore.setFromWorkbookConfiguration(
                          true,
                          data
                        );
                        categoryManagementStore.setCategoryEditData(
                          data.category
                        );
                        workbookConfigurationStore.setCreateEditModalIsOpen(
                          true
                        );
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faPlus}
                      className={style.icon}
                      onClick={() => {
                        setExpandedKeys([...expandedKeys, data.id]);
                        workbookConfigurationStore.addChildrenBranch(data);
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={style.icon}
                      onClick={async () => {
                        await workbookConfigurationStore.removeCategoryApi(
                          data.id
                        );
                      }}
                    />
                  </div>
                </div>
              );
            }
          }}
          {...treeProps}
        />
      </>
    );
  }
);
