import React from "react";
import { DropdownActionButton } from "components/Dropdown/DropdownActionButton";
import { categoryManagementStore } from "./store/categoryManagement.store";
import { Message } from "components/Message";
import { getCategoryWorkbooks } from "container/CategoryManagement/store/categoryManagement.api";
import style from "./index.module.scss";

export const columnsCategories = [
  {
    Header: "Category Name",
    accessor: "name",
    width: 500,
    minWidth: 400,
  },
  {
    Header: "Category Code",
    accessor: "category_code",
    width: 250,
    minWidth: 200,
  },
  {
    Header: "Category Type",
    accessor: "type",
    width: 250,
    minWidth: 200,
    Cell: ({ row }) =>
      row.original.type === "default" ? (
        <div className={style.defaultColor}>{row.original.type}</div>
      ) : (
        <div className={style.customColor}>{row.original.type}</div>
      ),
  },
  {
    Header: "Category Usage",
    accessor: "workbooks_count",
    width: 200,
    minWidth: 200,
  },
  {
    Header: "Action",
    id: "action",
    disableSortBy: true,
    width: 90,
    minWidth: 70,
    Cell: ({ row }) => (
      <div>
        <DropdownActionButton dropdownItem={dropDownItems(row.original)} />
      </div>
    ),
  },
];
const dropDownItems = (data) => [
  {
    name: "Edit",
    onClick: () => {
      categoryManagementStore.setIsEdit(true);
      categoryManagementStore.setCategoryEditData(data);
      categoryManagementStore.setEditOpen(!categoryManagementStore.editOpen);
    },
  },
  {
    name: "Clone",
    onClick: () => {
      categoryManagementStore.setCategoryCloneData(data);
      categoryManagementStore.setCloneOpen(!categoryManagementStore.cloneOpen);
    },
  },
  {
    name: "Delete",
    disabled: data.type === "default",
    onClick: async () => {
      categoryManagementStore.setCat(data);
      categoryManagementStore.catWorkbook > 0 &&
        (await getCategoryWorkbooks.callApi({
          categories_id: data.id,
        }));
      categoryManagementStore.setDeleteOpen(
        !categoryManagementStore.deleteOpen
      );
    },
  },
];
