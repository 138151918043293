import * as React from "react";
import { SortableHandle } from "react-sortable-hoc";
import style from "container/Workbook/WorkbookConfiguration/component/FormulaBox/formulaBox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export interface FormulaBoxProps {
  formulaName: string;
  formula: string;
  onClickRemove?: (item: any) => void;
  onClickEdit?: (item: any) => void;
  dragDrop?: boolean;
  indexNumber?: number;
  id?: number;
}

const DragHandle: React.ComponentClass = SortableHandle((props) => (
  <div className={style.sortingContainer}>
    <FontAwesomeIcon icon={faArrowsAlt} className={style.sorting} />
  </div>
));

export const FormulaBox: React.FC<FormulaBoxProps> = ({
  formulaName,
  formula,
  onClickRemove,
  onClickEdit,
}) => {
  const [showIcon, setShowIcon] = useState(false);
  return (
    <div
      className={`d-flex row ${style.formulaBox}`}
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
    >
      <div className={`${style.content} d-flex`}>
        <div className={`d-flex ${style.iconResponsive}`}>
          <DragHandle />
          <div className={`d-flex flex-column ${style.formulaContent}`}>
            <div className={style.formulaName}>{formulaName}</div>
            <div className={style.formulaFunction}>{formula}</div>
          </div>
          <FontAwesomeIcon
            icon={faPen}
            className={`${style.editButton} ${showIcon && style.show}`}
            onClick={onClickEdit}
          />
          <FontAwesomeIcon
            icon={faTimes}
            className={`${style.closeButton} ${showIcon && style.show}`}
            onClick={onClickRemove}
          />
        </div>
      </div>
    </div>
  );
};
