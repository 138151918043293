import * as React from "react";
import style from "./index.module.scss";
import { TableWithVirtualization } from "components/TableWithVirtualization";
import { mockColumnsTrack } from "components/TableWithVirtualization/mock";
import { Modal } from "components/Modal";
import { Select } from "components/Select";
import { landingStore } from "container/Landing/store/landing.store";
import { useEffectAsync } from "utlis/hooks";
import { globalContainerStore } from "container/_globalContainerStore/globalContainerStore.store";
import { Message } from "components/Message";
import { observer } from "mobx-react";
import { Table } from "components/Table";
import {
  trackFinalizeColumns,
  viewLogColumns,
} from "container/Landing/tableColumn";
import {get} from "lodash";
import {workBookDownloadApi} from "container/Landing/store/landing.api";

interface LandingPageModalsProps {
  isOpen?: boolean;
  toggle?: () => void;
  workbookId?: string;
}

export const TrackFinalizeModal: React.FC<LandingPageModalsProps> = observer(
  ({ isOpen, toggle }) => {
    useEffectAsync(async () => {
      if (isOpen) {
        await landingStore.callGetTrackingByWorkbookApi(
          landingStore.workbookId
        );
      }
    }, [isOpen]);

    return (
      <Modal
        toggle={toggle}
        isOpen={isOpen}
        headerTitle={`Track Finalize - ${landingStore.workbookName}`}
        cancelBtnLabel={"Cancel"}
        showActionBtn={false}
        modalSize={style.modalSize}
      >
        <div className={style.downloadModal}>
          <div className={style.modalTop}>View Finalize Track Status</div>
          <div className={style.tableHeight}>
            <TableWithVirtualization
              columns={trackFinalizeColumns}
              data={landingStore.getTrackingWorkbookData}
            />
          </div>
        </div>
      </Modal>
    );
  }
);

export const DownloadModal: React.FC<LandingPageModalsProps> = observer(
  ({ isOpen, toggle }) => {
    useEffectAsync(async () => {
      if (isOpen) {
        await globalContainerStore.callGetWorkbookApi({
          workbook_id: landingStore.workbookId,
        });
        await globalContainerStore.callGetJourneysApi(
          globalContainerStore.getWorkbookClientsIds
        );
        landingStore.resetDownloadPayloads();
      }
    }, [isOpen]);

    return (
      <Modal
        toggle={toggle}
        isOpen={isOpen}
        headerTitle={`Download - ${landingStore.workbookName}`}
        actionBtnLabel={"Download"}
        cancelBtnLabel={"Cancel"}
        actionBtnOnClick={async () => {
          try {
            await landingStore.callWorkBookDownloadApi(
              landingStore.downloadSelectJourney.value,
              landingStore.downloadSelectLevel.value
            );
            landingStore.resetDownloadPayloads();
            Message.success("Successfully download workbook");
          } catch (e) {
            landingStore.resetDownloadPayloads();
            const errorMessage = get(workBookDownloadApi.failureResponse, "errors");
            Message.error(
                errorMessage[Object.keys(errorMessage)[0]]
                    ? errorMessage[Object.keys(errorMessage)[0]]
                    : "Fail to download workbook"
            );
          }
        }}
      >
        <div className={style.downloadModal}>
          <div className={style.modalTop}>Show Projects</div>
          <div>
            <Select
              label={"Please select journey"}
              options={globalContainerStore.getWorkbookClientOptionData}
              value={landingStore.downloadSelectJourney}
              onChange={(e) => landingStore.setDownloadJourneyId(e)}
            />
          </div>
          <div>
            <Select
              label={"Please select level"}
              options={globalContainerStore.getWorkbookLevelOptionData}
              value={landingStore.downloadSelectLevel}
              onChange={(e) => landingStore.setDownloadLevel(e)}
            />
          </div>
        </div>
      </Modal>
    );
  }
);

export const ShareModal: React.FC<LandingPageModalsProps> = ({
  isOpen,
  toggle,
}) => {
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      headerTitle={"Download - Coca-Cola Senior Manager Workbook"}
      actionBtnLabel={"Download"}
      cancelBtnLabel={"Cancel"}
    >
      <div className={style.downloadModal}>
        <div className={style.modalTop}>Show Projects</div>
        <div>
          <Select label={"Please select journey"} />
        </div>
        <div>
          <Select label={"Please select level"} />
        </div>
      </div>
    </Modal>
  );
};

export const ViewLogModal: React.FC<LandingPageModalsProps> = observer(
  ({ isOpen, toggle }) => {
    useEffectAsync(async () => {
      if (isOpen) {
        await landingStore.callLogApi(landingStore.workbookId);
      }
    }, [isOpen]);

    return (
      <Modal
        toggle={toggle}
        isOpen={isOpen}
        headerTitle={`View Log - ${landingStore.workbookName}`}
        cancelBtnLabel={"Close"}
        showActionBtn={false}
        modalSize={style.modalSize}
      >
        <div className={style.downloadModal}>
          <div className={style.modalTop}>View Log History</div>
          <div className={style.tableHeight}>
            <Table columns={viewLogColumns} data={landingStore.getLogData} />
          </div>
        </div>
      </Modal>
    );
  }
);
