//Store for WorkbookConfiguration
import {
  addCategory,
  getFormulaTree,
  removeCategory,
  reorderMappingTree,
} from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.api";
import { makeAutoObservable } from "mobx";
import {
  debounce,
  filter,
  get,
  isEmpty,
  isNil,
  map,
  random,
  remove,
} from "lodash";
import {
  CategoryApisPayload,
  getCategoriesApi,
} from "container/CategoryManagement/store/categoryManagement.api";
import { getLevel } from "container/_globalContainerStore/globalContainerStore.api";
import { Message } from "components/Message";

class WorkbookConfigurationStore {
  constructor() {
    makeAutoObservable(this);
  }

  configurationTreeData;
  workbookId;
  selectedCategoryId;

  newBranch;
  createEditModalIsOpen;
  fromWorkbookConfiguration;
  fromWorkbookConfigurationData;

  //custom function to populate the tree structure
  loop = (arrayData, parentId?) => {
    return map(arrayData, (item) => {
      if (!isEmpty(item.children)) {
        return {
          key: item.id,
          title: item.category.name,
          ...item,
          children: this.loop(
            item.id === parentId
              ? [
                  ...item.children,
                  {
                    key: `${random(1, 1000)}-${parentId}`,
                    title: "",
                    parent_id: parentId,
                  },
                ]
              : item.children,
            parentId
          ),
        };
      } else {
        return {
          key: item.id || "",
          title: item.category?.name || "",
          ...item,
          children: !isNil(item.id) &&
            item.id === parentId && [
              {
                key: `${random(1, 1000)}-${parentId}`,
                title: "",
                parent_id: parentId,
              },
            ],
        };
      }
    });
  };

  loopChildrenRemove = (arrayData, key) => {
    return arrayData
      .filter((a) => a.key !== key)
      .map((e) => {
        return {
          ...e,
          children: this.loopChildrenRemove(e.children || [], key),
        };
      });
  };

  addNewParentBranch = () => {
    this.setNewBranch(true);
    this.configurationTreeData = [
      ...this.configurationTreeData,
      { key: `${random(1, 1000)}`, title: "" },
    ];
  };

  removeNewParentBranch = (id) => {
    this.setConfigurationTreeData(
      filter(this.configurationTreeData, (item: any) => item.key !== id)
    );
    this.setNewBranch(false);
  };

  addChildrenBranch = (e) => {
    this.configurationTreeData = this.loop(this.configurationTreeData, e.id);
  };

  removeChildrenBranch = (e) => {
    this.configurationTreeData = this.loopChildrenRemove(
      this.configurationTreeData,
      e.key
    );
  };

  setConfigurationTreeData = (data) => {
    this.configurationTreeData = data;
  };

  setWorkbookId = (id) => {
    this.workbookId = id;
  };

  setNewBranch = (bool: boolean) => {
    this.newBranch = bool;
  };

  setCreateEditModalIsOpen = (bool) => {
    this.createEditModalIsOpen = bool;
  };

  setFromWorkbookConfiguration = (bool, data) => {
    this.fromWorkbookConfiguration = bool;
    this.fromWorkbookConfigurationData = data;
  };

  setSelectedCategoryId = (id) => {
    this.selectedCategoryId = id;
  };

  callGetFormulaTreeApi = async (workbook_id) => {
    this.setConfigurationTreeData([]);
    await getFormulaTree.callApi({ workbook_id });
    this.setConfigurationTreeData(getFormulaTree.data);
  };

  callReorderMappingApi = async (child_id, parent_id, order) => {
    await reorderMappingTree.callApi({
      child_id: child_id,
      parent_id: parent_id,
      order: order,
    });
  };

  callGetCategoriesApi = async () => {
    await getCategoriesApi.callApi();
  };

  callGetCategoriesApiWithDebounce = debounce(
    (payload?: CategoryApisPayload) => {
      getCategoriesApi.callApiIfPayloadDifferent(payload);
    },
    750
  );

  get getFormulaTreeData() {
    return this.loop(this.configurationTreeData);
  }

  get getCategoryOptionData() {
    const existingOption = map(getCategoriesApi.data, (item: any) => ({
      label: item.name,
      value: item.id,
      code: item.category_code,
      type: item.type,
    }));

    return [
      {
        label: "Create New Category",
        value: "create",
      },
      {
        label: "Existing Option",
        options: existingOption,
      },
    ];
  }

  addCategoryApi = async (e, data) => {
    try {
      await addCategory.callApi({
        workbook_id: this.workbookId,
        category_id: e.value,
        parent_id: data.level > 0 ? data.parent_id : null,
      });
      await this.callGetFormulaTreeApi(this.workbookId);
      this.setNewBranch(false);
      Message.success("Add Category to Mapping Tree");
    } catch (error) {
      this.setNewBranch(false);
      const errorMessage = get(addCategory.failureResponse, "errors");
      Message.error(
        errorMessage[Object.keys(errorMessage)[0]]
          ? errorMessage[Object.keys(errorMessage)[0]][0]
          : "Failed to add category to mapping tree"
      );
    }
  };

  removeCategoryApi = async (category_workbook_id) => {
    try {
      await removeCategory.callApi({
        category_workbook_id: category_workbook_id,
      });
      await this.callGetFormulaTreeApi(this.workbookId);
      this.setSelectedCategoryId("");
      Message.success("Delete Category from Mapping Tree");
    } catch (error) {
      console.error("error", error);
      Message.error(removeCategory.failureResponse.errors.category[0]);
    }
  };
}

export const workbookConfigurationStore = new WorkbookConfigurationStore();
