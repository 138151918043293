import * as React from "react";
import style from "./index.module.scss";
import { Button, IButtonProps } from "../Button";

export interface ModalProps {
  isOpen?: boolean;
  toggle?: () => void;
  headerTitle?: string;
  cancelBtnLabel?: string;
  actionBtnLabel?: string;
  extraActionBtnLabel?: string;
  mainModalContentClassName?: string;
  modalSize?: string;
  actionBtnOnClick?: (e) => void;
  actionBtnProps?: IButtonProps;
  showActionBtn?: boolean;
  extraActionBtnOnClick?: (e) => void;
  extraActionBtnProps?: IButtonProps;
  showExtraActionBtn?: boolean;
  actionDisableToggle?: boolean;
  cancelDisableToggle?: boolean;
  cancelBtnOnClick?: (e) => void;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  toggle,
  headerTitle = "Modal Open",
  showActionBtn = true,
  actionBtnOnClick,
  showExtraActionBtn,
  extraActionBtnProps,
  extraActionBtnOnClick,
  actionDisableToggle,
  cancelDisableToggle,
  actionBtnProps,
  actionBtnLabel = "Submit",
  modalSize,
  cancelBtnOnClick,
  cancelBtnLabel = "Close",
  mainModalContentClassName,
  extraActionBtnLabel = "Save",
  children,
}) => {
  const [modalAnimation, setModalAnimation] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isOpen && modalAnimation) {
      setTimeout(() => {
        setModalAnimation(true);
      }, 350);
    }
    setModalAnimation(isOpen);
  }, [isOpen]);

  const renderDefaultFooter = (): React.ReactNode => (
    <>
      <Button
        onClick={(e) => {
          cancelBtnOnClick && cancelBtnOnClick(e);
          !cancelDisableToggle && toggle && toggle();
        }}
        color={"transparent"}
        className={`ml-2 ${style.footerBtn}`}
        buttonLabelClass={`w-100`}
      >
        {cancelBtnLabel}
      </Button>
      {showActionBtn && (
        <Button
          color={"primary"}
          onClick={(e) => {
            actionBtnOnClick(e);
            !actionDisableToggle && toggle && toggle();
          }}
          className={` ${style.footerBtn}`}
          buttonLabelClass={`w-100`}
          loading={actionBtnProps && actionBtnProps.loading}
          {...actionBtnProps}
        >
          {actionBtnLabel}
        </Button>
      )}
      {showExtraActionBtn && (
        <Button
          color={"primary"}
          onClick={(e) => {
            extraActionBtnOnClick(e);
            toggle && toggle();
          }}
          className={` ${style.footerBtn}`}
          buttonLabelClass={`w-100`}
          loading={extraActionBtnProps && extraActionBtnProps.loading}
          {...extraActionBtnProps}
        >
          {extraActionBtnLabel}
        </Button>
      )}
    </>
  );

  return (
    <div className={style.modalContainer}>
      {isOpen && (
        <>
          <div className={style.overlay} />
          <div className={`${style.centerDiv} `}>
            <div
              className={`${style.modal} ${
                modalAnimation && !isOpen ? style.closeAnimation : ""
              } ${modalSize}`}
            >
              <div className={style.header}>{headerTitle}</div>
              <div
                className={`${style.mainModalContent} ${mainModalContentClassName}`}
              >
                {children}
              </div>
              <div className={style.footer}>{renderDefaultFooter()}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
