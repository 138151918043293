import * as React from "react";
import style from "./index.module.scss";
import Tippy, { TippyProps } from "@tippyjs/react";

export interface TippyTooltipProps extends TippyProps {
    className?: string;
}

export const Tooltip: React.FC<TippyTooltipProps> = (props) => {
    const { className, children, ...otherProps } = props;

    return (
        <Tippy
            className={`${style.toolTip} ${className}`}
            {...otherProps}
        >
            <div>{children}</div>
        </Tippy>
    );
};
