import * as React from 'react';
import style from './index.module.scss';

interface AsteriskProps {
  className?: string;
}

export const Asterisk: React.FC<AsteriskProps> = props => {
  return <span className={`${style.managerAsterisk} ${props.className}`}>*</span>;
};
