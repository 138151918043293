//Store for MapperSetup

import {
  createMapperTemplateApi,
  getCsvFields,
  getFields,
  mappedCsvFields,
  mappingCsv,
  uploadCsvFile,
} from "container/MapperTemplate/MapperSetup/store/mapperSetup.api";
import { ChangeEvent } from "react";
import { downloadExcelFile, isExcelFile } from "utlis/files";
import { Message } from "components/Message";
import { mapperSetupFormStore } from "container/MapperTemplate/MapperSetup/store/mapperSetup.formstore";
import { Option } from "container/MapperTemplate/MapperLanding/store/mapperLanding.types";
import { CSVFields } from "container/MapperTemplate/MapperSetup/store/mapperSetup.types";
import { makeAutoObservable, observable, set } from "mobx";
import { debounce, get, map, uniq } from "lodash";
import readXlsxFile from "read-excel-file";

class MapperSetupStore {
  constructor() {
    makeAutoObservable(this);
  }
  csvHeaderRef;
  isCSVHeaderUploadLoading = false;
  isCSVTemplateDownloadLoading = false;
  mapperId;
  filterText = [];
  csvMapping = mapperSetupFormStore.$("csv_mapping");
  setIsCSVHeaderUploadLoading(bool: boolean) {
    this.isCSVHeaderUploadLoading = bool;
  }
  setIsCSVTemplateDownloadLoading(bool: boolean) {
    this.isCSVTemplateDownloadLoading = bool;
  }
  setMapperId(val) {
    this.mapperId = val;
  }
  handleUploadCSVHeader = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      this.setIsCSVHeaderUploadLoading(true);
      const excelFile = e.target.files ? e.target.files[0] : undefined;

      if (!isExcelFile(e.target.value)) {
        Message.warning("Unsupported File");
      }

      readXlsxFile(e.target.files[0]).then((rows) => {
        const filterData = uniq(rows[0])
        map(filterData, (item: string) => {
          if (!(item === "name" || item === "email")) {
            mapperSetupFormStore.$("csv_mapping").add([
              {
                mapper_csv_field: item,
                score_field: item,
              },
            ]);
          }
        });
      });

      const payload = {
        file: excelFile,
        id: this.mapperId,
      };
      await uploadCsvFile.callApiWithFormData(payload);
      await getCsvFields.callApi({
        mapper_template_id: this.mapperId,
      });
      await getFields.callApi({
        page: 1,
        per_page: 100,
      });

      Message.success("Successfully uploaded");
    } catch (error) {
      console.log("error: ", error);
      Message.error("Unsuccessfully uploaded");
    } finally {
      this.setIsCSVHeaderUploadLoading(false);
    }
  };
  downloadCSVTemplate = async () => {
    try {
      this.setIsCSVTemplateDownloadLoading(true);
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/storage/csv-mapping-template/mapper_template_file.xlsx`;
      await downloadExcelFile(endpoint, "mapper_template_file.xlsx", "", "GET");
      Message.success("CSV Template has been downloaded successfully");
      this.setIsCSVTemplateDownloadLoading(false);
    } catch (error) {
      Message.error("Could not download");
    }
  };
  get csvFieldList() {
    return (
      getCsvFields.successResponse &&
      getCsvFields.data?.map<Option>((field: CSVFields) => ({
        value: field.id,
        label: field.csv_field,
      }))
    );
  }
  get databaseFieldList() {
    return getFields.data?.map<Option>((field: CSVFields) => ({
      value: field.field_name,
      label: field.field_name,
    }));
  }
  addTemplate = () => {
    mapperSetupFormStore.$("csv_mapping").add([
      {
        mapper_csv_field: null,
        score_field: null,
      },
    ]);
  };
  setFilterText = (val, index) => {
    set(this.filterText, index, val);
  };
  removeFromMapping = (index) => {
    mapperSetupFormStore.del(`csv_mapping[${index}]`);
    mapperSetupFormStore.update({
      csv_mapping: [...(mapperSetupFormStore.$("csv_mapping").values() as any)],
    });
  };
  async init() {
    await mappedCsvFields.callApi({
      mapper_template_id: this.mapperId,
    });
    const csvFieldsData =
      mappedCsvFields.hasList &&
      mappedCsvFields.data.mappedCsvFields.map(
        ({ mapper_csv_field, score_field }) => ({
          mapper_csv_field,
          score_field,
        })
      );
    await mapperSetupFormStore.update({
      csv_mapping: csvFieldsData,
    });
    mappedCsvFields.data.mappedCsvFields &&
      (await getCsvFields.callApi({
        mapper_template_id: this.mapperId,
      }));
    await getFields.callApi({
      page: 1,
      per_page: 100,
    });
  }
  async handleSave() {
    try {
      await mapperSetupFormStore.submit();
    } catch (error) {
      const errorMessage = get(mappingCsv.failureResponse, "errors");
      Message.error(
        errorMessage[Object.keys(errorMessage)[0]]
          ? errorMessage[Object.keys(errorMessage)[0]][0]
          : "CSV template has been failed."
      );
    }
  }
  handleSearchInputChange = debounce((newValue, index) => {
    getFields.callApi({
      page: 1,
      per_page: 100,
      query: newValue,
    });
  }, 750);
}

export const mapperSetupStore = new MapperSetupStore();
