import { FormStore } from "stores/FormStore";
import { Message } from "components/Message";
import { map } from "lodash";
import { accessControlStore } from "container/AccessControl/store/accessControl.store";
import { authStore } from "stores/AuthStore";

interface AccessControlCreationFormStore {
  name: string;
  email: string;
}

export const accessControlCreationFormStore =
  new FormStore<AccessControlCreationFormStore>(
    {
      name: {
        placeholder: "Please enter the user name",
        label: "User Name",
        rules: "required",
        bindings: "extended",
      },
      email: {
        placeholder: "Please enter the user email",
        label: "User Email",
        rules: "required",
        bindings: "extended",
      },
    },
    {
      options: {
        validateOnChange: true,
      },
      hooks: {
        onSuccess: async (form: FormStore) => {
          const values = form.values();
          try {
            await accessControlStore.callCreateUserApi(
              values.name,
              values.email,
              authStore.getClient
            );
            accessControlStore.setLoading(false);
            accessControlStore.callGetUsersByClientApi({
              client_id: authStore.getClient,
            });
            form.reset();
            Message.success(`Successfully Create User`);
          } catch (e) {
            return Promise.reject(`Error calling api`);
          }
          return Promise.resolve();
        },
        onError: (form) => {
          Message.error("Incorrect form information");
          return Promise.reject(`Incorrect form information`);
        },
      },
    }
  );
