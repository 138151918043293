// screen file for Account
import React from "react";
import style from "./index.scss";
import { AccountProps } from "container/Account/store/account.types";
import { Header } from "container/Header";
import { get } from "lodash";
import { accountsStore } from "container/Account/store/account.store";
import { observer } from "mobx-react";
import { useEffectAsync } from "utlis/hooks";
import {impersonate, loginByToken, myProfile} from "container/Account/store/account.api";
import { authStore } from "stores/AuthStore";

export const Account: React.FC<AccountProps> = observer(({}) => {

  return (
    <>
      <Header name={get(accountsStore.getMyProfileData, "name", "")} />
    </>
  );
});
