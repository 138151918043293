import * as React from "react";
import style from "./index.module.scss";
import { useEffectAsync } from "utlis/hooks";
import { LoadingData } from "components/Loading";
import { loginByToken } from "container/Account/store/account.api";
import { authStore } from "stores/AuthStore";
import { useNavigate } from "react-router-dom";
import {
  MAPPING_DOMAIN,
  UNAUTHORIZED,
  WORKBOOK_DOMAIN,
} from "constants/routes";
import { Message } from "components/Message";
import {docCookies} from "utlis/cookies";

interface AuthenticationProps {}

export const Authentication: React.FC<AuthenticationProps> = (props) => {
  let navigate = useNavigate();
  useEffectAsync(async () => {
    try {
      authStore.removeToken()
      const token = docCookies.getItem('access_token')
      const response: any = await loginByToken.callApi({
        token: token,
      }, true);
      if (response.data) {
        authStore.setToken(response.data.data.token);
      }
      Message.success("Authentication successful");
      navigate(`/${MAPPING_DOMAIN}`);
      window.location.reload()
    } catch (e) {
      Message.error("Authentication failed");
      window.location.replace(`${process.env.REACT_APP_ACCOUNT_FRONT_END_URL}/login?type=mapping_revamp`)

    }
  }, []);

  return (
    <div>
      <LoadingData
        className={style.height}
        loadingText={"Checking Authentication"}
      />
    </div>
  );
};
