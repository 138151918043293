//Store for WorkbookConfiguration

import { makeAutoObservable, runInAction } from "mobx";
import {
  createFormulaCombination,
  deleteFormulaCombination,
  getWbCategoryData,
  searchFields,
  setScoreOptions,
  updateFormulaCombination,
  updateFormulaOrder,
} from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.api";
import { debounce, get, map, toNumber } from "lodash";
import {
  FormulaCombination,
  WorkbookCategory,
} from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.types";
import { workbookConfigurationStore } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.store";
import { arrayMoveImmutable } from "array-move";

class WorkbookFormulaMappingStore {
  constructor() {
    makeAutoObservable(this);
  }

  wbCategoryData: WorkbookCategory;
  formulaCombination: FormulaCombination[];
  workbookLoading = false;

  editFormulaDetails;

  isOpen = false;

  callSearchFieldsApi = async (search: string) => {
    await searchFields.callApiIfPayloadDifferent({ search: search });
  };

  callSearchFieldsApiDebounce = debounce((search: string) => {
    searchFields.callApi({ search: search });
  }, 1500);

  callCreateFormulaCombinationApi = async (payload: any) => {
    await createFormulaCombination.callApi(payload);
  };

  callUpdateFormulaCombinationApi = async (payload: any) => {
    await updateFormulaCombination.callApi(payload);
  };

  callWbCategoryDataApi = async (workCategoryId: number) => {
    try {
      this.setIsLoading(true);
      await getWbCategoryData.callApi({ workbook_category_id: workCategoryId });
      runInAction(() => {
        this.wbCategoryData = get(getWbCategoryData.data, "workbook_category");
        this.formulaCombination = get(
          getWbCategoryData.data,
          "formula_combinations"
        );
      });
      this.setIsLoading(false);
    } catch {
      this.setIsLoading(false);
    }
  };

  callSetScoreOptionsApi = debounce(() => {
    setScoreOptions.callApi({
      workbook_category_id: this.wbCategoryData.id,
      ...this.wbCategoryData,
    });
  }, 750);

  callUpdateFormulaOrderApi = debounce(() => {
    const formulaOrder = map(this.formulaCombination, (item, index) => {
      return { formula_combination_id: item.id, order: index + 1 };
    });
    updateFormulaOrder.callApi({
      workbook_category_id: workbookConfigurationStore.selectedCategoryId,
      formula_orders: formulaOrder,
    });
  }, 1500);

  callDeleteFormulaOrderApi = async (id) => {
    await deleteFormulaCombination.callApi({
      formula_id: id,
    });
  };

  setWbCategoryData = async (values: WorkbookCategory) => {
    this.wbCategoryData.no_of_decimals = values.no_of_decimals;
    this.wbCategoryData.is_sten = values.is_sten || false;
    this.wbCategoryData.is_sten5 = values.is_sten5 || false;
    this.wbCategoryData.is_sten5_decimal = values.is_sten5_decimal || false;
    this.wbCategoryData.is_sten9 = values.is_sten9 || false;
    this.wbCategoryData.is_percentile = values.is_percentile || false;
    this.wbCategoryData.show_in_excel = values.show_in_excel;
    this.wbCategoryData.is_original_score = values.is_original_score;
    this.wbCategoryData.is_percentage = values.is_percentage;
    this.wbCategoryData.threshold = values.threshold;
    if (
      (toNumber(values.threshold) >= -3 && toNumber(values.threshold) <= 3) ||
      values.threshold === null
    )
      await this.callSetScoreOptionsApi();
  };

  setWbCategoryThreshold = async (values: WorkbookCategory) => {
    this.wbCategoryData.threshold = values.threshold;
  };

  setIsOpen = (bool: boolean) => {
    this.isOpen = bool;
  };

  setIsLoading = (bool: boolean) => {
    this.workbookLoading = bool;
  };

  setEditFormulaData = (data) => {
    this.editFormulaDetails = data;
  };

  onSortFormulaList = ({ oldIndex, newIndex }) => {
    this.formulaCombination = arrayMoveImmutable(
      this.formulaCombination,
      oldIndex,
      newIndex
    );
    this.callUpdateFormulaOrderApi();
  };

  removeFromFormulaCreate = (id) => {
    this.formulaCombination = this.formulaCombination.filter(
      (item) => item.id !== id
    );
  };

  get getSearchData() {
    return searchFields.data;
  }

  get getWbCategoryData() {
    return this.wbCategoryData;
  }

  get getFormulaCombination() {
    return this.formulaCombination;
  }
}

export const workbookFormulaMapping = new WorkbookFormulaMappingStore();
