import * as React from "react";
import style from "./index.module.scss";
import * as _ from "lodash";
import { CSSProperties, useState } from "react";

export interface UserProfileProps {
  name: string;
  size?: "small" | "medium" | "large" | "extraLarge";
  className?: string;
  pictureUrl?: string;
}

export const UserProfile: React.FC<UserProfileProps> = ({
  name,
  size = "medium",
  className,
  pictureUrl,
}) => {
  const [letterName, setLetterName] = useState<string>("");

  React.useEffect(() => {
    if (name) {
      const nameSplit = name.split(" ");
      const nameArray = [];
      for (let i = 0; i < (nameSplit.length < 2 ? nameSplit.length : 2); i++) {
        nameArray.push(nameSplit[i].charAt(0).toUpperCase());
      }
      setLetterName(nameArray.join(""));
    }
  }, [name]);

  return (
    <div className={`${style.pictureWrapper} ${className}`}>
      <div className={`${style.picture}`}>
        {pictureUrl ? (
          <img
            alt={`${name} profile picture`}
            className={style[`${size}`]}
            src={pictureUrl}
          />
        ) : (
          <div
            className={`d-flex justify-content-center align-items-center ${
              style.defaultPicture
            } ${style[`${size}`]}`}
          >
            {letterName}
          </div>
        )}
      </div>
    </div>
  );
};
