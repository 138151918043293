/**
 Detail about this screen can be found here https://labsteam.atlassian.net/browse/MRF-35
 */
// screen file for CompetencyLibrary
import React from "react";
import style from "./index.module.scss";
import { CompetencyLibraryProps } from "container/CompetencyLibrary/store/competencyLibrary.types";
import { PageBase } from "components/PageBase";
import { PageTitle } from "components/PageTitle";
import { Title } from "components/Title";
import { Button } from "components/Button";
import { competencyLibraryStore } from "./store/competencyLibrary.store";
import { columnsCompetencyLibrary } from "./tableColumn";
import { useEffectAsync } from "utlis/hooks";
import { observer } from "mobx-react";
import { authStore } from "stores/AuthStore";
import { SearchInput } from "components/SearchInput";
import { Pagination } from "components/Pagination";
import { Table } from "components/Table";
import { getCompetencyLibrary } from "container/CompetencyLibrary/store/competencyLibrary.api";

export const CompetencyLibrary: React.FC<CompetencyLibraryProps> = observer(
  ({}) => {
    useEffectAsync(async () => {
      await competencyLibraryStore.callGetCompetencyApi();
    }, [authStore.getClientIdToken]);

    return (
      <PageBase className={style.competencyLibraryContainer}>
        <PageTitle pageTitle={`Upload Competency Library XL`} />
        <div className={style.topDropdown}>
          <div className={style.titleSearch}>
            <Title count={competencyLibraryStore.getTotalData}>
              Competency Library XL
            </Title>
            <SearchInput
              placeholder={"Search"}
              onChange={(e) => competencyLibraryStore.setSearch(e.target.value)}
            />
          </div>
          <div className={style.filesContainer}>
            <div className={style.uploadFile}>
              <div className={style.label}>Upload New Document:</div>
              <Button
                className={`ms-2`}
                onClick={() =>
                  competencyLibraryStore.competencyLibraryRef.click()
                }
                loading={
                  competencyLibraryStore.isCompetencyLibraryUploadLoading
                }
                disabled={
                  competencyLibraryStore.isCompetencyLibraryUploadLoading
                }
              >
                Upload File
              </Button>
              <input
                className="d-none"
                type="file"
                accept=".xlsx, .xls"
                id={"input"}
                ref={(ref) =>
                  (competencyLibraryStore.competencyLibraryRef = ref)
                }
                value=""
                onChange={(e) =>
                  competencyLibraryStore.handleUploadCompetencyLibrary(e)
                }
              />
            </div>
            <div className={style.downloadSection}>
              <div className={style.label}>
                Download Previous Uploaded Document:
              </div>
              <Button
                className={`ms-2`}
                onClick={() =>
                  competencyLibraryStore.downloadCompetencyLibrary()
                }
              >
                Download
              </Button>
            </div>
          </div>
        </div>
        <div className={style.tableHeight}>
          <Table
            columns={columnsCompetencyLibrary}
            data={competencyLibraryStore.getTableData}
            isLoading={getCompetencyLibrary.isLoading}
          />
        </div>
        <Pagination
          currentPage={competencyLibraryStore.getMetaData?.current_page}
          lastPage={competencyLibraryStore.getMetaData?.last_page}
          onPageClick={(e) => competencyLibraryStore.setPage(e)}
          onNextClick={() =>
            competencyLibraryStore.setPage(
              competencyLibraryStore.getMetaData?.current_page + 1
            )
          }
          onPreviousClick={() =>
            competencyLibraryStore.setPage(
              competencyLibraryStore.getMetaData?.current_page - 1
            )
          }
          onLastClick={() =>
            competencyLibraryStore.setPage(
              competencyLibraryStore.getMetaData?.last_page
            )
          }
          onFirstClick={() => competencyLibraryStore.setPage(1)}
        />
      </PageBase>
    );
  }
);
