// Apis For CompetencyLibrary
import { ApiStore } from "stores/ApiStore";
import { RestMethods } from "stores/baseApi";

export interface CompetencyLibraryApisPayload {}

const ENDPOINT_TEMPLATE = "/api/descriptors/competencyLibrary";

class CompetencyLibraryApis extends ApiStore<
  CompetencyLibraryApisPayload,
  null,
  null,
  null
> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static uploadCompetencyLibrary() {
    const endpoint = `${ENDPOINT_TEMPLATE}/uploadCompetencyLibrary`;
    return new CompetencyLibraryApis(endpoint, {
      requestType: RestMethods.POST,
    });
  }

  static getCompetencyLibrary() {
    const endpoint = `${ENDPOINT_TEMPLATE}/getCompetencyLibrary`;
    return new CompetencyLibraryApis(endpoint, {
      requestType: RestMethods.GET,
    });
  }
}

export const uploadCompetencyLibrary =
  CompetencyLibraryApis.uploadCompetencyLibrary();
export const getCompetencyLibrary =
  CompetencyLibraryApis.getCompetencyLibrary();
