import { FormStore } from "stores/FormStore";
import { Message } from "components/Message";
import { workbookFormulaMapping } from "container/Workbook/WorkbookConfiguration/store/workbookFormulaMapping.store";
import { workbookConfigurationStore } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.store";

interface WorkbookFormulaFormStore {
  formula_name: string;
  formula: string;
  order: string;
  workbook_category_id: string;
  workbook_id: string;
}

export const workbookFormulaFormStore = new FormStore<WorkbookFormulaFormStore>(
  {
    formula_name: {
      label: "Formula Name",
      placeholder: "Name your Formula Name",
      rules: "required",
      bindings: "extended",
    },
    formula: {
      rules: "required",
      bindings: "extended",
    },
  },
  {
    options: {
      validateOnChange: true,
    },
    hooks: {
      onSuccess: async (form: FormStore) => {
        const values = form.values();
        try {
          if (workbookFormulaMapping.editFormulaDetails?.id) {
            await workbookFormulaMapping.callUpdateFormulaCombinationApi({
              formula_id: workbookFormulaMapping.editFormulaDetails.id,
              formula_name: values.formula_name,
              formula: values.formula,
              order: workbookFormulaMapping.editFormulaDetails.order,
              workbook_category_id:
                workbookConfigurationStore.selectedCategoryId,
              workbook_id: workbookConfigurationStore.workbookId,
            });
          } else {
            await workbookFormulaMapping.callCreateFormulaCombinationApi({
              formula_name: values.formula_name,
              formula: values.formula,
              order: workbookFormulaMapping.formulaCombination.length + 1,
              workbook_category_id:
                workbookConfigurationStore.selectedCategoryId,
              workbook_id: workbookConfigurationStore.workbookId,
            });
          }
          form.reInit();
          Message.success(
            `Successfully ${
              workbookFormulaMapping.editFormulaDetails?.id ? `Edit` : `Create`
            } Mapping Formula`
          );
          workbookFormulaMapping.setEditFormulaData(undefined);
          await workbookFormulaMapping.callWbCategoryDataApi(
            workbookConfigurationStore.selectedCategoryId
          );
        } catch (e) {
          return Promise.reject(`Error calling api`);
        }
        return Promise.resolve();
      },
      onError: (form) => {
        Message.error("Incorrect form information");
        return Promise.reject(`Incorrect form information`);
      },
    },
  }
);
