//Store for ReviewMapping

import { getFormulaTree } from "container/Workbook/WorkbookConfiguration/store/workbookConfiguration.api";
import {
  finalizeWorkbook,
  getJourneys,
} from "container/Workbook/ReviewMapping/store/reviewMapping.api";
import { map } from "lodash";
import { makeAutoObservable } from "mobx";
import { getCalculatedData } from "container/Workbook/ViewDataset/store/viewDataset.api";
import { GetCalculatedDataPayload } from "container/Workbook/ViewDataset/store/viewDataset.types";

class ReviewMappingStore {
  constructor() {
    makeAutoObservable(this);
  }
  selectLevel;
  selectJourney;
  showErrorLevel;
  showErrorDataset;


  callGetFormulaTreeApi = async (workbook_id) => {
    await getFormulaTree.callApi({ workbook_id });
  };

  callGetJourneysApi = async (client_ids) => {
    await getJourneys.callApi({ "client_ids[]": client_ids });
  };

  callGetCalculateDataApi = async (payload: GetCalculatedDataPayload) => {
    await getCalculatedData.callApi(payload);
  };


  clearSelected = () => {
    this.selectLevel = null
    this.selectJourney = null
  }

  //add formula_combinations data once there is data in
  loop = (arrayData) => {
    const data = [];
    const convertArrToObj = (arr, level) => {
      arr.forEach((item) => {
        if (item.children) {
          data.push({
            level: level,
            category: item.category,
            formula_combinations: item.formula_combinations,
          });
          convertArrToObj(item.children, level + 1);
        } else
          data.push({
            level: level,
            category: item.category,
            formula_combinations: item.formula_combinations,
          });
      });
    };
    if (arrayData) {
      convertArrToObj(arrayData, 1);
    }
    return data;
  };

  setJourneyId = (ids) => {
    this.selectJourney = ids;
    this.setShowErrorDataset(false)
  };



  setLevelId = (ids) => {
    this.selectLevel = ids;
    this.setShowErrorLevel(false)
  };

  setShowErrorLevel = (bool: boolean) => {
    this.showErrorLevel = bool;
  };

  setShowErrorDataset = (bool: boolean) => {
    this.showErrorDataset = bool;
  };



  get getFormulaTreeData() {
    return this.loop(getFormulaTree.data);
  }

  get getJourneyOptionData() {
    return map(getJourneys.data, (item: any) => ({
      label: item.name,
      value: item.id,
    }));
  }
}

export const reviewMappingStore = new ReviewMappingStore();
