import * as React from "react";
import { Landing } from "container/Landing";
import { CategoryManagement } from "container/CategoryManagement";
import { CompetencyLibrary } from "container/CompetencyLibrary";
import { LookUpXL } from "container/LookUpXL";
import { MapperLanding } from "container/MapperTemplate/MapperLanding";
import { ResultPostLog } from "container/ResultPostLog";
import { WorkbookCreation } from "container/Workbook/WorkbookCreation";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { Component } from "components";
import {
  ACCESS_CONTROL_DOMAIN,
  CATEGORY_MANAGEMENT_DOMAIN,
  COMPETENCY_LIBRARY_DOMAIN,
  LOOKUP_DOMAIN,
  MAPPER_TEMPLATE_DOMAIN,
  MAPPING_DOMAIN,
  MOTIVATING_FACTOR_DOMAIN,
  RESULT_POST_LOG_DOMAIN,
  UNAUTHORIZED,
  WORK_RELATED_BEHAVIOURS_DOMAIN,
  WORKBOOK_DOMAIN,
} from "constants/routes";
import { WorkbookConfiguration } from "container/Workbook/WorkbookConfiguration";
import { ViewDataset } from "container/Workbook/ViewDataset";
import { ReviewMapping } from "container/Workbook/ReviewMapping";
import { MapperSetup } from "container/MapperTemplate/MapperSetup";
import { Account } from "container/Account";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { Authentication } from "container/Authentication";
import { Unauthorized } from "container/Unauthorized";
import { MotivatingFactors } from "container/MotivatingFactors";
import { WorkRelatedBehaviours } from "container/WorkRelatedBehaviours";
import { AccessControl } from "container/AccessControl";
import { authStore } from "stores/AuthStore";
import { docCookies } from "utlis/cookies";

const App: React.FC = () => {
  return (
    <>
      <ToastContainer
        position={toast.POSITION.TOP_CENTER}
        autoClose={3000}
        newestOnTop={true}
        closeOnClick={true}
        closeButton={false}
        transition={Bounce}
      />
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Authentication/>}/>
          <Route element={<ProtectedRoutes />}>
            <Route path={"/"} element={<MainHeader />}>
              <Route path={MAPPING_DOMAIN} element={<Landing />} />
              <Route path={`${WORKBOOK_DOMAIN}/*`}>
                <Route
                  path={"create/:workbookId"}
                  element={<WorkbookCreation />}
                />
                <Route
                  path={"configure/:workbookId"}
                  element={<WorkbookConfiguration />}
                />
                <Route
                  path={"review/:workbookId"}
                  element={<ReviewMapping />}
                />
                <Route
                  path={"viewData/:workbookId/:level_id/:journey_id"}
                  element={<ViewDataset />}
                />
              </Route>
              <Route
                path={CATEGORY_MANAGEMENT_DOMAIN}
                element={<CategoryManagement />}
              />
              <Route
                path={COMPETENCY_LIBRARY_DOMAIN}
                element={<CompetencyLibrary />}
              />
              <Route path={LOOKUP_DOMAIN} element={<LookUpXL />} />
              <Route path={ACCESS_CONTROL_DOMAIN} element={<AccessControl />} />
              <Route
                path={MOTIVATING_FACTOR_DOMAIN}
                element={<MotivatingFactors />}
              />
              <Route
                path={WORK_RELATED_BEHAVIOURS_DOMAIN}
                element={<WorkRelatedBehaviours />}
              />
              <Route path={`${MAPPER_TEMPLATE_DOMAIN}`}>
                <Route path={""} element={<MapperLanding />} />
                <Route path={"create/:mapperId"} element={<MapperSetup />} />
              </Route>
              <Route
                path={RESULT_POST_LOG_DOMAIN}
                element={<ResultPostLog />}
              />
            </Route>
          </Route>
          <Route path={UNAUTHORIZED} element={<Unauthorized />} />
          <Route path="component" element={<Component />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

const MainHeader = () => {
  return (
    <>
      <Account />
      <Outlet />
    </>
  );
};

const ProtectedRoutes = () => {
  return docCookies.getItem("access_token") && authStore.getToken && docCookies.getItem("access_token") === authStore.getToken ? (
    <Outlet />
  ) : (
    <Authentication />
  );
};

export default App;
