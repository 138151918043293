import { action, computed, makeAutoObservable, observable } from "mobx";

export enum TOKEN_NAME {
  CLIENT_ID = "CLIENT_ID",
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "REFRESH_TOKEN",
}

class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }
  isAuthenticated: boolean = false;
  client_id: number | string;

  get getToken() {
    return window.localStorage.getItem(TOKEN_NAME.ACCESS_TOKEN);
  }

  get getClient() {
    return window.localStorage.getItem(TOKEN_NAME.CLIENT_ID);
  }

  get getClientIdToken(){
    return this.client_id
  }

  removeToken = () => {
    return window.localStorage.removeItem(TOKEN_NAME.ACCESS_TOKEN);
  };

  setToken = (accessToken, refreshToken: string = undefined) => {
    window.localStorage.setItem(TOKEN_NAME.ACCESS_TOKEN, accessToken);
  };

  setClient = (client_id) => {
    this.client_id = client_id
    return window.localStorage.setItem(TOKEN_NAME.CLIENT_ID, client_id);
  }

  get hasToken() {
    return !!this.getToken;
  }
}

export const authStore = new AuthStore();
