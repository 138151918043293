// Apis For Landing
import { ApiStore } from 'stores/ApiStore';
import { RestMethods } from 'stores/baseApi';

export interface LandingApisPayload {
  workbook_id?: number;
  journey_id?: number;
  norm_group_id?: number;
  client_id?: number;
}

const ENDPOINT_TEMPLATE = 'api/workbook'
const FINALIZE_ENDPOINT_TEMPLATE = 'api/finalize'

export class LandingApis extends ApiStore<LandingApisPayload, null, null, null> {
  constructor(endpoint = ENDPOINT_TEMPLATE, options = {}) {
    super(endpoint, options);
  }

  static workBookDetailApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/search`;
    return new LandingApis(endpoint, { requestType: RestMethods.GET });
  }

  static workBookDownloadApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:workbook_id/download`;
    return new LandingApis(endpoint, { requestType: RestMethods.GET });
  }

  static workBookDeleteApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:workbook_id/delete`;
    return new LandingApis(endpoint, { requestType: RestMethods.POST });
  }

  static workBookCloneApi() {
    const endpoint = `${ENDPOINT_TEMPLATE}/:workbook_id/clone`;
    return new LandingApis(endpoint, { requestType: RestMethods.POST });
  }

  static getTrackingByWorkbook() {
    const endpoint = `${FINALIZE_ENDPOINT_TEMPLATE}/getTrackingByWorkbook`;
    return new LandingApis(endpoint, { requestType: RestMethods.GET });
  }

  static logApi() {
    const endpoint = `/api/logs/workbook/:workbook_id`;
    return new LandingApis(endpoint, { requestType: RestMethods.GET });
  }
}

export const workBookDetailApi = LandingApis.workBookDetailApi();
export const workBookDownloadApi = LandingApis.workBookDownloadApi();
export const workBookDeleteApi = LandingApis.workBookDeleteApi();
export const workBookCloneApi = LandingApis.workBookCloneApi();
export const getTrackingByWorkbook = LandingApis.getTrackingByWorkbook();
export const logApi = LandingApis.logApi();

