import { FormStore } from "stores/FormStore";
import { workbookCreationStore } from "container/Workbook/WorkbookCreation/store/workbookCreation.store";
import { Message } from "components/Message";
import { map } from "lodash";

interface WorkbookCreationFormStore {
  name: string;
  client_ids: number[];
  level_ids: number[];
}

export const workbookCreationFormStore =
  new FormStore<WorkbookCreationFormStore>(
    {
      name: {
        placeholder: "Name your Workbook",
        rules: "required",
        bindings: "extended",
      },
      client_ids: {
        placeholder: "Client",
        rules: "required",
        bindings: "extended",
      },
      level_ids: {
        placeholder: "Level",
        rules: "required",
        bindings: "extended",
      },
      workbook_id: {},
    },
    {
      options: {
        validateOnChange: true,
      },
      hooks: {
        onSuccess: async (form: FormStore) => {
          const values = form.values();
          try {
            const clientIds = map(values.client_ids, (e: any) => e.value);
            const levelIds = map(values.level_ids, (e: any) => e.value);
            if (values.workbook_id) {
              await workbookCreationStore.callUpdateWorkbookApi({
                name: values.name,
                client_ids: clientIds,
                level_ids: levelIds,
                is_cloned: false,
                workbook_id: values.workbook_id,
              });
            } else {
              await workbookCreationStore.callCreateWorkbookApi({
                name: values.name,
                client_ids: clientIds,
                level_ids: levelIds,
                is_cloned: false,
              });
            }
            workbookCreationStore.setLoading(false);
            form.reset();
            Message.success(
              `Successfully ${values.workbook_id ? `Edit` : `Create`} Workbook`
            );
          } catch (e) {
            return Promise.reject(`Error calling api`);
          }
          return Promise.resolve();
        },
        onError: (form) => {
          Message.error("Incorrect form information");
          return Promise.reject(`Incorrect form information`);
        },
      },
    }
  );
